import { Component, Input, OnInit } from "@angular/core";
import { CustomerObjectsService } from "../../../../../../categories/customer-objects/customer-objects.service";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { CustomerObjectDeviceDto } from "../../../../../models/customerObject/customer-object-device";

@Component({
	selector: 'request-page-customer-object-devices',
	templateUrl: './request-page-customer-object-devices.component.html',
	styleUrls: ['./request-page-customer-object-devices.component.scss',
		'../../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class RequestPageCustomerObjectDevicesComponent implements OnInit {

	@Input() customerObjectId: number;

	dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	loading: boolean = false;

	customerObjectDevices: CustomerObjectDeviceDto[] = [];

	constructor (
		private customerObjectsService: CustomerObjectsService
	) {}

	ngOnInit(): void {
		this.initData();
	}

	initData() {
		if (this.customerObjectId) {
			this.loading = true;
			this.customerObjectsService.getCustomerObjectDevices(this.customerObjectId).subscribe(resp => {
				this.customerObjectDevices = resp;
				this.loading = false;
			});
		}
	}

	public rowClass() {
		return {
			'cursor-pointer': true
		};
	}
}
