import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { LookupService } from "../../../../shared/services/lookup.service";
import { forkJoin } from "rxjs";
import { KeyValueObject } from "../../../../shared/models/core/KeyValueObject";
import { NewRequestService } from "../../../../shared/models/request/new-request/new-request-service";
import { CategoryServicesService } from "../../../../shared/services/category-services.service";
import { ServiceItem } from "../../../../shared/models/services/service-item";
import { GridComponent } from "@progress/kendo-angular-grid";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { NewRequestDevice } from "../../../../shared/models/request/new-request/new-request-device";
import { HierarchyLookupModel } from "../../../../shared/models/core/HierarchyLookupModel";
import { DeviceModelsService } from "../../../../shared/services/device-models.service";
import { DeviceModelDto } from "../../../../shared/models/device/device-model-dto";
import { RequestsService } from "../../../services/requests.service";
import { RequestDevicesService } from "../../../services/request-devices.service";

@Component({
	selector: 'setup-device-serial',
	templateUrl: './setup-device-serial.html',
	styleUrls: ['./setup-device-serial.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class SetupDeviceSerial implements OnInit {

    @ViewChild("requestDevicesGrid") requestDevicesGrid: GridComponent;

    @Input() requestId: number;
    @Input() activityId: number;
    
    @Output() onContinueEvent: EventEmitter<any> = new EventEmitter();
    @Output() onCancelEvent: EventEmitter<any> = new EventEmitter();

    dataSaving: boolean = false;
    loading: boolean = false;
	dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    deviceTypes: KeyValueObject[] = [];
    deviceModels: DeviceModelDto[] = [];
    dropDownDeviceModels: DeviceModelDto[] = [];
    deviceSuppliers: DeviceModelDto[] = [];
    dropDownDeviceSuppliers: DeviceModelDto[] = [];

    requestDevices: NewRequestDevice[] = [];

    constructor(
        public requestsService: RequestsService,
        public deviceModelsService: DeviceModelsService,
        public lookupService: LookupService,
        public requestDevicesService: RequestDevicesService
    ) { }

    ngOnInit(): void {
        let deviceTypes$ = this.lookupService.getData('device-types', null);
		let deviceModels$ = this.deviceModelsService.getAll();
        this.loadRequestDevices();

        forkJoin(deviceTypes$,  deviceModels$)
            .subscribe(([deviceTypes, deviceModels]) => {
                this.deviceTypes = deviceTypes;
                this.deviceModels = deviceModels;
                this.dropDownDeviceModels = [];
                this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null);
                this.dropDownDeviceSuppliers = [];
            });
    }

    loadRequestDevices() {
        this.loading = true;
        this.requestsService.getRequestDevices(this.requestId).subscribe(resp => {
            this.requestDevices = resp;
            this.loading = false;
        })
    }

    saveChanges() {
        this.dataSaving = true;
        this.requestsService.saveSerialRequestDevices(this.requestDevices).subscribe(() => {
            this.onContinueEvent.emit();
            this.dataSaving = false;
        });
    }

    cancel() {
        this.onCancelEvent.emit();
    }

    getNameById(items: KeyValueObject[] = [], id: number): string {
        return items.some(x => x.id == id)
            ? items.find(x => x.id == id).name
            : '';
    }

    saveRequestDevice({ dataItem }: any) {
    }

    removeRequestDevice({ dataItem }: any) {
    }

    createNewRequestDevice(): NewRequestDevice {
        let device = new NewRequestDevice();
        device.manuallyAdded = true;
        device.requestId = this.requestId;
        device.activityId = this.activityId;
        return device;
    }

    addNewRequestDevice() {
        this.requestDevicesGrid.addRow(this.createNewRequestDevice())
    }

    public deviceTypeChange(deviceTypeId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
            requestDevice.deviceSupplierId = null;
        }
    }

    public deviceSupplierChange(supplierId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
        }
    }

    public handleDeviceModelsDropdownOpen(deviceSupplierId: number) {
		this.dropDownDeviceModels = this.deviceModels != null && deviceSupplierId
            ? this.deviceModels.filter(x => x.parentId === deviceSupplierId)
            : [];
	}

    public handleDeviceSuppliersDropdownOpen(deviceTypeId: number) {
        this.dropDownDeviceSuppliers = this.deviceModels != null && deviceTypeId
            ? this.deviceSuppliers.filter(x => x.typeId == deviceTypeId)
            : [];
    }

    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

    get gridHeight(): number {
        return 400;
    }

    get canSaveChanges(): boolean {
        return this.requestDevices.every(x => this.requestDeviceValid(x));
    }

    requestDeviceValid(requestDevice): boolean {
        return !requestDevice.toDelivery || 
            (requestDevice.toDelivery && requestDevice.serialNumber != null && requestDevice.serialNumber.trim() != '')
    }
}
