import { Observable, forkJoin, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { CompleteActivityRequiredAttachmentData } from "./complete-activity-required-attachment-data";
import { UploadAttachmentsModel } from "../../../../models/attachment";
import { AttachmentsService } from "../../../../services/attachments.service";
import { UploadFileData } from "../../../../models/attachment/upload-file-data";
import { Component } from "@angular/core";
import { tap } from "rxjs/operators";

@Component({
    selector: 'complete-activity-required-attachment',
    templateUrl: './complete-activity-required-attachment.component.html',
    styleUrls: ['./complete-activity-required-attachment.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivityRequiredAttachmentComponent 
    extends BaseFlowComponent<CompleteActivityRequiredAttachmentData> {

    constructor(
        public attachmentsService: AttachmentsService
    ) {
        super();
    }

    initData() { }

    saveChanges(): Observable<any> {
        return this.uploadAttachmentsRequest();
    }
    
    uploadAttachmentsRequest(): Observable<any> {
        this.data.attachments.forEach(x => {
            x.attachmentTypeId = this.data.attachmentTypeId;
            x.isTemporary = true;
        });
        
        let attachmentsToUpload = this.data.attachments.filter(x => !x.isUploadedOnServer && !x.isRemoved);
        let attachmentsToRemove = this.data.attachments.filter(x => x.isUploadedOnServer && x.isRemoved);

        const uploadModel = new UploadAttachmentsModel({ 
            requestId: this.data.requestId, 
            activityId: this.data.activityId,
            attachments: attachmentsToUpload
        });

        let attachmentObservables$: Observable<any>[] = [];

        if (attachmentsToRemove.length > 0) {
            attachmentObservables$ = attachmentsToRemove.map((x) => this.attachmentsService.remove(x.uid, null, null, true));
        }

        if (attachmentsToUpload.length > 0) {
            attachmentObservables$.push(
                this.attachmentsService.uploadWithResumableIfMobile(uploadModel)
                .pipe(tap(() => {
                    attachmentsToUpload.forEach(x => x.isUploadedOnServer = true);
                })));
        }

        return attachmentObservables$.length > 0 
            ? forkJoin(attachmentObservables$) 
            : of({}); 
    }

    removeAttachment(uploadFileData: UploadFileData) {
        let attachment = this.data.attachments.find(x => x.uid == uploadFileData.uid);
        if (attachment.isUploadedOnServer) {
            attachment.isRemoved = true;
        }
        else {
            this.data.attachments = this.data.attachments.filter(x => x.uid != uploadFileData.uid);
        }
    }

    get hasSavedAttachments() {
        return this.data.attachments.filter(x => x.isRemoved != true).length > 0;
    }
}