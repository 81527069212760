import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from '../../../app.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { NotificationService } from '../../../core/services/notification.service';
import { RequestFilesService } from '../../../shared/services/request-files.service';
import { RequestFile } from '../../../shared/models/attachment/request-file';
import { ImagePopup } from '../../../shared/components/image-popup/image-popup.component';
import { UploadFile } from '../../../shared/models/attachment/upload-file';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { LookupService } from '../../../shared/services/lookup.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ActionResult } from '../../../shared/models/core/ActionResult';
import { NotificationType } from '../../../core/services/notification-type';
import { AttachmentsService } from '../../../shared/services/attachments.service';
import { saveAs } from 'file-saver';
import { Request } from '../../../shared/models/request/request';
import { UserGroup } from '../../../shared/models/user-group/user-group';
import { ImagePopupExtended } from '../../../shared/components/image-popup-extended/image-popup-extended.component';
import { Attachment } from '../../../shared/models/attachment';
import { SendAttachmentsToCustomerModel } from '../../../shared/models/attachment/send-attachments-to-customer-model';

@Component({
	selector: 'request-files',
	templateUrl: './request-files.component.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class RequestFilesComponent implements OnInit {

	@Input()
	requestId: number;
	@Input() entity: Request;

	@Output()
	goToActivityEmiter : EventEmitter<number> = new EventEmitter(); 
	@Output()
	filesLoadedEmiter : EventEmitter<number> = new EventEmitter();

	@Input() selectName?: string = null;
	@Input() isSingleSelectFileType: boolean = false;
	@Input() gridHeight?: number = null;
	@Input() isSlim: boolean = false;
	@Input() visibleAttachmentTypes: number[] = [];
	@Input() spotId: number = null;
	@Input() activityId?: number = null;
	@Input() serviceCenter: string = '';
	@Input() userGroups: UserGroup[] = [];
	@Input() canEditFinishFactDate: boolean = false;
	@Input() canSendToCustomer: boolean = false;

	files: RequestFile[] = [];
	filesToShow: Attachment[] = [];
	gridSelectedFileIds: number[] = [];

	filesLoading: boolean = false;
	showAddFilePanel: boolean = false;
	showGrid: boolean = true;
	showAddButton: boolean = true;

	uploadInProgress: any = false;
	uploadFilesPercentages?: number;
	filesSending: boolean = false;
	isCompressing = false;

	commentToAdd: string = "";
	filesToUpload: UploadFile[] = [];
	attachmentTypes: KeyValueObject[] = [];
	downloadFilesInProgress: number[] = [];

	imageExtensions: string[] = ['.png', '.jpg', '.jpeg'];

	allowedToShowPopupExtensions: string[] = ['.png', '.jpg', '.jpeg', '.pdf'];

	constructor(
		private appService: AppService,
		private dialogService: DialogService,
		private notificationService: NotificationService,
		private RequestFilesService: RequestFilesService,
		private attachmentsService: AttachmentsService,
		private lookupService: LookupService
	) { }

	ngOnInit(): void{
		if (this.gridHeight === null) {
			this.gridHeight = window.innerHeight - 250;
		} 

		this.loadFiles(this.requestId);

		this.lookupService.getData("attachment-types", null).subscribe(data => this.attachmentTypes = data);
	}

	loadFiles(requestId: number) {

		this.filesLoading = true;

		let requestFilesServiceListStateFilters = [];

		if (this.spotId === null) {
			requestFilesServiceListStateFilters.push({ field: 'requestId', operator: 'eq', value: requestId });
		} else {
			requestFilesServiceListStateFilters.push({ field: 'requestId', operator: 'eq', value: requestId });
			requestFilesServiceListStateFilters.push({ field: 'AttachmentSpotId', operator: 'eq', value: this.spotId });
		}

		this.RequestFilesService.list({
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: requestFilesServiceListStateFilters }
		}).subscribe(x => {
			this.files = x.data;

			if (this.filesLoadedEmiter != null) {
				this.filesLoadedEmiter.emit(this.files.length)
			}
			
			this.getAndCreateFiles(this.files);

			this.filesLoading = false;
		});
	}

	async getAndCreateFiles(files: RequestFile[]){
		this.filesToShow = [];
		var promises = [];
		for (const file of files) {
			if (!this.isAllowedToShowPopupExtension(file.fileExtension))
				continue;

			var blob = await this.attachmentsService.getAttachmentById(file.fileId).toPromise();
			promises.push(new Promise((resolve) => {
				var reader = new FileReader();
				var self = this;
				reader.readAsDataURL(blob); 
				reader.onloadend = function() {
					self.prepareFile(reader.result, file);
					resolve(reader.result);
				}		
			}));
		}

		await Promise.all(promises);
	}

	openImagePopup(imageUrl: string, file: RequestFile) {

		if (!this.isAllowedToShowPopupExtension(file.fileExtension))
			return;

		const dialogRef = this.dialogService.open({ content: ImagePopupExtended, width: this.appService.isMobileDevice ? '90%' : '90%', height: '90%', title: 'Изображение'});

		const imagePopup = <ImagePopupExtended>dialogRef.content.instance;

		imagePopup.canEditFinishFactDate = this.canEditFinishFactDate;
		imagePopup.fileId = file.fileId;
		imagePopup.serviceCenter = this.serviceCenter;
		imagePopup.files = this.filesToShow;
		imagePopup.requestId = this.requestId;
		imagePopup.entity = this.entity;
		imagePopup.userGroups = this.userGroups;
	}

	getImageUrl(fileId: number): string{
		return `/api/v1/request-files/${fileId}/preview`
	}

	showActivity(activityId: number){

		if (this.goToActivityEmiter != null){
			this.goToActivityEmiter.emit(activityId)
		}

	}

	addNewFilesClick() {
		this.showAddFilePanel = true;
		this.showGrid = false;
		this.showAddButton = false;
		this.commentToAdd = "";
		this.uploadFilesPercentages = null;
	}

	cancelUploadFiles(){
		this.showAddFilePanel = false;
		this.showGrid = true;
		this.showAddButton = true;
	}

	onAttachmentsAdded(uploadFiles: UploadFile[]) {
		this.filesToUpload = uploadFiles;
	}

	get isMobileDevice(): boolean{
		return this.appService.isMobileDevice;
	}

	isCompressingHandler(isCompressing: boolean){
		this.isCompressing = isCompressing;
	}

	uploadFiles() {
		
		if (this.filesToUpload.length < 0)
			return;

		if (this.filesToUpload.length > 0 && this.filesToUpload.some(x => x.attachmentTypeIds.length === 0)) {
			this.notificationService.error({
				title: 'Ошибка',
				message: "Укажите типы для загружаемых файлов",
				notificationType: NotificationType.SweetAlert
			});

			return;
		}

		var file = new RequestFile();
		file.requestId = this.requestId;
		file.activityId = this.activityId;
		file.files = this.filesToUpload;
		file.files.forEach(f => f.attachmentSpotId = this.spotId);
		file.requestCommentText = this.commentToAdd;

		this.uploadInProgress = true;

		this.RequestFilesService.create(file).subscribe(x => {

			switch (x.type) {
				case HttpEventType.UploadProgress:
					this.uploadFilesPercentages = Math.round(x.loaded / (x.total != null ? x.total : 0) * 100);
				case HttpEventType.Response:
					if (x instanceof HttpResponse) {
						var result = <ActionResult>x.body;

						this.uploadInProgress = false;

						if (!result.isSuccessful)
							return;
			
						this.loadFiles(this.requestId);

						//Эти таймауты необходимы чтобы корректно отображались миниатюры
						setTimeout(() => {
							this.showAddFilePanel = false;
							this.showGrid = true;

							setTimeout(() => {
								this.showGrid = false;
								
								setTimeout(() => {
									this.showGrid = true;
									this.showAddButton = true;
								}, 200);
							}, 200);
						}, 200);
				}
			}
		});
	}

	get getSelectName(): string {
		if(this.isCompressing){
			return "Сжатие...";
		} else if (this.uploadInProgress){
			return "Загрузка..."
		}
			return "Загрузить";
	}

	onEditFileSelected(event, requestFile: RequestFile) {

        const file:File = event.target.files[0];
      
        if (file) {

			const reader = new FileReader();

			reader.onload = function (readerEvt: any) {
				const binaryString = <string>readerEvt.target.result;

				requestFile.fileName = file.name;
				requestFile.fileData = btoa(binaryString);

				console.log(requestFile.fileData);
			};

			reader.readAsBinaryString(file);
        }
    }

	createNewRequestFile(){
		return new RequestFile();
	}

	public requestFileSaveHandler({ dataItem }: any) {

		var requestFile = <RequestFile>dataItem;

		this.RequestFilesService.update(requestFile, "Файл успешно изменен").subscribe(x => {

			if (!x.isSuccessful)
				return;

			this.loadFiles(this.requestId);
		});
	}

	public requestFileRemoveHandler(dataItem: RequestFile) {
		var requestFile = <RequestFile>dataItem;
		this.notificationService.confirmation({
			title: requestFile.fileName,
			message: 'Вы уверены что хотите удалить файл?',
			type: 'question',
			confirmButtonText: 'Да',
			cancelButtonText: 'Нет',
			showCloseButton: true
		}, () => {
			this.RequestFilesService.remove(`${requestFile.fileId}`, "Удаление файла", "Файл успешно удален").subscribe(x => {
				this.files.splice(this.files.indexOf(dataItem), 1);
				this.loadFiles(this.requestId);
			 });
		}, () => {});
	}

	public sendToCustomer() {
		let sendModel = new SendAttachmentsToCustomerModel;
		sendModel.requestId = this.requestId;
		sendModel.attachmentIds = this.gridSelectedFileIds;
		sendModel.force = true;

		this.filesSending = true;
		this.attachmentsService.sendToCustomer(sendModel).subscribe(() =>
			this.filesSending = false, () => this.filesSending = false);
	}

	isAllowedToShowPopupExtension(extension: string): boolean {

		if (extension == null)
			return false;

		extension = extension.toLowerCase();

		return this.allowedToShowPopupExtensions.some(ext => extension === ext);
	}

	isImageExtension(extension: string): boolean {

		if (extension == null)
			return false;

		extension = extension.toLowerCase();

		return this.imageExtensions.some(ext => extension === ext);
	}

	getByFileImageClassByExtension(extension: string): string {

		var imageClass = "fa-file";

		switch (extension) {
			case ".xls":
			case ".xlsx":
				imageClass = "fa-file-excel";
				break;
			case ".doc":
			case ".docx":
				imageClass = "fa-file-word";
				break;
			case ".pdf":
				imageClass = "fa-file-pdf";
				break;
			case ".zip":
			case ".rar":
				imageClass = "fa-file-archive";
				break;
			case ".ppt":
			case ".pptx":
				imageClass = "fa-file-powerpoint";
				break;
		}

		return imageClass;
	}

	downloadFile(requestFile: RequestFile) {

		this.downloadFilesInProgress.push(requestFile.fileId);

		this.attachmentsService.getAttachmentById(requestFile.fileId).subscribe(blob => {
			saveAs(blob, requestFile.fileName, { type: 'application/octet-stream' });

			const index = this.downloadFilesInProgress.indexOf(requestFile.fileId, 0);
			if (index > -1) {
				this.downloadFilesInProgress.splice(index, 1);
			}
		});
	}

	get isCommentInputVisible(): boolean {
		return this.spotId === null;
	}

	get availableSendToCustomer(): boolean {
		return this.canSendToCustomer && this.gridSelectedFileIds.length > 0;
	}

	prepareFile(result: string | ArrayBuffer, file: RequestFile){
		var extension = file.fileExtension.toLowerCase();
		var base64data = (result as string).split(',')[1];  
		var image;
		switch (extension) {
			case ('.pdf'): 
			{  
				image = result;
				break;
			}
			default:   
				image = `data:image/png;base64,${base64data}`;
				break;
	   }

	   let attachment = new Attachment();
	   attachment.attachmentId = file.fileId;
	   attachment.fileBase64 = image;
	   attachment.attachmentExtension = file.fileExtension;
	   this.filesToShow.push(attachment);
	}
}
