import { Claim } from './Claim';

export class AuthUserDto {
	userId: number;
	login = '';
	userName = '';
	userRole = '';
	bearerToken = '';
	contragentId: number;
	isAuthenticated = false;
	expiresInUtc: Date;
	claims: Claim[] = [];
}
