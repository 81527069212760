export class CustomerObjectWithContactsDto {
    customerObjectId: number;
    name: string;
    address: string;
    addressDescription: string;
    contragentId: number;
    contragentName: string;

    contacts: CustomerObjectContactDto[] = [];
}

export class CustomerObjectContactDto {
    contactId: number;
    fullName: string;
    phoneNumber: string;
    email: string;
    isContragentContact: boolean;
}