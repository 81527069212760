import { OnInit } from '@angular/core';
import { DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { FilterDescriptor, isCompositeFilterDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppService } from '../../../../app.service';
import { GridSettings } from '../../../models/grid/grid-settings';
import { StatePersistingService } from '../../../services/state-persisting.service';

export abstract class PersistenceGridFeatures implements OnInit {

	public enableSaveGridSettings: boolean = false;
	gridSettingsStorageKey: string;
	public gridColumnOffset: number = 0;
	protected ignoreMoscowToLocalfixTimeZoneShift = false;

	public abstract loadGridData(): void;

	constructor(
		protected appService: AppService,
		protected persistingService: StatePersistingService
	) { }

	ngOnInit(): void {
		if (this.enableSaveGridSettings) {
			const gridSettings: GridSettings = this.persistingService.get(this.gridSettingsStorageKey);
			this.setGridSettings(gridSettings);
		}
	}

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: []
	}

	public gridSettingsFilter: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: []
	}

	protected onGridStateChange(): void {

	}

	public dataStateChange(state: DataStateChangeEvent): void {
		this.gridSettings.state = state;
		this.loadGridData();

		this.onGridStateChange();

		if (this.enableSaveGridSettings) {
			this.saveGridSettings();
		}
	}

	protected setGridSettings(gridSettings: GridSettings, isFilter: boolean = false) {

		let gs = this.gridSettings;

		if (isFilter) {
			gs = this.gridSettingsFilter;
		}

		if (gridSettings !== null) {
			this.fixTimeZoneShift(gridSettings, isFilter);
			this.syncronizeSettings(gridSettings, isFilter);
			if (gs.state.filter != null) {
				gs.state.filter.filters.forEach(x => {
					if (!isCompositeFilterDescriptor(x)) {
						var field = (<string>(<FilterDescriptor>x).field);
						if (gs.columnsConfig.some(x => x.field == field && x.filter === 'date')) {
							(<FilterDescriptor>x).value = this.appService.parseDate((<FilterDescriptor>x).value/*.replace(":000", ":00")*/);
						}
					}
				});
			}
		}
	}

	private fixTimeZoneShift(gridSettings: GridSettings, isFilter: boolean = false): void {
		let gs = this.gridSettings;

		if (isFilter) {
			gs = this.gridSettingsFilter;
		}

		let wrongFieldsNames = ["createdDate", "slaStartDate", "slaDueDate", "finishDate", "stoppedUntil", "stateUpdateDate", "endOfSlaDate"];
		let fieldsNames = gs.columnsConfig.map(m => m.field);

		if (gridSettings.state != null) {
			gridSettings.state.sort.forEach((s, i) => {
				if (wrongFieldsNames.includes(s.field)
					&& fieldsNames.includes(`${s.field}Moscow`)) {

					s.field = `${s.field}Moscow`;
				}
			});
		}

		if (gridSettings.state != null && gridSettings.state.filter != null) {
			this.fixTimeZoneShiftFilters(gridSettings.state.filter.filters, wrongFieldsNames, fieldsNames);
		}
	}

	private syncronizeSettings(gridSettings: GridSettings, isFilter: boolean = false): void {
		let gs = this.gridSettings;

		if (isFilter) {
			gs = this.gridSettingsFilter;
		}

		//Добавляем новые столбцы, удаляем ненужные столбцы
		var fieldsForAdd = [];
		var fieldsForRemove = [];

		gridSettings.columnsConfig.forEach(f => {
			if (f.field.indexOf('Moscow') != -1 && !this.ignoreMoscowToLocalfixTimeZoneShift) {
				f.field = f.field.replace('Moscow', 'Local');
			}

			if (!gs.columnsConfig.map(m => m.field).includes(f.field)) {
				fieldsForRemove.push(f);
			}
		});
		gs.columnsConfig.forEach(f => {
			if (!gridSettings.columnsConfig.map(m => m.field).includes(f.field)) {
				fieldsForAdd.push(f);
			}
		});
		fieldsForRemove.forEach(f => {
			gridSettings.columnsConfig.splice(gridSettings.columnsConfig.findIndex(fi => fi.field === f.field), 1);
		});
		fieldsForAdd.forEach(f => {
			gridSettings.columnsConfig.push(f);
		});
		gridSettings.columnsConfig.forEach(fieldGrid => {
			if (gs.columnsConfig.map(m => m.field).includes(fieldGrid.field)) {
				var fieldFromSource = gs.columnsConfig.find(fi => fi.field === fieldGrid.field);

				if (fieldFromSource.filter !== fieldGrid.filter) {
					fieldGrid.filter = fieldFromSource.filter;
				}
				if (fieldFromSource.filterable !== fieldGrid.filterable) {
					fieldGrid.filterable = fieldFromSource.filterable;
				}
				if (fieldFromSource.format !== fieldGrid.format) {
					fieldGrid.format = fieldFromSource.format;
				}
				if (fieldFromSource.title !== fieldGrid.title) {
					fieldGrid.title = fieldFromSource.title;
				}
			}
		});

		if (isFilter) {
			this.gridSettingsFilter = this.mapGridSettings(gridSettings);
		} else {
			this.gridSettings = this.mapGridSettings(gridSettings);
		}
	}

	private fixTimeZoneShiftFilters(fd: any[], wrongFieldsNames: string[], fieldsNames: string[]): void {
		fd.forEach(el => {
			if (el.filters) {
				this.fixTimeZoneShiftFilters(el.filters, wrongFieldsNames, fieldsNames);
			} else if (el.field) {
				if (wrongFieldsNames.includes(el.field)
					&& fieldsNames.includes(`${el.field}Moscow`)) {

					el.field = `${el.field}Moscow`;
				}
				if (el.field.indexOf('Moscow') != -1 && !this.ignoreMoscowToLocalfixTimeZoneShift) {
					el.field = el.field.replace('Moscow', 'Local');
				}
			}
		});
	}

	public mapGridSettings(gridSettings: GridSettings): GridSettings {
		const state = gridSettings.state;
		this.mapDateFilter(state.filter);
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
		};
	}

	protected saveGridSettings(): void {
		const gridConfig = {
			columnsConfig: this.gridSettings.columnsConfig,
			state: this.gridSettings.state
		};

		this.persistingService.set(this.gridSettingsStorageKey, gridConfig);
	}

	protected mapDateFilter = (descriptor: any) => {
		const filters = descriptor.filters || [];
		filters.forEach(filter => {
			if (filter.filters) {
				this.mapDateFilter(filter);
			} else if (filter.field === 'FirstOrderedOn' && filter.value) {
				filter.value = new Date(filter.value);
			}
		});
	}

	public onReorder(e: any): void {

		if (!this.enableSaveGridSettings) {
			return;
		}

		const oldIndex = e.oldIndex - this.gridColumnOffset;
		const newIndex = e.newIndex - this.gridColumnOffset;

		const columnSetting = this.gridSettings.columnsConfig[oldIndex];
		this.gridSettings.columnsConfig.splice(oldIndex, 1);
		this.gridSettings.columnsConfig.splice(newIndex, 0, columnSetting);

		var i = 0;
		this.gridSettings.columnsConfig = orderBy(this.gridSettings.columnsConfig, [{ field: "hidden", dir: "asc" }]);
		this.gridSettings.columnsConfig.forEach(col => {
			col.orderIndex = i++;
		});

		this.onGridStateChange();

		this.saveGridSettings();
	}

	public onResize(e: any): void {

		if (!this.enableSaveGridSettings) {
			return;
		}

		e.forEach(item => {
			this.gridSettings.columnsConfig.find(col => col.field === item.column.field).width = item.newWidth;
		});

		this.onGridStateChange();

		this.saveGridSettings();
	}

	public onVisibilityChange(e: any): void {

		if (!this.enableSaveGridSettings) {
			return;
		}

		e.columns.forEach(column => {
			this.gridSettings.columnsConfig.find(col => col.field === column.field).hidden = column.hidden;
		});

		var i = 0;
		this.gridSettings.columnsConfig = orderBy(this.gridSettings.columnsConfig, [{ field: "hidden", dir: "asc" }]);
		this.gridSettings.columnsConfig.forEach(col => {
			col.orderIndex = i++;
		});

		this.onGridStateChange();

		this.saveGridSettings();
	}

	public rowClass(context: RowClassArgs) {
		return {
			'cursor-pointer': true
		};
	}
}
