import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { DirtyStateGuard } from '../core/guards/dirty-state.guard';
import { ActivityDetailsComponent } from './request-components/activity-details/activity-details.component';

import { RequestsListPage } from './list/list.page';
import { RequestPage } from './request/request.page';
import { NewRequestPage } from './new-request/new-request.page';
import { RequestNotificationsComponent } from './request-components/request-notifications/request-notifications.component';
import { SerialNumbersValidationForm } from './request-components/serial-numbers-validation-form/serial-numbers-validation-form';
import { RequestEmailListComponent } from './request-components/request-email-list/request-email-list.component';
import { RequestEmailComponent } from './request-components/request-email/request-email.component';
import { ActivityFinesComponent } from './request-components/activity-fines/activity-fines.component';
import { NewRequestClientInfoComponent } from './new-request/sub-components/client-info/new-request-client-info.component';
import { NewRequestCustomerObjectInfoComponent } from './new-request/sub-components/customer-object-info/new-request-customer-object-info.component';
import { NewRequestGeneralInfoComponent } from './new-request/sub-components/general-info/new-request-general-info.component';
import { NewRequestServicesAndDevicesComponent } from './new-request/sub-components/services-and-devices/new-request-services-and-devices.component';

export const requestsRoutes: Routes = [

	{ path: '', component: RequestsListPage, canActivate: [AuthGuard] },
	{ path: 'my', component: RequestsListPage, canActivate: [AuthGuard] },
	{ path: 'my/high-priority', component: RequestsListPage, canActivate: [AuthGuard] },
	{ path: 'my/overdue', component: RequestsListPage, canActivate: [AuthGuard] },
	
	{ path: 'new', component: NewRequestPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },
	{ path: ':requestId', component: RequestPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

];

@NgModule({
	imports: [],
	exports: [RouterModule]
})
export class RequestsRoutingModule {
	public static components = [
		RequestsListPage,
		NewRequestPage,
		RequestPage,
		ActivityDetailsComponent,
		ActivityFinesComponent,
		RequestNotificationsComponent,
		SerialNumbersValidationForm,
		RequestEmailListComponent,
		RequestEmailComponent,
		NewRequestClientInfoComponent,
		NewRequestCustomerObjectInfoComponent,
		NewRequestGeneralInfoComponent,
		NewRequestServicesAndDevicesComponent,
	];
}
