import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { CustomerObject } from '../../../shared/models/customerObject/CustomerObject';
import { CustomerObjectsService } from '../customer-objects.service';
import { EntityViewModel } from '../../../shared/models/core/EntityViewModel';
import { CategoryType } from '../../../shared/enums/category-type.enum';
import { ContragentsService } from '../../contragents/contragents.service';
import { ContragentWithContactDto } from '../../../shared/models/contragent/contragent-with-contact-dto';
import { CustomerObjectWithContactsDto } from '../../../shared/models/customerObject/customer-object-with-contacts.dto';
import { NewRequestDevice } from '../../../shared/models/request/new-request/new-request-device';

const flatten = filter => {
    const filters = filter.filters;
    if (filters) {
        return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
    }
    return [];
};

@Component({
    selector: 'categories-customer-object',
    templateUrl: './customer-object.page.html',
    styleUrls: [
        './customer-object.page.scss',
        '../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
    ]
})
export class CustomerObjectPage extends ComponentPageBase<CustomerObject> {

    headerName = "Основное";
	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.customerObjects;

    loaded: boolean = false;

    customerObject: CustomerObjectWithContactsDto = null;
    selectedContragent: ContragentWithContactDto = null;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected appService: AppService,
        protected notificationService: NotificationService,
        protected dataService: CustomerObjectsService,
        private contragentsService: ContragentsService
    ) {
        super(route, router, appService, notificationService, dataService);
        this.listPageRoutePath = 'categories/customer-objects';
        this.appService.pageTitle = 'Новый объект обслуживания';
    }

    ngOnInit() {
        this.entityId = this.route.snapshot.paramMap.get('customerObjectId');

        if (this.entityId != null) {
            this.dataService.getCustomerObjectWithContacts(+this.entityId).subscribe(vm => {
            this.entityName = `${vm.name}`;
            this.customerObject = vm;
            //this.dataBind(vm);

            this.contragentsService.getContragentWithContact(vm.contragentId).subscribe(resp => {
                this.selectedContragent = resp;
                this.loaded = true;
            })
        });
        } else {
            this.customerObject = new CustomerObjectWithContactsDto();
            this.entityViewModel = new EntityViewModel<CustomerObject>();
            this.entityViewModel.entity = new CustomerObject();
            this.entityViewModel.entity.createdDate = new Date();
            this.canEditForm = true;
            this.isNew = true;
            this.loaded = true;
        }
    }

    afterSaveChangesChild() {
        if (this.isNew) {
            this.router.navigate([this.listPageRoutePath]);
        }
  }

    refreshCustomerObject(customerObject: CustomerObjectWithContactsDto) {
        this.customerObject = customerObject;
    }

    saveRequestDevices(requestDevices: NewRequestDevice[] = []) {
    }

    updateContragent(contragent: ContragentWithContactDto) {
        this.selectedContragent = contragent;
        
        this.customerObject.contragentId = contragent != null
            ? contragent.id
            : null;

        if (!this.isNew && contragent != null) {
            this.dataService.updateCustomerObjectWithContacts(this.customerObject).subscribe(() => {
                this.dataService.getCustomerObjectWithContacts(this.customerObject.customerObjectId).subscribe((resp) => {
                    this.customerObject = resp;
                });
            });
        }
    }

    saveChanges() {
        this.dataSaving = true;
        this.dataService.createCustomerObjectWithContacts(this.customerObject).subscribe((resp) => {
            this.dataSaving = false;
            window.location.replace(`/categories/customer-object/${resp.data}`);        
        });
    }

    get customerObjectInfoDisabled(): boolean {
        return this.selectedContragent == null || 
            this.selectedContragent.id == null || 
            this.selectedContragent.id == 0;
    }

    get createCustomerObjectAvailable(): boolean {
        return this.customerObject != null && 
            this.customerObject.customerObjectId != null &&
            this.customerObject.contragentId != null && 
            this.customerObject.contragentId != 0;
    }
}
