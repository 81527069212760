import { AccordionGroupComponent } from './components/accordion/accordion-group.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { NgModule, Sanitizer } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhonePipe } from './pipes/Phone.pipe';
import { CommentsFilterPipe } from './pipes/selected-comment-kinds.pipe';
import { DateFormatPipe } from './pipes/DateFormatPipe.pipe';
import { NumberFormatPipe } from './pipes/NumberFormatPipe.pipe';
import { AuthPipe } from './pipes/auth.pipe';
import { CommentsComponent } from './components/comments/comments.component';
import { CommentsBlockComponent } from './components/comments/comments-block/comments-block.component';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { PopupModule } from '@progress/kendo-angular-popup';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TreeDropdownListModule } from '../tree-dropdown-list';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { NgxImageCompressService } from 'ngx-image-compress'
import { ButtonGroupModule, ButtonModule } from '@progress/kendo-angular-buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import 'hammerjs';
import { EntityStateFilterComponent } from './components/grid/customFilters/entitystate/entitystate-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BooleanFilterComponent } from './components/grid/customFilters/boolean/boolean-filter.component';
import { HasClaimDirective } from './directives/has-claim.directive';
import { DropDownFilterComponent } from './components/grid/customFilters/dropdown-filter/dropdown-filter.component';
import { VirtualDropDownFilterComponent } from './components/grid/customFilters/virtual-dropdown-filter/virtual-dropdown-filter.component';
import { EntityInfoComponent } from './components/entity-info/entity-info.component';
import { LookupService } from './services/lookup.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RequestsService } from './services/requests.service';
import { LaddaModule } from 'angular2-ladda';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { EthereumGateComponent } from './components/ethereum-gate-users/ethereum-gate-users.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { AgreementsService } from './services/agreements.service';
import { NewRequestsService } from './services/new-requests.service';
import { UserGroupsService } from './services/user-groups.service';
import { SetPerformerUserGroup } from './components/workflow-transitions/set-performer-user-group/set-performer-user-group';
import { SetPerformerGroup } from './components/workflow-transitions/set-performer-group/set-performer-group';
import { UsersService } from '../admin/users/users.service';
import { ActivitiesService } from './services/activities.service';
import { AttachmentsService } from './services/attachments.service';
import { EthereumGateService } from './services/ethereum-gate.service';
import { UploadAttachmentsComponent } from './components/upload-attachments/upload-attachments.component';
import { SetComment } from './components/workflow-transitions/set-comment/set-comment';
import { SetSn } from './components/workflow-transitions/set-sn/set-sn';
import { ActivityOperationsService } from './services/activity-operations.service';
import { RequestDevicesService } from './services/request-devices.service';
import { RequestServicesService } from './services/request-services.service';
import { RequestDevicesComponent } from './components/request-devices/request-devices.component';
import { DevicesService } from './services/devices.service';
import { DevicesListPage } from './components/devices/list/list.page';
import { MobileFooterComponent } from './components/mobile-footer/mobile-footer.component';
import { NavbarHeaderTextComponent } from './components/navbar-header-text/navbar-header-text.component';
import { NavbarHeaderLeftActionsComponent } from './components/navbar-header-left-actions/navbar-header-left-actions.component';
import { NavbarHeaderRightActionsComponent } from './components/navbar-header-right-actions/navbar-header-right-actions.component';
import { AutosizeModule } from 'ngx-autosize';
import { ServiceCentersService } from './services/service-centers.service';
import { StatePersistingService } from './services/state-persisting.service';
import { DateRangeFilterCellComponent } from './components/grid/customFilters/date-range-cell-filter.component';
import { MultiSelectLookupFilterComponent } from './components/grid/customFilters/multiselect-dropdown-filter/multiselect-dropdown-filter.component';
import { MultiSelectLookupFilterVirtualComponent } from './components/grid/customFilters/multiselect-dropdown-filter-virtual/multiselect-dropdown-filter-virtual.component';
import { SetReasonForStopping } from './components/workflow-transitions/set-reason-for-stopping/set-reason-for-stopping';
import { CheckDevicesAtWarehouseComponent, SetDeviceSerialNumbersComponent } from './components/workflow-transitions';
import { RequestUninstallDevicesService } from './services/request-uninstall-devices.service';
import { SetActivityReasonComponent } from './components/workflow-transitions/set-activity-reason/set-activity-reason.component';
import { SetActivityReasonForStoppingComponent } from './components/workflow-transitions/set-activity-reason-for-stopping/set-activity-reason-for-stopping.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { DeviceAttachmentsComponent } from './components/device-attachments/device-attachments.component';
import { RequestDeviceFilterPipe } from './components/request-devices/request-device-filter.pipe';
import { ReportsService } from './services/reports.service';
import { GridContextMenuComponent } from './components/grid/grid-context-menu.component';
import { MultipleOperationsComponent } from './components/multiple-operations/multiple-operations.component';
import { MultipleOperationErrorResultComponent } from './components/multiple-operations/multiple-operation-error-result/multiple-operation-error-result.component';
import { DaDataAddressComponent } from './components/dadata-address/dadata-address.component';
import { DaDataService } from './services/dadata.service';
import { RequestCustomerContactService } from './services/request-customer-contacts.service';
import { ContactsComponent } from './components/contacts/contacts.component';
import { UserFiltersService } from './services/user-filters.service';
import { UploadInChunksService } from './services/upload-in-chunks.service';
import { LineListDirective } from './directives/line-list.directive';
import { CallsService } from './services/calls.service';
import { CallsComponent } from './components/calls/calls.component';
import { MapYaComponent } from "../requests/map-ya/map-ya.component";
import { AngularYandexMapsModule } from "angular8-yandex-maps";
import { mapConfig } from "../requests/map-ya/map-ya-config";
import { For1CService } from './services/for-1c.service';
import { RequestNotificationTypeService } from './services/request-notification-type.service';
import { SetCarrier } from './components/workflow-transitions/set-carrier/set-carrier';
import { CarriersService } from './services/carriers.service';
import { MobileRequestsCardComponent } from './components/mobile-requests-card/mobile-requests-card.component';
import { MapYaDataService } from './services/map-ya-data.service';
import { MapYaPolygonComponent } from '../requests/map-ya-components/map-ya-polygon/map-ya-polygon.component';
import { SetCancelReserveReason } from './components/request-devices/set-cancel-reserve-reason/set-cancel-reserve-reason.component';
import { CategoryServicesService } from './services/category-services.service';
import { ServiceModesService } from './services/service-modes.service';
import { ServiceCategoriesService } from './services/service-categories.service';
import { ServiceTypesService } from './services/service-types.service';
import { ChangeLogGrid } from './components/categories/change-log/change-log.grid';
import { CategoriesService } from './services/categories.service';
import { EditTidsComponent } from './components/tid/edit-tids/edit-tids.component';
import { WarehousesService } from './services/warehouses.service';
import { ExpertiseFormComponent } from '../requests/request-components/expertise-form/expertise-form.component';
import { ExpertiseFormDropdownList } from '../requests/request-components/expertise-form/expertise-form-components/dropdown-list/expertise-form-dropdown-list.component';
import { ExpertiseFormMultiselectList } from '../requests/request-components/expertise-form/expertise-form-components/multiselect-list/expertise-form-multiselect-list.component';
import { ExpertiseActCheckbox } from '../requests/request-components/expertise-form/expertise-form-components/checkbox/expertise-act-checkbox.component';
import { InstructionsListComponent } from '../requests/request-components/instructions/instructionlist.component'
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { ImagePopup } from './components/image-popup/image-popup.component';
import { ImagePopupExtended } from './components/image-popup-extended/image-popup-extended.component';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { OrgTreeComponent } from './components/org-tree/org-tree.component';
import { RequestFilesService } from './services/request-files.service';
import { RequestSlaStepsComponent } from './components/request-sla-steps/request-sla-steps.component';
import { RequestRegistrationFiltersService } from './services/request-registration-filters.service';
import { RequestRegistrationFilterComponent } from './components/request-registration-filter/request-registration-filter.component';
import { MobileRequestCardComponent } from './components/mobile-request-card/mobile-request-card.component';
import { LookupDropDownComponent } from './components/lookup-dropdown/lookup-dropdown.component';
import { ColorSchemeSettingsService } from './services/color-scheme-settings.service';
import { RegionsService } from './services/regions.service';
import { ContragentContactsService } from "./services/contragent-contacts.service";
import { ContragentDetailsService } from "./services/contragent-details.service";
import { ContragentPolygonComponent } from "../categories/contragents/contragent/components/polygon/contragent-polygon.component";
import { RequestEmailsService } from './services/request-emails.service';
import { AttachmentTypeConfigurationsService } from './services/attachment-type-configurations.service';
import { AttachmentTypesService } from './services/attachment-types.service';
import { RequestRegistrationFilterChangeLogComponent } from './components/request-registration-filter-change-log/request-registration-filter-change-log.component';
import { AutomaticRegistrationRequestsGridComponent } from './components/automatic-registration-requests-grid/automatic-registration-requests-grid.component';
import { SetSnReceive } from './components/workflow-transitions/set-sn-receive/set-sn-receive.component';
import { PopupComment } from './components/grid/popupComment/popup-comment.component';
import { RequestFilesComponent } from '../requests/request-components/request-files/request-files.component';
import { RegisterUserRequestComponent } from '../requests/request-components/register-user-request/register-user-request.component';
import { SelfEmployedComponent } from '../admin/users/user/self-employed/self-employed.component';
import { LoadFinancialReconciliationWarningComponent } from './components/financial-reconciliation-warning/financial-reconciliation-warning';
import { NotificationMultiSelectFilterComponent } from './components/grid/customFilters/notification-multiselect-dropdown-filter/notification-multiselect-filter.component';
import { NotificationButtonComponent } from './components/grid/notification-button/notification-button.component';
import { DevicePage } from './components/devices/device/device.page';
import { ContragentProjectsService } from './services/contragent-projects.service';
import { CustomerObjectWorkingHoursComponent } from "./components/customer-object/customer-object-working-hours/customer-object-working-hours.component"
import { MobileTextDialog } from './components/mobile-request-card/mobile-text-dialog/mobile-text-dialog.component';

import { TextMaskModule } from 'angular2-text-mask';
import { WarehouseAreasService } from './services/warehouse-areas.service';
import { WarehouseCellsService } from './services/warehouse-cells.service';
import { EngubatorPlanningService } from './services/engubator/engubator.planning.service';
import { EngubatorEngineersService } from './services/engubator/engubator.engineers.service';
import { VeerouteCalendarComponent } from '../requests/veeroute/veeroute-calendar.component';
import { EngubatorRequestsService } from './services/engubator/engubator.requests.service';
import { VeerouteCommonSettingsService } from './services/engubator/engubator.common-settings.service';
import { EngubatorSkillsService } from './services/engubator/engubator.skills.service';
import { EngubatorPlanningZonesService } from './services/engubator/engubator.planningZones.service';
import { EngubatorLookupService } from './services/engubator/engubator.lookup.service';
import { EngubatorRegionsService } from './services/engubator/engubator.regions.service';
import { EngubatorRegionSegmentsService } from './services/engubator/engubator.region-segments.service';
import { EngineersRegionsComponent } from '../admin/users/user/regions/regions.component';
import { TransitionConfirmationRequestComponent } from './components/workflow-transitions/transition-confirmation-request/transition-confirmation-request.component';
import { DeviceTypesService } from './services/device-types.service';
import { NoWhitespaceAtStartDirective } from './directives/no-whitespace-at-start.directive';
import { NoEmptyStringsDirective } from './directives/no-empty-strings';
import { WarehouseTransferSummaryComponent } from '../requests/request-components/warehouse-transfer-summary/warehouse-transfer-summary.component';
import {InitTypesService} from "./services/init-types.service";
import {RequestStatusesService} from "./services/request-statuses.service";
import {RequestStatusMspAliasGroupsService} from "./services/request-status-msp-alias-groups.service";
import {ActivityStatusesService} from "./services/activity-statuses.service";
import {RequestAttributesService} from "./services/request-attributes.service";
import {RequestPrioritiesService} from "./services/request-priorities.service";
import { AddressService } from './services/address.service';
import { RequestTypeService } from './services/request-type.service';
import { ArticlesService } from './services/articles.service';
import { RequestServicesComponent } from './components/request-services/request-services.component';
import { CompleteWorkUninstallNew } from './components/workflow-transitions/complete-work-uninstall-new/complete-work-uninstall-new.component';
import { ConfigurationPreparing } from './components/workflow-transitions/configuration-preparing/configuration-preparing.component';
import { RequestConfigurationService } from './services/request-configuration.service';
import { TmsGateDataService } from './services/tmsgate.data.service';
import { TmsConfigurationService } from './services/tms-configuration.service';
import {MerchantsService} from "../categories/merchants/merchants.service";
import {ActivityTypesService} from "./services/activity-types.service";
import { RequestDeliveryComponent } from '../requests/request-components/request-delivery/request-delivery.component';
import { DeliveryService } from './services/delivery/delivery.service';
import { TransportCompanyService } from './services/delivery/transport-company.service';
import { ActivityFinesService } from './services/activity-fines/activity-fines.serivce';
import { ActivityFineTypesService } from './services/activity-fines/activity-fine-types.service';
import { ExportExcelFromGridService } from './services/export-excel-from-grid.service';
import { WarehouseWorkplaceRequestsService } from './services/warehouse-workplace-requests.service';
import { SetSnComponents as SetSnComponents } from './components/workflow-transitions/set-sn-components/set-sn-components';
import { RequestPublicationSettingsService } from './services/request-publication-settings.service';
import { SpecialRightsGroupsService } from '../admin/roles/special-rigths-group/special-rights-groups.service';
import { DeviceConditionsService } from './services/device-conditions.service';
import { EditingDismantledDevices } from './components/workflow-transitions/editing-dismantled-devices/editing-dismantled-devices';
import { WorkEndedSuccessInstall } from './components/workflow-transitions/work-ended-success-install/work-ended-success-install';
import { AddingDevice } from './components/workflow-transitions/adding-device/adding-device';
import { ConfirmDeviceDifferent } from './components/workflow-transitions/confirm-device-different/confirm-device-different';
import { DeliveryOfEquipment } from './components/workflow-transitions/delivery-of-equipment/delivery-of-equipment';
import { KendoDropdownListComponent } from './components/workflow-transitions/kendo-dropdown-list/kendo-dropdown-list.component';
import { RequestStoppingReasonsService } from './services/request-stopping-reasons.service';
import { RequestStoppingReasonAliasesService } from './services/request-stopping-reason-aliases.service';
import { RequestStoppingReasonKindsService } from './services/request-stopping-reason-kinds.service';
import { CallContactsComponent } from './components/call-contacts/call-contacts.component';
import { StoppingReasonTypeRulesService } from './services/stopping-reason-type-rules.service';
import { ActivitySuccessResultFlowContainer } from './components/workflow-transitions/flow-containers/activity-success-result-flow-container/activity-success-result-flow-container';
import { CompleteActivityResultPropertiesComponent } from './components/workflow-transitions/flow-components/complete-activity-result-properties/complete-activity-result-properties.component';
import { NomenclatureService } from './services/nomenclature.service';
import { CompleteActivityDismantledSeriesComponent } from './components/workflow-transitions/flow-components/complete-activity-dismantled-series/complete-activity-dismantled-series.component';
import { DualChoiceComponent } from './components/workflow-transitions/flow-components/dual-choice/dual-choice.component';
import { DismantledSeriesSerialPartNumbersComponent } from './components/workflow-transitions/flow-components/dismantled-series-serial-part-numbers/dismantled-series-serial-part-numbers.component';
import { DismantledSeriesGeneralProperties } from './components/workflow-transitions/flow-components/dismantled-series-general-properties/dismantled-series-general-properties.component';
import { DismantledSeriesImagesComponent } from './components/workflow-transitions/flow-components/dismantled-series-images/dismantled-series-images.component';
import { CompleteActivityImageUploader } from './components/workflow-transitions/flow-components/complete-activity-image-uploader/complete-activity-image-uploader.component';
import { CheckDevicesAtWarehouseMobileComponent } from './components/workflow-transitions/check-devices-at-warehouse-mobile/check-devices-at-warehouse-mobile.component';
import { CompleteActivityDismantledSimcardComponent } from './components/workflow-transitions/flow-components/complete-activity-dismantled-simcard/complete-activity-dismantled-simcard.component';
import { CompleteActivityRequiredAttachmentComponent } from './components/workflow-transitions/flow-components/complete-activity-required-attachment/complete-activity-required-attachment.component';
import { CompleteActivitySummaryComponent } from './components/workflow-transitions/flow-components/complete-activity-summary/complete-activity-summary.component';
import { ExternalService } from './services/external.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BrowserModule } from '@angular/platform-browser';
import { SetPlannedDateComponent } from './components/workflow-transitions/set-planned-date/set-planned-date.component';
import { RequestDeviceWarehouseRefillsService } from './services/request-device-warehouse-refills.service';
import { SetSnSetPackageSizeComponent } from './components/workflow-transitions/set-sn/set-sn-set-size/set-sn-set-package-size.component';
import { InstallFlowDeviceComponent } from './components/workflow-transitions/flow-components/install-flow-components/install-flow-device/install-flow-device.component';
import { InstallFlowSimcardComponent } from './components/workflow-transitions/flow-components/install-flow-components/install-flow-simcard/install-flow-simcard.component';
import { InstallFlowSummaryComponent } from './components/workflow-transitions/flow-components/install-flow-components/install-flow-summary/install-flow-summary.component';
import { InstallFlowDeviceSerialComponent } from './components/workflow-transitions/flow-components/install-flow-components/install-flow-device/install-flow-device-serial/install-flow-device-serial.component';
import { OSMService } from './services/oSM.service';
import { AddIntakeInfoPage } from '../requests/request-components/request-delivery/add-intake-info/add-intake-info.page';
import { BoxBerryService } from './services/delivery/box-berry.service';
import { RequestCategoryService } from './services/request-categoty.service';
import { RequestPageMainComponent } from './components/request-page/request-page-main/request-page-main.component';
import { RequestPageActivitiesComponent } from './components/request-page/request-page-activities/request-page-activities.component';
import { ActivityWorkflowComponent } from '../requests/request-components/activity-workflow/activity-workflow.component';
import { RequestPageCustomerObjectInfoComponent } from './components/request-page/request-page-customer-object/sub-components/request-page-customer-object-info/request-page-customer-object-info.component';
import { RequestPageCustomerObjectContactsComponent } from './components/request-page/request-page-customer-object/sub-components/request-page-customer-object-contacts/request-page-customer-object-contacts.component';
import { RequestPageCustomerObjectDevicesComponent } from './components/request-page/request-page-customer-object/sub-components/request-page-customer-object-devices/request-page-customer-object-devices.component';
import { RequestPageCustomerObjectRequestsComponent } from './components/request-page/request-page-customer-object/sub-components/request-page-customer-object-requests/request-page-customer-object-requests.component';
import { RequestPageCustomerObjectComponent } from './components/request-page/request-page-customer-object/request-page-customer-object.component';
import { RequestPageServicesComponent } from './components/request-page/request-page-services/request-page-services.component';
import { RequestPageChangeLogComponent } from './components/request-page/request-page-change-log/request-page-change-log.component';
import { CreateCustomerObjectComponent } from './components/customer-object/create-customer-object/create-customer-object.component';
import { CreateContragentComponent } from './components/contragents/create-contragent/create-contragent.component';
import { CustomerObjectPageContragentInfoComponent } from '../categories/customer-objects/customer-object/sub-components/customer-object-page-contragent-info/customer-object-page-contragent-info.component';
import { CustomerObjectPageRelatedRequestsComponent } from '../categories/customer-objects/customer-object/sub-components/customer-object-page-related-requests/customer-object-page-related-requests.component';
import { CustomerObjectPageInfoWithContactsComponent } from '../categories/customer-objects/customer-object/sub-components/customer-object-page-info-with-contacts/customer-object-page-info-with-contacts.component';
import { CustomerObjectPageDevicesComponent } from '../categories/customer-objects/customer-object/sub-components/customer-object-page-devices/customer-object-page-devices.component';
import { RequestPageDevicesComponent } from './components/request-page/request-page-devices/request-page-devices.components';
import { DeviceModelsService } from './services/device-models.service';
import { ContragentCustomerObjectsComponent } from '../categories/contragents/contragent/components/contragent-customer-objects/contragent-customer-objects.component';
import { SetupDeviceSerial } from './components/workflow-transitions/setup-device-serial/setup-device-serial';
import { FinishActivityWithDevicesServices } from './components/workflow-transitions/finish-activity-with-devices-services/finish-activity-with-devices-services';
import { RequestStatusWorkflowTransitionsComponent } from './components/request-page/request-page-main/request-status-workflow-transitions/request-status-workflow-transitions.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		CoreModule,
		GridModule,
		ListViewModule,
		TreeViewModule,
		DropDownsModule,
		InputsModule,
		FormsModule,
		DateInputsModule,
		PerfectScrollbarModule,
		LaddaModule,
		DialogsModule,
		UploadModule,
		NgbModule,
		ExcelModule,
		AutosizeModule,
		PopupModule,
		TreeDropdownListModule,
		ReactiveFormsModule,
		ScrollViewModule,
		TreeDropdownListModule,
		AngularYandexMapsModule.forRoot(mapConfig),
		NgxImageGalleryModule,
		PinchZoomModule,
		NgxOrgChartModule,
		TextMaskModule,
		LoaderModule,
		ButtonGroupModule,
		ButtonModule,
		CarouselModule,
		TooltipModule,
		PdfViewerModule,
		BrowserModule
	],
	entryComponents: [
		DevicesListPage,
		DevicePage,
		SetPerformerUserGroup,
		SetPerformerGroup,
		SetComment,
		SetSn,
		SetSnComponents,
		TransitionConfirmationRequestComponent,
		ConfigurationPreparing,
		EditingDismantledDevices,
		AutomaticRegistrationRequestsGridComponent,
		SetCarrier,
		SetSnReceive,
		SetReasonForStopping,
		EthereumGateComponent,
		RequestDevicesComponent,
		RequestServicesComponent,
		SetCancelReserveReason,
		CreateContragentComponent,
		CreateCustomerObjectComponent,
		RequestPageMainComponent,
		RequestStatusWorkflowTransitionsComponent,
		RequestPageActivitiesComponent,
		RequestPageCustomerObjectInfoComponent,
		RequestPageCustomerObjectContactsComponent,
		RequestPageCustomerObjectDevicesComponent,
		RequestPageCustomerObjectRequestsComponent,
		RequestPageCustomerObjectComponent,
		RequestPageServicesComponent,
		RequestPageDevicesComponent,
		RequestPageChangeLogComponent,
		ActivityWorkflowComponent,
		CustomerObjectPageContragentInfoComponent,
		CustomerObjectPageRelatedRequestsComponent,
		CustomerObjectPageInfoWithContactsComponent,
		CustomerObjectPageDevicesComponent,

		SetDeviceSerialNumbersComponent,
		CompleteWorkUninstallNew,
		SetActivityReasonComponent,
		SetActivityReasonForStoppingComponent,
		DeviceAttachmentsComponent,
		MultipleOperationsComponent,
		VeerouteCalendarComponent,
		MultipleOperationErrorResultComponent,
		CheckDevicesAtWarehouseComponent,
		CheckDevicesAtWarehouseMobileComponent,
		EditTidsComponent,
		ImagePopup,
		ImagePopupExtended,
		PopupComment,
		LoadFinancialReconciliationWarningComponent,
		WorkEndedSuccessInstall,
		SetupDeviceSerial,
		FinishActivityWithDevicesServices,
		AddingDevice,
		ConfirmDeviceDifferent,
		DeliveryOfEquipment,
		CallContactsComponent,
		ActivitySuccessResultFlowContainer,
		CompleteActivityResultPropertiesComponent,
		CompleteActivityDismantledSeriesComponent,
		DualChoiceComponent,
		DismantledSeriesImagesComponent,
		DismantledSeriesSerialPartNumbersComponent,
		DismantledSeriesGeneralProperties,
		CompleteActivityImageUploader,
		CompleteActivityDismantledSimcardComponent,
		CompleteActivityRequiredAttachmentComponent,
		CompleteActivitySummaryComponent,
		SetPlannedDateComponent,
		SetSnSetPackageSizeComponent,
		InstallFlowDeviceComponent,
		InstallFlowSimcardComponent,
		InstallFlowSummaryComponent,
		InstallFlowDeviceSerialComponent,
		AddIntakeInfoPage,
	],
	declarations: [
		// Components
		CommentsComponent,
		CommentsBlockComponent,
		EntityStateFilterComponent,
		BooleanFilterComponent,
		GridContextMenuComponent,
		MultipleOperationErrorResultComponent,
		DropDownFilterComponent,
		MultiSelectLookupFilterComponent,
		MultiSelectLookupFilterVirtualComponent,
		NotificationMultiSelectFilterComponent,
		NotificationButtonComponent,
		VirtualDropDownFilterComponent,
		DateRangeFilterCellComponent,
		EntityInfoComponent,
		AttachmentsComponent,
		EthereumGateComponent,
		MultipleOperationsComponent,
		UploadAttachmentsComponent,
		RequestRegistrationFilterComponent,
		RequestDevicesComponent,
		RequestServicesComponent,
		DevicesListPage,
		DevicePage,
		MobileFooterComponent,
		NavbarHeaderTextComponent,
		NavbarHeaderLeftActionsComponent,
		NavbarHeaderRightActionsComponent,
		DaDataAddressComponent,
		ContactsComponent,
		RequestSlaStepsComponent,
		RequestRegistrationFilterChangeLogComponent,
		CallsComponent,
		MobileRequestsCardComponent,
		MapYaComponent,
		MapYaPolygonComponent,
		ContragentPolygonComponent,
		ContragentCustomerObjectsComponent,
		SetCancelReserveReason,
		EditTidsComponent,
		AccordionComponent,
		AccordionGroupComponent,
		ExpertiseFormDropdownList,
		ExpertiseFormMultiselectList,
		ExpertiseActCheckbox,
		MobileRequestCardComponent,
		LookupDropDownComponent,
		PopupComment,
		RequestFilesComponent,
		SelfEmployedComponent,
		RegisterUserRequestComponent,
		CustomerObjectWorkingHoursComponent,
		InstructionsListComponent,
		VeerouteCalendarComponent,
		EngineersRegionsComponent,
		RequestDeliveryComponent,
		SetSnSetPackageSizeComponent,
		CreateContragentComponent,
		CreateCustomerObjectComponent,
		RequestPageMainComponent,
		RequestStatusWorkflowTransitionsComponent,
		RequestPageActivitiesComponent,
		RequestPageCustomerObjectInfoComponent,
		RequestPageCustomerObjectContactsComponent,
		RequestPageCustomerObjectDevicesComponent,
		RequestPageCustomerObjectRequestsComponent,
		RequestPageCustomerObjectComponent,
		RequestPageServicesComponent,
		RequestPageDevicesComponent,
		RequestPageChangeLogComponent,
		ActivityWorkflowComponent,
		CustomerObjectPageContragentInfoComponent,
		CustomerObjectPageRelatedRequestsComponent,
		CustomerObjectPageInfoWithContactsComponent,
		CustomerObjectPageDevicesComponent,
		// Workflow
		SetPerformerUserGroup,
		SetPerformerGroup,
		SetComment,
		SetSn,
		SetSnComponents,
		TransitionConfirmationRequestComponent,
		ConfigurationPreparing,
		EditingDismantledDevices,
		AutomaticRegistrationRequestsGridComponent,
		SetCarrier,
		SetSnReceive,
		SetReasonForStopping,
		SetDeviceSerialNumbersComponent,
		CompleteWorkUninstallNew,
		SetActivityReasonComponent,
		SetActivityReasonForStoppingComponent,
		CheckDevicesAtWarehouseComponent,
		CheckDevicesAtWarehouseMobileComponent,
		ExpertiseFormComponent,
		KendoDropdownListComponent,
		SetPlannedDateComponent,

		DeviceAttachmentsComponent,
		ImageGalleryComponent,

		HasClaimDirective,
		NoWhitespaceAtStartDirective,
		NoEmptyStringsDirective,
		WorkEndedSuccessInstall,
		SetupDeviceSerial,
		FinishActivityWithDevicesServices,
		AddingDevice,
		ConfirmDeviceDifferent,
		DeliveryOfEquipment,
		MobileTextDialog,
		ActivitySuccessResultFlowContainer,
		CompleteActivityResultPropertiesComponent,
		CompleteActivityDismantledSeriesComponent,
		DualChoiceComponent,
		DismantledSeriesImagesComponent,
		DismantledSeriesSerialPartNumbersComponent,
		DismantledSeriesGeneralProperties,
		CompleteActivityImageUploader,
		CompleteActivityDismantledSimcardComponent,
		CompleteActivityRequiredAttachmentComponent,
		CompleteActivitySummaryComponent,
		InstallFlowDeviceComponent,
		InstallFlowSimcardComponent,
		InstallFlowSummaryComponent,
		InstallFlowDeviceSerialComponent,

		// Pipes
		PhonePipe,
		DateFormatPipe,
		NumberFormatPipe,
		LineListDirective,
		RequestDeviceFilterPipe,
		AuthPipe,
		CommentsFilterPipe,

		ChangeLogGrid,
		ImagePopup,
		ImagePopupExtended,
		OrgTreeComponent,
		LoadFinancialReconciliationWarningComponent,
		WarehouseTransferSummaryComponent,
		CallContactsComponent,
		AddIntakeInfoPage
	],
	exports: [
		//Components
		CommentsComponent,
		CommentsBlockComponent,
		EntityStateFilterComponent,
		BooleanFilterComponent,
		GridContextMenuComponent,
		MultipleOperationErrorResultComponent,
		DropDownFilterComponent,
		MultiSelectLookupFilterComponent,
		MultiSelectLookupFilterVirtualComponent,
		NotificationMultiSelectFilterComponent,
		NotificationButtonComponent,
		VirtualDropDownFilterComponent,
		DateRangeFilterCellComponent,
		EntityInfoComponent,
		AttachmentsComponent,
		EthereumGateComponent,
		MultipleOperationsComponent,
		UploadAttachmentsComponent,
		RequestRegistrationFilterComponent,
		RequestDevicesComponent,
		RequestServicesComponent,
		DevicesListPage,
		DevicePage,
		MobileFooterComponent,
		NavbarHeaderTextComponent,
		NavbarHeaderLeftActionsComponent,
		NavbarHeaderRightActionsComponent,
		DaDataAddressComponent,
		ContactsComponent,
		RequestSlaStepsComponent,
		RequestRegistrationFilterChangeLogComponent,
		CallsComponent,
		MobileRequestsCardComponent,
		MapYaComponent,
		ContragentPolygonComponent,
		ContragentCustomerObjectsComponent,
		AccordionComponent,
		AccordionGroupComponent,
		ExpertiseFormDropdownList,
		ExpertiseFormMultiselectList,
		ExpertiseActCheckbox,
		MobileRequestCardComponent,
		LookupDropDownComponent,
		PopupComment,
		RequestFilesComponent,
		SelfEmployedComponent,
		RegisterUserRequestComponent,
		CustomerObjectWorkingHoursComponent,
		InstructionsListComponent,
		VeerouteCalendarComponent,
		EngineersRegionsComponent,
		RequestDeliveryComponent,
		RequestPageMainComponent,
		RequestStatusWorkflowTransitionsComponent,
		CreateContragentComponent,
		CreateCustomerObjectComponent,
		RequestPageActivitiesComponent,
		RequestPageCustomerObjectInfoComponent,
		RequestPageCustomerObjectContactsComponent,
		RequestPageCustomerObjectDevicesComponent,
		RequestPageCustomerObjectRequestsComponent,
		RequestPageCustomerObjectComponent,
		RequestPageServicesComponent,
		RequestPageDevicesComponent,
		RequestPageChangeLogComponent,
		ActivityWorkflowComponent,
		CustomerObjectPageContragentInfoComponent,
		CustomerObjectPageRelatedRequestsComponent,
		CustomerObjectPageInfoWithContactsComponent,
		CustomerObjectPageDevicesComponent,
		//Workflow
		SetPerformerUserGroup,
		SetPerformerGroup,
		SetComment,
		SetSn,
		SetSnComponents,
		TransitionConfirmationRequestComponent,
		ConfigurationPreparing,
		EditingDismantledDevices,
		AutomaticRegistrationRequestsGridComponent,
		SetCarrier,
		SetSnReceive,
		SetReasonForStopping,
		SetActivityReasonComponent,
		SetDeviceSerialNumbersComponent,
		CompleteWorkUninstallNew,
		CheckDevicesAtWarehouseComponent,
		CheckDevicesAtWarehouseMobileComponent,
		ExpertiseFormComponent,

        HasClaimDirective,
        LineListDirective,
		NoWhitespaceAtStartDirective,
		NoEmptyStringsDirective,
		WorkEndedSuccessInstall,
		SetupDeviceSerial,
		FinishActivityWithDevicesServices,
		AddingDevice,
		ConfirmDeviceDifferent,
		DeliveryOfEquipment,
		ActivitySuccessResultFlowContainer,
		CompleteActivityResultPropertiesComponent,
		CompleteActivityDismantledSeriesComponent,
		DualChoiceComponent,
		DismantledSeriesImagesComponent,
		DismantledSeriesSerialPartNumbersComponent,
		DismantledSeriesGeneralProperties,
		CompleteActivityImageUploader,
		CompleteActivityDismantledSimcardComponent,
		CompleteActivityRequiredAttachmentComponent,
		CompleteActivitySummaryComponent,
		SetSnSetPackageSizeComponent,
		InstallFlowDeviceComponent,
		InstallFlowSimcardComponent,
		InstallFlowSummaryComponent,
		InstallFlowDeviceSerialComponent,

		//Pipes
		PhonePipe,
		DateFormatPipe,
		NumberFormatPipe,
		AuthPipe,

		GridModule,
		DropDownsModule,
		InputsModule,
		DateInputsModule,
		DialogsModule,
		UploadModule,
		NgbModule,
		AutosizeModule,
		TreeDropdownListModule,

		ChangeLogGrid,
		PinchZoomModule,
		NgxOrgChartModule,
		OrgTreeComponent,
		LoadFinancialReconciliationWarningComponent,
		WarehouseTransferSummaryComponent,
		CallContactsComponent,
    ],
	providers: [
		LookupService,
		RequestsService,
		WarehouseWorkplaceRequestsService,
		CarriersService,
		NewRequestsService,
		AgreementsService,
		UsersService,
		UserGroupsService,
		ActivitiesService,
		AddressService,
		For1CService,
		AttachmentsService,
		EthereumGateService,
		ActivityOperationsService,
		RequestDevicesService,
		RequestUninstallDevicesService,
		RequestServicesService,
		DevicesService,
		DeviceTypesService,
		DeviceConditionsService,
		DeviceModelsService,
		ServiceCentersService,
		StatePersistingService,
		ReportsService,
		ExportExcelFromGridService,
		DaDataService,
		TransportCompanyService,
		DeliveryService,
		BoxBerryService,
		StatePersistingService,
		RequestCustomerContactService,
		UserFiltersService,
		UploadInChunksService,
		CallsService,
		RequestNotificationTypeService,
		RequestConfigurationService,
		TmsConfigurationService,
		TmsGateDataService,
		MapYaDataService,
		CategoryServicesService,
		ServiceModesService,
		ServiceCategoriesService,
		ServiceTypesService,
		NomenclatureService,
		CategoriesService,
		RequestFilesService,
		RequestRegistrationFiltersService,
		RequestPublicationSettingsService,
		WarehousesService,
		WarehouseAreasService,
		WarehouseCellsService,
		ColorSchemeSettingsService,
		RegionsService,
		OSMService,
		RequestEmailsService,
		AttachmentTypesService,
		AttachmentTypeConfigurationsService,
		ContragentContactsService,
		ContragentDetailsService,
		ContragentProjectsService,
		EngubatorPlanningService,
		EngubatorRequestsService,
		EngubatorEngineersService,
		VeerouteCommonSettingsService,
		EngubatorSkillsService,
		EngubatorPlanningZonesService,
		EngubatorLookupService,
		EngubatorRegionsService,
		EngubatorRegionSegmentsService,
		InitTypesService,
		RequestStatusesService,
		RequestStatusMspAliasGroupsService,
		ActivityStatusesService,
		RequestAttributesService,
		RequestPrioritiesService,
		RequestTypeService,
		RequestStoppingReasonsService,
		RequestStoppingReasonAliasesService,
		RequestStoppingReasonKindsService,
		StoppingReasonTypeRulesService,
		MerchantsService,
		ActivityTypesService,
		ArticlesService,
		ActivityFinesService,
		ActivityFineTypesService,
		SpecialRightsGroupsService,
		NgxImageCompressService,
		ExternalService,
		RequestDeviceWarehouseRefillsService,
		RequestCategoryService,
	]
})
export class SharedModule { }
