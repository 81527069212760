import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { KeyValueObject } from '../models/core/KeyValueObject';
import { Observable } from 'rxjs';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { RequestTypeHierarchyModel } from '../models/request/request-type-hierarchy-model';
import { RequestTypeWithCategoriesModel } from '../models/request/request-type-with-categories-model';

@Injectable()
export class RequestTypeService extends DataService<KeyValueObject, KeyValueObject> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'request-types';
	}

	public getHierarchyData(): Observable<RequestTypeHierarchyModel[]> {
		const url = `${this.baseUrl()}/hierarchy-data`;
		return this.getQuery<RequestTypeHierarchyModel[]>(url);
	}

	public getRequestTypesWithCategories(): Observable<RequestTypeWithCategoriesModel[]> {
		const url = `${this.baseUrl()}/with-categories`;
		return this.getQuery<RequestTypeWithCategoriesModel[]>(url);
	}
}
