import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";
import { DialogService } from "@progress/kendo-angular-dialog";
import { KeyValueObject } from "../../../../../shared/models/core/KeyValueObject";
import { ContragentWithContactDto } from "../../../../../shared/models/contragent/contragent-with-contact-dto";
import { ContragentsService } from "../../../../contragents/contragents.service";
import { CreateContragentComponent } from "../../../../../shared/components/contragents/create-contragent/create-contragent.component";

@Component({
	selector: 'customer-object-page-contragent-info',
	templateUrl: './customer-object-page-contragent-info.component.html',
	styleUrls: ['./customer-object-page-contragent-info.component.scss']
})
export class CustomerObjectPageContragentInfoComponent implements OnChanges {

    @Input() selectedContragent: ContragentWithContactDto = null;

    @Output() saveContragent: EventEmitter<ContragentWithContactDto> = new EventEmitter<ContragentWithContactDto>();

	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

	contragentSearchPauseTime = 500;
	contragentsSearchDelay: NodeJS.Timer;
	contragentsSearchSubsription: Subscription;

	defaultContragent: KeyValueObject = new KeyValueObject(null, 'Не выбрано');
	contragents: KeyValueObject[] = [];
	selectedContragentId: number;
	selectedContragentName: string;
	
    constructor(
        public contragentsService: ContragentsService,
        public dialogService: DialogService
    ) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (this.selectedContragent != null) {
			this.setSelectedContragentName(this.selectedContragent);
		}
	}

    clientChange(value: string): void {
		let contragent = this.contragents.find(x => x.name == value);
		if (contragent != null) {
			this.selectedContragentId = contragent.id;
			this.contragentsService.getContragentWithContact(this.selectedContragentId).subscribe(resp => {
				this.setSelectedContragentName(resp);
				this.selectedContragent = resp;
				this.saveContragent.emit(this.selectedContragent);
			});
		}
		else {
			this.selectedContragent = null;
			this.saveContragent.emit(null);
		}
	}

	clientFilterChange(value: string): void {
		if (!value || value.trim() == '' || value.trim().length < 3) {
			this.contragents = this.selectedContragent != null
				? this.contragents.filter(x => x.id == this.selectedContragentId)
				: [];
		}
		else {
			this.searchContragents(value);
		}		
	}

	searchContragents(value: string) {
		if (this.contragentsSearchDelay) {
			clearTimeout(this.contragentsSearchDelay);
		}

		this.contragentsSearchDelay = setTimeout(() => {
			if (this.contragentsSearchSubsription) {
				this.contragentsSearchSubsription.unsubscribe();
			}

			this.contragentsSearchSubsription = this.contragentsService.searchByName(value)
            .subscribe((response) => {
                this.contragents = response.data;
			});
		}, this.contragentSearchPauseTime);
	}

	openCreateContragentDialog() {
		const dialogRef = this.dialogService.open({ content: CreateContragentComponent, width: '60%', height: '640px' });
		const createContragentForm = <CreateContragentComponent>dialogRef.content.instance;
		
		createContragentForm.isEditing = this.selectedContragent != null;
		createContragentForm.contragentEntity = this.selectedContragent;
		createContragentForm.onCancelEvent.subscribe(() => dialogRef.close());
		createContragentForm.onContinueEvent.subscribe((contragent) => {
			this.selectedContragent = contragent;
            this.saveContragent.emit(this.selectedContragent);
			this.selectedContragentId = contragent.id;

			const contragentNameData = [contragent.contactFullName, contragent.contactPhone, contragent.name, contragent.inn];
    		const contragentDisplayName = contragentNameData.filter(s => s !== null && s.trim() != '').join(', ');
			this.setSelectedContragentName(contragent);
			this.contragents = [new KeyValueObject(contragent.id, contragentDisplayName)];

			this.clientFilterChange(null);
			dialogRef.close();
		});
	}

	setSelectedContragentName(contragent: ContragentWithContactDto) {
		this.selectedContragentName = contragent.isIndividual
			? contragent.contactFullName
				? contragent.contactFullName
				: contragent.contactPhone
			: contragent.name
				? contragent.name
				: contragent.inn;
	}

	get autoCompleteClass() {
		return this.selectedContragentName
			? ''
			: 'autocomplete-required-invalid'
	}
}
