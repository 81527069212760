import { Observable, forkJoin, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { CompleteActivityDismantledSeriesData } from "./complete-activity-dismantled-series.data";
import { Component } from "@angular/core";
import { CompleteActivityDismantledSeriesOrder } from "./complete-activity-dismantled-series-order";
import { RequestUninstallDevicesService } from "../../../../services/request-uninstall-devices.service";
import { NewRequestDevice } from "../../../../models/request/new-request/new-request-device";
import { switchMap, tap } from "rxjs/operators";
import { AttachmentType, DeviceStatus } from "../../../../enums";
import { DeviceTypeEnum } from "../../../../enums/device-type.enum";
import { UploadAttachmentsModel } from "../../../../models/attachment";
import { AttachmentsService } from "../../../../services/attachments.service";

@Component({
    selector: 'complete-activity-dismantled-series',
    templateUrl: './complete-activity-dismantled-series.component.html',
    styleUrls: ['./complete-activity-dismantled-series.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivityDismantledSeriesComponent 
    extends BaseFlowComponent<CompleteActivityDismantledSeriesData>
{
    StepOrder = CompleteActivityDismantledSeriesOrder;

    stepOrdering = [
        CompleteActivityDismantledSeriesOrder.dismantledImages,
        CompleteActivityDismantledSeriesOrder.serialAndPartNumbers,
        CompleteActivityDismantledSeriesOrder.generalDismantledProperties
    ];

    currentStepIndex: number = 0;

    constructor(
        public requestUninstallDevicesService: RequestUninstallDevicesService,
        public attachmentsService: AttachmentsService) 
    {
        super();
    }

    initData() {
        this.currentStepIndex = this.isForwardMovement ? 0 : this.stepOrdering.length - 1;
    }

    saveChanges(): Observable<any> {
        let requestDevice = new NewRequestDevice;
        requestDevice.requestId = this.data.requestId;
        requestDevice.requestDeviceId = this.data.requestUninstallDeviceId;
        requestDevice.serialNumber = this.data.serialAndPartNumbersData.serialNumber;
        requestDevice.partNumber = this.data.serialAndPartNumbersData.partNumber;
        requestDevice.nomenclature = this.data.serialAndPartNumbersData.nomenclature;
        requestDevice.deviceStatusId = DeviceStatus.toUninstall;
        requestDevice.deviceTypeId = DeviceTypeEnum.terminal;

        requestDevice.deviceConditionId = this.data.generalPropertiesData.deviceConditionId;
        requestDevice.isDeviceBroken = this.data.generalPropertiesData.isDeviceBroken;
        requestDevice.brokenDescription = this.data.generalPropertiesData.brokenDescription;
        requestDevice.completeKit = this.data.generalPropertiesData.completeKit;
        requestDevice.hasCryptoKey = this.data.generalPropertiesData.hasCryptoKey;
        requestDevice.cryptoKey = this.data.generalPropertiesData.cryptoKey;

        return this.data.requestUninstallDeviceId == null
            ? this.requestUninstallDevicesService.create(requestDevice, null, true, true)
                .pipe(
                    tap(resp => { 
                        this.data.requestUninstallDeviceId = resp.data; 
                    }),
                    switchMap(() => {                            
                        return this.uploadAttachmentsRequest();
                    }))
            : this.requestUninstallDevicesService.update(requestDevice, null, true, true)
                .pipe(
                    switchMap(() => {    
                        return this.uploadAttachmentsRequest();
                    })
                );
    }

    goToNextStep(): void {  
        if (this.currentStepIndex == this.stepOrdering.length - 1) {
            this.goToNext();
        }
        else {
            this.currentStepIndex++;
        }
    }

    goToPreviousStep(): void {
        this.currentStepIndex--;
        if (this.currentStepIndex < 0) {
            this.goToPrevious();
        }
    }

    get currentStepNumber(): number {
        return this.stepOrdering[this.currentStepIndex];
    }

    private uploadAttachmentsRequest(): Observable<any> {
        this.data.imagesData.requestDeviceImages.forEach(x => {
            x.attachmentTypeId = AttachmentType.requestDeviceImage;
            x.isTemporary = true;
        });
        this.data.imagesData.requestDeviceSerialNumberImages.forEach(x => {
            x.attachmentTypeId = AttachmentType.uninstalledDevicesFotos;
            x.isTemporary = true;
        });

        let attachments = this.data.imagesData.requestDeviceImages.concat(this.data.imagesData.requestDeviceSerialNumberImages);
        let attachmentsToUpload = attachments.filter(x => !x.isUploadedOnServer && !x.isRemoved);
        let attachmentsToRemove = attachments.filter(x => x.isUploadedOnServer && x.isRemoved);

        const uploadModel = new UploadAttachmentsModel({ 
            requestId: this.data.requestId, 
            activityId: this.data.activityId,
            requestUninstallDeviceId: this.data.requestUninstallDeviceId,
            attachments: attachmentsToUpload
        });

        let attachmentObservables$: Observable<any>[] = [];

        if (attachmentsToRemove.length > 0) {
            attachmentObservables$ = attachmentsToRemove.map((x) => this.attachmentsService.remove(x.uid, null, null, true));
        }

        if (attachmentsToUpload.length > 0) {
            attachmentObservables$.push(
                this.attachmentsService.uploadWithResumableIfMobile(uploadModel)
                .pipe(tap(() => {
                    attachmentsToUpload.forEach(x => x.isUploadedOnServer = true);
                })));
        }

        return attachmentObservables$.length > 0 
            ? forkJoin(attachmentObservables$) 
            : of({}); 
    }
}