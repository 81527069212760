import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CustomerObjectContactDto, CustomerObjectWithContactsDto } from "../../../../../models/customerObject/customer-object-with-contacts.dto";
import { GridComponent } from "@progress/kendo-angular-grid";
import { CustomerObjectsService } from "../../../../../../categories/customer-objects/customer-objects.service";

@Component({
	selector: 'request-page-customer-object-contacts',
	templateUrl: './request-page-customer-object-contacts.component.html',
	styleUrls: ['./request-page-customer-object-contacts.component.scss',
		'../../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class RequestPageCustomerObjectContactsComponent implements OnInit {

	@ViewChild("contactsGrid") contactsGrid: GridComponent;
	
	@Input() contragentId: number;
	@Input() customerObjectId: number;

	customerObject: CustomerObjectWithContactsDto = { contacts: [] } as CustomerObjectWithContactsDto;
	loading: boolean = false;

	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

	constructor(
        private customerObjectsService: CustomerObjectsService
    ) { }

    ngOnInit(): void {
		this.initData();
    }

	initData() {
		if (this.customerObjectId) {
			this.loading = true;
			this.customerObjectsService.getCustomerObjectWithContacts(this.customerObjectId).subscribe(resp => {
				this.customerObject = resp;
				this.loading = false;
			});
		}
	}

	saveContactHandler({ dataItem }: any) {
		let updatedCustomerObject = {...this.customerObject};
		if (dataItem.contactId && dataItem.contactId != 0) {
			let updatedContactId = updatedCustomerObject.contacts.findIndex(x => x.contactId == dataItem.contactId);
			updatedCustomerObject.contacts[updatedContactId] = dataItem;
		}
		else {
			updatedCustomerObject.contacts.push(dataItem);
		}
		this.refreshContactsHandler(updatedCustomerObject);
	}

	removeContactHandler({ dataItem }: any) {
		let updatedCustomerObject = {...this.customerObject};
		updatedCustomerObject.contacts = updatedCustomerObject.contacts.filter(x => x.contactId != dataItem.contactId);
		this.refreshContactsHandler(updatedCustomerObject);
	}

	refreshContactsHandler(updatedCustomerObject: CustomerObjectWithContactsDto) {
		this.loading = true;
		this.customerObjectsService.updateCustomerObjectWithContacts(updatedCustomerObject).subscribe(resp => {
			this.customerObjectsService.getCustomerObjectWithContacts(this.customerObjectId).subscribe(resp => {
				this.customerObject = resp;
				this.loading = false;
			});
		})
	}

    createNewContact(): CustomerObjectContactDto {
        var contact = new CustomerObjectContactDto();
        return contact;
    }

    addNewContact() {
		this.contactsGrid.addRow(this.createNewContact());
	}
    
    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

	get customerObjectExist() {
		return this.customerObjectId && this.customerObjectId != 0;
	}
}
