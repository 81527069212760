import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CustomerObjectWithContactsDto } from "../../../../shared/models/customerObject/customer-object-with-contacts.dto";
import { KeyValueObject } from "../../../../shared/models/core/KeyValueObject";
import { CustomerObjectsService } from "../../../../categories/customer-objects/customer-objects.service";
import { DialogService } from "@progress/kendo-angular-dialog";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { CreateCustomerObjectComponent } from "../../../../shared/components/customer-object/create-customer-object/create-customer-object.component";

@Component({
	selector: 'new-request-customer-object-info',
	templateUrl: './new-request-customer-object-info.component.html',
	styleUrls: ['./new-request-customer-object-info.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class NewRequestCustomerObjectInfoComponent {

	clientDefaultItem: KeyValueObject = new KeyValueObject(0, 'Не выбрано');
	dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    @Input() selectedCustomerObject: CustomerObjectWithContactsDto = new CustomerObjectWithContactsDto();
    @Input() customerObjects: KeyValueObject[] = [];
    @Input() contragentId: number;

    @Output() saveCustomerObject: EventEmitter<CustomerObjectWithContactsDto> = new EventEmitter<CustomerObjectWithContactsDto>();

    constructor(
        public customerObjectsService: CustomerObjectsService,
        public dialogService: DialogService
    ) { }

    customerObjectChange(id: number) {
        if (id == null || id == 0) {
            this.selectedCustomerObject = new CustomerObjectWithContactsDto();
        }
        else {
            this.customerObjectsService.getCustomerObjectWithContacts(id).subscribe((resp) => {
                this.selectedCustomerObject = resp;
                this.saveCustomerObject.emit(resp);
            });
        }
    }

    customerObjectFilterChange(value: string) {
    }

    openCustomerObjectDialog() {
        const dialogRef = this.dialogService.open({ content: CreateCustomerObjectComponent, width: '60%', height: '540px' });
		const createContragentForm = <CreateCustomerObjectComponent>dialogRef.content.instance;
		
		createContragentForm.customerObjects = this.customerObjects;
		createContragentForm.selectedCustomerObject = this.selectedCustomerObject;
        createContragentForm.contragentId = this.contragentId;
        createContragentForm.isEditing = this.isEditing;
		createContragentForm.onCancelEvent.subscribe(() => dialogRef.close());
		createContragentForm.onContinueEvent.subscribe((customerObject) => {
			this.selectedCustomerObject = customerObject;
            this.saveCustomerObject.emit(this.selectedCustomerObject);
			dialogRef.close();
		});
    }

    get selectedCustomerObjectAddress() {
        if (!this.selectedCustomerObject.address) {
            return '-';
        }

        return this.selectedCustomerObject.addressDescription
            ? `${this.selectedCustomerObject.address}, ${this.selectedCustomerObject.addressDescription}`
            : this.selectedCustomerObject.address;
    }
    
    get isEditing(): boolean {
        return this.selectedCustomerObject.customerObjectId != null 
            && this.selectedCustomerObject.customerObjectId != 0;
    }

    get disabled(): boolean {
        return this.contragentId == null || this.contragentId == 0;
    }
}