import { Component, Input, OnInit } from "@angular/core";
import { PerfectScrollbarComponent } from "ngx-perfect-scrollbar";
import { Router } from "@angular/router";
import { CustomerObjectRelatedRequestDto } from "../../../../../shared/models/customerObject/customer-object-related-request-dto";
import { CustomerObjectsService } from "../../../customer-objects.service";

@Component({
	selector: 'customer-object-page-related-requests',
	templateUrl: './customer-object-page-related-requests.component.html',
	styleUrls: ['./customer-object-page-related-requests.component.scss',
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class CustomerObjectPageRelatedRequestsComponent implements OnInit {
	
	@Input() customerObjectId: number;

	relatedRequests: CustomerObjectRelatedRequestDto[] = [];

	loading: boolean = false;

	constructor (
		private customerObjectsService: CustomerObjectsService
	) {}

	ngOnInit(): void {
		if (this.customerObjectId) {
			this.loading = true;
			this.customerObjectsService.getCustomerObjectRelatedRequests(this.customerObjectId, null).subscribe(resp => {
				this.relatedRequests = resp;
				this.loading = false;
			});	
		}
	}
	
    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

	cellClick({ dataItem }: any) {
		let win = window.open(`/requests/${dataItem.requestId}`, '_blank');
	}
}
