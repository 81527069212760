import { Observable, forkJoin, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { CompleteActivityDismantledSimcardData } from "./complete-activity-dismantled-simcard-data";
import { Component } from "@angular/core";
import { UploadAttachmentsModel } from "../../../../models/attachment";
import { AttachmentType, DeviceStatus } from "../../../../enums";
import { AttachmentsService } from "../../../../services/attachments.service";
import { RequestUninstallDevicesService } from "../../../../services/request-uninstall-devices.service";
import { DeviceTypeEnum } from "../../../../enums/device-type.enum";
import { switchMap, tap } from "rxjs/operators";
import { UploadFileData } from "../../../../models/attachment/upload-file-data";
import { DeviceConditionEnum } from "../../../../enums/device-conditions.enum";

@Component({
	selector: 'complete-activity-dismantled-simcard',
	templateUrl: './complete-activity-dismantled-simcard.component.html',
	styleUrls: ['./complete-activity-dismantled-simcard.component.scss',
		'../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivityDismantledSimcardComponent
	extends BaseFlowComponent<CompleteActivityDismantledSimcardData>
{
	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};
	constructor(
		public requestUninstallDevicesService: RequestUninstallDevicesService,
		public attachmentsService: AttachmentsService
	) {
		super();
	}

	initData() { }

	saveChanges(): Observable<any> {
		if (!this.needToFillFields && this.data.requestUninstallDevice.requestDeviceId != null) {
			this.data.requestUninstallDevice.requestDeviceId = null;
			return this.requestUninstallDevicesService.remove(this.data.requestUninstallDevice.requestDeviceId.toString(), null, null, true);
		}
		else if (this.fieldsCompleted) {
			this.data.requestUninstallDevice.nomenclature = this.data.mobileNomenclatures.find(x =>
				x.id == this.data.nomenclatureId).name;

			let requestDevice = this.data.requestUninstallDevice;
			requestDevice.requestId = this.data.requestId;
			requestDevice.serialNumber = this.data.requestUninstallDevice.iccid;
			requestDevice.deviceStatusId = DeviceStatus.toUninstall;
			requestDevice.deviceTypeId = DeviceTypeEnum.simCard;
			requestDevice.deviceSupplierId = this.data.requestUninstallDevice.deviceModelId;
			requestDevice.deviceConditionId = DeviceConditionEnum.used;

			return this.data.requestUninstallDevice.requestDeviceId == null
				? this.requestUninstallDevicesService.create(requestDevice, null, true, true)
					.pipe(
						tap(resp => {
							this.data.requestUninstallDevice.requestDeviceId = resp.data;
						}),
						switchMap(() => {
							return this.uploadAttachmentsRequest();
						}))
				: this.requestUninstallDevicesService.update(requestDevice, null, true, true)
					.pipe(
						switchMap(() => {
							return this.uploadAttachmentsRequest();
						})
					);
		}

		return of({});
	}

	uploadAttachmentsRequest(): Observable<any> {
		this.data.attachments.forEach(x => {
			x.attachmentTypeId = AttachmentType.uninstalledSimFoto;
			x.isTemporary = true;
		});

		let attachmentsToUpload = this.data.attachments.filter(x => !x.isUploadedOnServer && !x.isRemoved);
		let attachmentsToRemove = this.data.attachments.filter(x => x.isUploadedOnServer && x.isRemoved);

		const uploadModel = new UploadAttachmentsModel({
			requestId: this.data.requestId,
			activityId: this.data.activityId,
			requestUninstallDeviceId: this.data.requestUninstallDevice.requestDeviceId,
			attachments: attachmentsToUpload
		});

		let attachmentObservables$: Observable<any>[] = [];

		if (attachmentsToRemove.length > 0) {
			attachmentObservables$ = attachmentsToRemove.map((x) => this.attachmentsService.remove(x.uid, null, null, true));
		}

		if (attachmentsToUpload.length > 0) {
			attachmentObservables$.push(
				this.attachmentsService.uploadWithResumableIfMobile(uploadModel)
					.pipe(tap(() => {
						attachmentsToUpload.forEach(x => x.isUploadedOnServer = true);
					})));
		}

		return attachmentObservables$.length > 0
			? forkJoin(attachmentObservables$)
			: of({});
	}

	removeAttachment(uploadFileData: UploadFileData) {
		let attachment = this.data.attachments.find(x => x.uid == uploadFileData.uid);
		if (attachment.isUploadedOnServer) {
			attachment.isRemoved = true;
		}
		else {
			this.data.attachments = this.data.attachments.filter(x => x.uid != uploadFileData.uid);
		}
	}

	get hasSavedAttachments() {
		return this.data.attachments.filter(x => x.isRemoved != true).length > 0;
	}

	get needToFillFields(): boolean {
		return this.hasSavedAttachments ||
			this.data.nomenclatureId != null ||
			(this.data.requestUninstallDevice.iccid != null &&
				this.data.requestUninstallDevice.iccid.trim() != '') ||
			(this.data.requestUninstallDevice.subscriberNumber != null &&
				this.data.requestUninstallDevice.subscriberNumber.trim() != '');
	}

	get fieldsCompleted(): boolean {
		return this.hasSavedAttachments &&
			this.data.nomenclatureId != null &&
			this.simCardICCIDValid &&
			this.subscriberNumberValid;
	}

	get simCardICCIDValid(): boolean {
		return this.data.requestUninstallDevice.iccid != null && this.data.requestUninstallDevice.iccid.trim() != '';
	}

	get subscriberNumberValid(): boolean {
		return !this.data.fillSubsriberNumber ||
			(this.data.requestUninstallDevice.subscriberNumber != null &&
				this.data.requestUninstallDevice.subscriberNumber.trim() != '' &&
				this.data.requestUninstallDevice.subscriberNumber.trim().length == 10);
	}
}
