import { EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";

export abstract class BaseFlowComponent<T> implements OnInit {

	private _data: T;
	public get data(): T {
		return this._data;
	}
	@Input()
	public set data(value: T) {
		this._data = value;
	}

    private _isForwardMovement: boolean = true;
    public get isForwardMovement(): boolean {
        return this._isForwardMovement;
    }
    @Input() 
    public set isForwardMovement(value: boolean) {
        this._isForwardMovement = value;
    }

    @Input() goToPreviousDisabled: boolean = false;

    @Output() goToNextEvent = new EventEmitter(); 
    @Output() goToPreviousEvent = new EventEmitter();
    @Output() onContinueEvent: EventEmitter<any> = new EventEmitter<any>();

    dataSaving: boolean;

    abstract initData(): any;
    abstract saveChanges(): Observable<any>;

    ngOnInit(): void {
        this.initData();
    }

    goToPrevious() {
        this.goToPreviousEvent.emit();
    }

    goToNext() {
        this.dataSaving = true;
        this.saveChanges().subscribe(() => {
            this.dataSaving = false;
            this.goToNextEvent.emit();
        });
    }
}