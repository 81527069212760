import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { GridComponent } from "@progress/kendo-angular-grid";
import { CustomerObjectContactDto, CustomerObjectWithContactsDto } from "../../../../../shared/models/customerObject/customer-object-with-contacts.dto";
import { CustomerObjectsService } from "../../../customer-objects.service";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { KeyValueObject } from "../../../../../shared/models/core/KeyValueObject";
import { DialogService } from "@progress/kendo-angular-dialog";
import { CreateCustomerObjectComponent } from "../../../../../shared/components/customer-object/create-customer-object/create-customer-object.component";

@Component({
	selector: 'customer-object-page-info-with-contacts',
	templateUrl: './customer-object-page-info-with-contacts.component.html',
	styleUrls: ['./customer-object-page-info-with-contacts.component.scss',
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class CustomerObjectPageInfoWithContactsComponent {

	dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    @Input() customerObject: CustomerObjectWithContactsDto = null;
    @Input() selectedContragentId: number;
    @Input() disabled: boolean = false;
    @Input() commitChanges: boolean = true;

    @Output() saveCustomerObject: EventEmitter<CustomerObjectWithContactsDto> = new EventEmitter<CustomerObjectWithContactsDto>();

    constructor(
        public customerObjectsService: CustomerObjectsService,
        public dialogService: DialogService
    ) { }

    openCustomerObjectDialog() {
        const dialogRef = this.dialogService.open({ content: CreateCustomerObjectComponent, width: '60%', height: '540px' });
		const customerObjectForm = <CreateCustomerObjectComponent>dialogRef.content.instance;
		
		customerObjectForm.selectedCustomerObject = this.customerObject;
        customerObjectForm.contragentId = this.selectedContragentId;
        customerObjectForm.isEditing = this.isEditing;
        customerObjectForm.commitChanges = this.commitChanges;
        customerObjectForm.showEmail = true;

		customerObjectForm.onCancelEvent.subscribe(() => dialogRef.close());
		customerObjectForm.onContinueEvent.subscribe((customerObject) => {
			this.customerObject = customerObject;
            this.saveCustomerObject.emit(this.customerObject);
			dialogRef.close();
		});
    }

    get isEditing(): boolean {
        return this.customerObject != null && this.customerObject.customerObjectId != null;
    }

    get selectedCustomerObjectAddress() {
        if (!this.customerObject.address) {
            return '-';
        }

        return this.customerObject.addressDescription
            ? `${this.customerObject.address}, ${this.customerObject.addressDescription}`
            : this.customerObject.address;
    }
}