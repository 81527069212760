import { HttpClient } from "@angular/common/http";
import { DeviceModelDto } from "../models/device/device-model-dto";
import { ServiceItem } from "../models/services/service-item";
import { NotificationService } from "../../core/services/notification.service";
import { DataService } from "../../core/services/data.service";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class DeviceModelsService extends DataService<DeviceModelDto, DeviceModelDto> {

	constructor(
		protected http: HttpClient,
		protected notificationService: NotificationService) {
    super(http, notificationService);

    this.controllerName = 'device-models';
  }

    public getAll(): Observable<DeviceModelDto[]> {
        return this.getQuery<DeviceModelDto[]>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/all`);
    }
}