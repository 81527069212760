import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { ContragentsService } from '../contragents.service';
import { Contragent } from '../../../shared/models/contragent/Contragent';
import {CategoryType} from "../../../shared/enums/category-type.enum";
import {LookupService} from "../../../shared/services/lookup.service";
import {KeyValueObject} from "../../../shared/models/core/KeyValueObject";
import {EntityViewModel} from "../../../shared/models/core/EntityViewModel";
import { ServiceCentersService } from '../../../shared/services/service-centers.service';
import { UpdateServiceCenterCustomCommand } from '../../../shared/models/request/update-service-center-custom/update-service-center-custom-command';
import { ContragentWithContactDto } from '../../../shared/models/contragent/contragent-with-contact-dto';
import { DaDataAddressComponent } from '../../../shared/components/dadata-address/dadata-address.component';
import { AddressSuggestion } from '../../../shared/models/dadata-address/address-suggestion';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'categories-contragent',
  templateUrl: './contragent.page.html',
	styleUrls: [
    './contragent.page.scss',
    '../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
  ]
})
export class ContragentPage extends ComponentPageBase<Contragent> implements OnInit {

	@ViewChild("dadataAddressControl") dadataAddressControl: DaDataAddressComponent;

  	headerName = "Основное";
	activeIdString: any = "main-tab";
	categoryTypeId = <number>CategoryType.contragents;

	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

	contragentTypes: KeyValueObject[] = [];
	loaded: boolean = false;

	contragent: ContragentWithContactDto = null;
	updatedContragent: ContragentWithContactDto;

	constructor(
		private lookupService: LookupService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: ContragentsService,
		protected serviceCentersService: ServiceCentersService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/contragents';
	}

	ngOnInit(): void {
		this.entityId = this.route.snapshot.paramMap.get('contragentId');
		
		this.lookupService.getData("contragentTypes", null).subscribe(resp =>{
			this.contragentTypes = resp;
		});
        
		if (this.entityId != null) {

            this.dataService.getContragentWithContact(+this.entityId).subscribe(vm => {
				this.entityName = `${vm.name}`;
				this.contragent = vm;
				this.loaded = true;
				this.canEditForm = false;	
				setTimeout(() => {
					if (this.dadataAddressControl) {
						this.dadataAddressControl.initValue(this.contragent.address);
					}
				}, 1000);
        	});
		} 
		else {
            this.contragent = new ContragentWithContactDto();
            this.entityViewModel = new EntityViewModel<Contragent>();
            this.entityViewModel.entity = new Contragent();
            this.entityViewModel.entity.createdDate = new Date();
            this.canEditForm = true;
            this.isNew = true;
			this.loaded = true;
			this.canEditForm = true;
        }
	}

	saveChanges() {
		this.dataSaving = true;
		if (this.contragent.id == null || this.contragent.id == 0) {
			this.dataService.createContragentWithContact(this.contragent).subscribe((resp) => {
				this.dataSaving = false;
				window.location.replace(`/categories/contragent/${resp.data}`);        
			});
		}
		else {
			this.dataService.updateContragentWithContact(this.contragent).subscribe(() => {
				this.dataService.getContragentWithContact(this.contragent.id).subscribe(resp => {
					this.contragent = resp;
					this.canEditForm = false;
					this.dataSaving = false;
				});
			});
		}
	}

	onAddressChange(value: AddressSuggestion) {
		this.contragent.address = value.address;
	}

	editEnable() {
		this.updatedContragent = { ...this.contragent };
		this.canEditForm = true;
	}

	editDisable() {
		this.contragent = { ...this.updatedContragent };
		this.canEditForm = false;
	}

	get saveContragentAvailable() {
		return this.contragent && this.contragent.contactPhone;
	}
}