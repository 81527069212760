import { BaseObject } from '../core/BaseObject';
import { CustomerObjectWorkingHours } from './customer-object-working-hours';

export class CustomerObject extends BaseObject {
	customerObjectId: number;
	name: string;
	address: string;
	distanceFromCityKm: number;
	parentCustomerObjectId: number;
	parentCustomerObjectName: string;
	contragentId: number;
	contragentName: string;
	contragentPhone: string;
	contragentINN: string;
	acquirerName: string;
	latitude: number;
	longitude: number;
	regionId: number;
	regionName: string;
	startWork: string;
	endWork: string;
	merchantId: number;
	serviceCenterId: number;
	workingHours: CustomerObjectWorkingHours[] = [];
	addressId?: number;
	parseAddressDadata: boolean = true;
}
