import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RequestTypeService } from "../../../../shared/services/request-type.service";
import { RequestCategoryService } from "../../../../shared/services/request-categoty.service";
import { forkJoin } from "rxjs";
import { RequestTypeWithCategoriesModel } from "../../../../shared/models/request/request-type-with-categories-model";
import { RequestCategotyWithTypesModel } from "../../../../shared/models/request/request-categoty-with-types-model";

@Component({
	selector: 'new-request-general-info',
	templateUrl: './new-request-general-info.component.html',
	styleUrls: ['./new-request-general-info.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class NewRequestGeneralInfoComponent implements OnInit {

    @Input() requestTypeId: number;
    @Input() requestCategoryId: number;
    @Input() requestDescription: string;

    @Output() changeRequestTypeId: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeRequestCategoryId: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeRequestDescription: EventEmitter<string> = new EventEmitter<string>();

    descriptionInputRows: number = 1;
    requestTypeDefaultItem = { id: null, name: "Не выбрано" } as RequestTypeWithCategoriesModel;
    requestCategoryDefaultItem = { id: null, name: "Не выбрано" } as RequestCategotyWithTypesModel;

    dependentRequestTypes: RequestTypeWithCategoriesModel[] = [];
    dependentRequestCategories: RequestCategotyWithTypesModel[] = [];
    requestTypes: RequestTypeWithCategoriesModel[] = [];
    requestCategories: RequestCategotyWithTypesModel[] = [];

    constructor(
        public requestTypeService: RequestTypeService,
        public requestCategoryService: RequestCategoryService
    ) { }

    ngOnInit() {
        let $requestTypes = this.requestTypeService.getRequestTypesWithCategories();
        let $requestCategories = this.requestCategoryService.getRequestCategoriesWithTypes();

        forkJoin($requestTypes, $requestCategories)
            .subscribe(([requestTypes, requestCategories]) => {
                this.requestTypes = requestTypes;
                this.requestCategories = requestCategories;
                this.dependentRequestTypes = requestTypes;
                this.dependentRequestCategories = requestCategories;
            });
    }

    requestTypeChange(requestTypeId: number) {
        if (requestTypeId != null && requestTypeId != 0) {
            this.dependentRequestCategories = this.requestCategories.filter(x => 
                x.requestTypeIds.some(rt => rt == requestTypeId));
        }
        else {
            this.dependentRequestCategories = this.requestCategories;
        }

        this.changeRequestTypeId.emit(requestTypeId);
    }

    requestCategoryChange(requestCategoryId: number) {
        if (requestCategoryId != null && requestCategoryId != 0) {
            this.dependentRequestTypes = this.requestTypes.filter(x => 
                x.requestCategoryIds.some(rc => rc == requestCategoryId));
        }
        else {
            this.dependentRequestTypes = this.requestTypes;
        }

        this.changeRequestCategoryId.emit(requestCategoryId);
    }

    requestDescriptionChange() {
        if (this.requestDescription != null) {
            this.descriptionInputRows = this.requestDescription.split('\n').length;
        }

        this.changeRequestDescription.emit(this.requestDescription);
    }
}