import { Observable, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { CompleteActivitySummaryData } from "./complete-activity-summary-data";
import { Component } from "@angular/core";
import { ActivitiesService } from "../../../../services/activities.service";
import { ActivityFinishSuccesfully } from "../../../../models/activity/activity-finish-succesfully";
import { NotificationService } from "../../../../../core/services/notification.service";
import { NotificationType } from "../../../../../core/services/notification-type";

@Component({
    selector: 'complete-activity-summary',
    templateUrl: './complete-activity-summary.component.html',
    styleUrls: ['./complete-activity-summary.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivitySummaryComponent extends BaseFlowComponent<CompleteActivitySummaryData> {

    constructor(
        public activitiesService: ActivitiesService,
        public notificationService: NotificationService
    ) {
        super();
    }

    initData() {
    }

    saveChanges(): Observable<any> {
        return of({});
    }

    finishSuccesfully() {
        this.dataSaving = true;
        let model = new ActivityFinishSuccesfully;
        model.requestId = this.data.requestId;
        model.activityId = this.data.activityId;
        model.newFinishDateFact = this.data.completedDateTime;
        model.activityResultId = this.data.activityResultId;

        this.activitiesService.finishSuccesfully(model).subscribe((result) => {
            if (!result.data.success) {
				this.notificationService.error({
					title: 'Ошибка при успешном завершении активности',
					message: result.data.errorMessage,
					notificationType: NotificationType.Toast
				});
                this.dataSaving = false;
            }
            else {
                this.goToNext();
            }
        });
    }
}