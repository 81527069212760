import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { DirtyStateGuard } from '../core/guards/dirty-state.guard';
import { AutomaticRegistrationRequestPage } from './automatic-registration-requests/automatic-registration-request/automatic-registration-request.page';
import { AutomaticRegistrationRequestsListPage } from './automatic-registration-requests/list/list.page';

import { RolesListPage } from './roles/list/list.page';
import { RolePage } from './roles/role/role.page';
import { UsersListPage } from './users/list/list.page';
import { UserPage } from './users/user/user.page';
import { VeerouteSettingsPage } from './veeroute/veeroute-settings.page';
import { AutomaticPublicationRequestsListPage } from './automatic-publication-requests/list/list.page';
import { AutomaticPublicationRequestPage } from './automatic-publication-requests/automatic-publication-request/automatic-publication-request.page';

export const adminRoutes: Routes = [

	{ path: 'users', component: UsersListPage, canActivate: [AuthGuard] },
	{ path: 'user/new', component: UserPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },
	{ path: 'user/:userId', component: UserPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'roles', component: RolesListPage, canActivate: [AuthGuard] },
	{ path: 'role/new', component: RolePage, canDeactivate: [DirtyStateGuard] },
	{ path: 'role/:roleId', component: RolePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	/* BWT
	{ path: 'automatic-registration-requests', component: AutomaticRegistrationRequestsListPage, canActivate: [AuthGuard] },
	{ path: 'automatic-registration-request/new', component: AutomaticRegistrationRequestPage, canDeactivate: [DirtyStateGuard] },
	{ path: 'automatic-registration-request/:requestRegistrationFilterId', component: AutomaticRegistrationRequestPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'automatic-publication-requests', component: AutomaticPublicationRequestsListPage, canActivate: [AuthGuard] },
	{ path: 'automatic-publication-request/new', component: AutomaticPublicationRequestPage, canDeactivate: [DirtyStateGuard] },
	{ path: 'automatic-publication-request/:requestPublicationSettingId', component: AutomaticPublicationRequestPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'veeroute-settings', component: VeerouteSettingsPage, canActivate: [AuthGuard] },
	*/
];
