export enum ActivityType {
	inspection = 1,
	techSupport = 2,
	warehouse = 3,
	engineering = 4,
	laboratory = 5,


	engineer = 1001,
}
