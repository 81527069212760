import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { RequestsService } from "../../../services/requests.service";
import { NewRequestService } from "../../../models/request/new-request/new-request-service";
import { GridComponent } from "@progress/kendo-angular-grid";
import { RequestServicesService } from "../../../services/request-services.service";
import { CategoryServicesService } from "../../../services/category-services.service";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";

@Component({
	selector: 'request-page-services',
	templateUrl: './request-page-services.component.html',
    styleUrls: ['./request-page-services.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	],
	encapsulation: ViewEncapsulation.None,
})
export class RequestPageServicesComponent implements OnInit {

	@ViewChild("additionalRequestServicesGrid") additionalRequestServicesGrid: GridComponent;

    @Input() requestId: number;

    services: KeyValueObject[] = [];
    requestServices: NewRequestService[] = [];

    loadingBasic: boolean = false;
    loadingAdditional: boolean = false;

    dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    constructor(
        private requestsService: RequestsService,
        private requestServicesService: RequestServicesService,
        private categoryServicesService: CategoryServicesService
    ) { }

    ngOnInit(): void {
        this.loadRequestServices(null);

        this.categoryServicesService.list().subscribe(resp => {
            this.services = resp.data.map(x => new KeyValueObject(x.id, x.name));
        });
    }

    loadRequestServices(basic: boolean = null) {
        this.turnOnLoading(basic);
        this.requestsService.getRequestServices(this.requestId).subscribe(resp => {
            this.requestServices = resp;
            this.turnOffLoading(basic);
        });
    }

    createNewBasicRequestService(): NewRequestService {
        var requestService = new NewRequestService();
        requestService.requestId = this.requestId;
        requestService.isDefault = true;
        return requestService;    
    }

    createNewAdditionalRequestService(): NewRequestService {
        var requestService = new NewRequestService();
        requestService.requestId = this.requestId;
        requestService.isDefault = false;
        return requestService;
    }

    saveAdditionalRequestServiceHandler({ dataItem }: any) {
        this.saveRequestServiceHandler(dataItem, false);
    }

    saveBasicRequestServiceHandler({ dataItem }: any) {
        this.saveRequestServiceHandler(dataItem, true);
    }

    saveRequestServiceHandler(dataItem, basic: boolean) {
        this.turnOnLoading(basic);    
        if (dataItem.requestServiceId && dataItem.requestServiceId != 0) {
            this.requestServicesService.update(dataItem, null).subscribe(() => {
                this.loadRequestServices(basic);
            });
        }
        else {
            this.requestServicesService.create(dataItem).subscribe(() => {
                this.loadRequestServices(basic);
            });
        }
    }

    removeRequestServiceHandler({ dataItem }: any) {
        this.turnOnLoading(false);
        this.requestServicesService.remove(dataItem.requestServiceId, null, null, true).subscribe(() => {
            this.loadRequestServices();
            this.turnOffLoading(false);
        });
    }

    addNewAdditionalRequestService() {
        this.additionalRequestServicesGrid.addRow(this.createNewAdditionalRequestService());
    }

    turnOnLoading(basic: boolean = null) {
        if (basic == null) {
            this.loadingBasic = true;
            this.loadingAdditional = true;
        }
        else {
            if (basic) this.loadingBasic = true;
            else this.loadingAdditional = true;
        }
    }

    turnOffLoading(basic: boolean = null) {
        if (basic == null) {
            this.loadingBasic = false;
            this.loadingAdditional = false;
        }
        else {
            if (basic) this.loadingBasic = false;
            else this.loadingAdditional = false;
        }
    }

    get basicRequestServices(): NewRequestService[] {
        return this.requestServices.filter(x => x.isDefault == true);
    }

    get additionalRequestServices(): NewRequestService[] {
        return this.requestServices.filter(x => x.isDefault == false);
    }

    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}
}