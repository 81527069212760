import { Observable, forkJoin, of } from "rxjs";
import { BaseFlowComponent } from "../../base-flow-component/base-flow-component";
import { InstallFlowDeviceData } from "./install-flow-device.data";
import { Component, Input } from "@angular/core";
import { InstallFlowDeviceOrder } from "./install-flow-device-order";
import { RequestUninstallDevicesService } from "../../../../../services/request-uninstall-devices.service";
import { AttachmentsService } from "../../../../../services/attachments.service";
import { DeviceInfo } from "../../../../../models/device/device-info";
import { Attachment, UploadAttachmentsModel } from "../../../../../models/attachment";
import { AttachmentType } from "../../../../../enums";
import { tap } from "rxjs/operators";
import { UploadFileData } from "../../../../../models/attachment/upload-file-data";
import { ActivitiesService } from "../../../../../services/activities.service";

@Component({
    selector: 'install-flow-device',
    templateUrl: './install-flow-device.component.html',
    styleUrls: ['./install-flow-device.component.scss',
        '../../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class InstallFlowDeviceComponent 
    extends BaseFlowComponent<InstallFlowDeviceData>
{  
    serialNumberErrorMessage = 'По серийному номеру не найдены остатки в 1с. Исправьте номер и попробуйте еще раз, или свяжитесь с координатором';
    StepOrder = InstallFlowDeviceOrder;
    skipToNextStep = false;

    uploading = false;

    stepOrdering = [
        InstallFlowDeviceOrder.dismantledImages,
        InstallFlowDeviceOrder.serialAndPartNumbers
    ];

    @Input() uploadOnClick: boolean = false;

    currentStepIndex: number = 0;

    constructor(
        public requestUninstallDevicesService: RequestUninstallDevicesService,
        public attachmentsService: AttachmentsService,
        private activitiesService: ActivitiesService,) 
    {
        super();
    }

    initData() {
        if (this.data.isFromDualchoise){
            this.stepOrdering = [
                InstallFlowDeviceOrder.serialAndPartNumbers
            ];
        }
        this.currentStepIndex = this.isForwardMovement ? 0 : this.stepOrdering.length - 1;

        this.reloadAllAttachments();
    }

    saveChanges(): Observable<any> {
        let response;
        if (this.skipToNextStep) {
            response = {
                addDevice: false   
            };
        } else {
            response = {
                id :this.data.id,
                serialNumber: this.data.serialAndPartNumbersData.serialNumber,
                addDevice: true   
            };
        }

        this.onContinueEvent.emit(response);
        return of(null);
    }

    goToNextStep(): void {  
        if (this.skipToNextStep){
            this.goToNext();
        } else if (this.currentStepIndex == this.stepOrdering.length - 1) {
            this.goToNext();
        } else {
            this.currentStepIndex++;
        }
    }

    goToPreviousStep(): void {
        this.currentStepIndex--;
        if (this.currentStepIndex < 0) {
            this.goToPrevious();
        }
    }

    get currentStepNumber(): number {
        return this.stepOrdering[this.currentStepIndex];
    }

    uploadAttachmentsRequest(files: UploadFileData[]) {
        this.uploading = true;
        const uploadModel = new UploadAttachmentsModel({ 
            requestId: this.data.requestId, 
            activityId: this.data.activityId,
            attachments: files
        });

        let attachmentObservables$: Observable<any>[] = [];

        if (files.length > 0) {
            attachmentObservables$.push(
                this.attachmentsService.uploadWithResumableIfMobile(uploadModel)
                .pipe(tap(() => {
                    files.forEach(x => x.isUploadedOnServer = true);
                })));
        }

        forkJoin(attachmentObservables$).subscribe(() => {
            this.uploading = false;
            this.reloadAllAttachments();
        });
    }

    removeFile(file: UploadFileData){
        if (file) {
            this.attachmentsService.remove(file.uid, null, null, true).subscribe(() => {
                this.reloadAllAttachments();
            })
        }
    }

    reloadAllAttachments(){
        this.activitiesService.getById(this.data.activityId).subscribe(activity => {
            this.loadAttachmentsForType(AttachmentType.requestDeviceImage, activity.entity.attachments).subscribe(x => {
                this.data.imagesData.requestDeviceImages = x;
                this.data.imagesData.requestDeviceImages.forEach(x => x.isUploadedOnServer = true);
            });
            this.loadAttachmentsForType(AttachmentType.installedDevicesFotos, activity.entity.attachments).subscribe(x => {
                this.data.imagesData.requestDeviceSerialNumberImages = x;
                this.data.imagesData.requestDeviceSerialNumberImages.forEach(x => x.isUploadedOnServer = true);
            });
        });
    }

    loadAttachmentsForType(type: AttachmentType, attachments: Attachment[]): Observable<UploadFileData[]>{
        return new Observable((observer) => {
            var files = attachments
            .filter(x => x.attachmentTypeIds.includes(type))
            .map(attachment => {
                return this.loadFileData(attachment);
            });
            Promise.all(files)
                .then((results) => {
                    observer.next(results);
                    observer.complete();
                });
          });
    }

    async loadFileData(attachment: Attachment) { 
        let file = new UploadFileData;
        file.uid = attachment.attachmentId.toString();
        file.fileName = attachment.attachmentName;
        file.fileSize = attachment.attachmentSize;
        file.fileExtension = attachment.attachmentExtension;
        file.isUploadedOnServer = true;

		let blob = await this.attachmentsService.getAttachmentById(attachment.attachmentId).toPromise();
        var reader = new FileReader();
		reader.readAsDataURL(blob); 
		reader.onloadend = function() {
			file.fileData = (reader.result as string).split(',')[1];
		}

        return file;
    }

    public imagesUploadedEvent(skipToNextStep: boolean){
        this.skipToNextStep = skipToNextStep;
    }
}