import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { RequestDevicesService } from "../../../services/request-devices.service";
import { RequestsService } from "../../../services/requests.service";
import { NewRequestDevice } from "../../../models/request/new-request/new-request-device";
import { GridComponent } from "@progress/kendo-angular-grid";
import { LookupService } from "../../../services/lookup.service";
import { forkJoin } from "rxjs";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { HierarchyLookupModel } from "../../../models/core/HierarchyLookupModel";
import { DeviceStatus } from "../../../enums";
import { ReserveResult } from "../../../enums/reserve-result.enum";
import { DeviceModelDto } from "../../../models/device/device-model-dto";
import { DeviceModelsService } from "../../../services/device-models.service";

@Component({
	selector: 'request-page-devices',
	templateUrl: './request-page-devices.component.html',
    styleUrls: ['./request-page-devices.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	],
	encapsulation: ViewEncapsulation.None,
})
export class RequestPageDevicesComponent implements OnInit {

	@ViewChild("mainRequestDevicesGrid") mainRequestDevicesGrid: GridComponent;

    @Input() requestId: number;

    loading: boolean = false;
    dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    mainRequestDevices: NewRequestDevice[] = [];
    additionalRequestDevices: NewRequestDevice[] = [];

    deviceTypes: KeyValueObject[] = [];
    deviceModels: DeviceModelDto[] = [];
    dropDownDeviceModels: DeviceModelDto[] = [];
    deviceSuppliers: DeviceModelDto[] = [];
    dropDownDeviceSuppliers: DeviceModelDto[] = [];

    constructor (
        private lookupService: LookupService,
        private requestsService: RequestsService,
        public deviceModelsService: DeviceModelsService,
        private requestDevicesService: RequestDevicesService
    ) { }

    ngOnInit(): void {
        this.loadRequestDevices();

        let deviceTypes$ = this.lookupService.getData('device-types', null);
		let deviceModels$ = this.deviceModelsService.getAll();

        forkJoin(deviceTypes$,  deviceModels$)
            .subscribe(([deviceTypes, deviceModels]) => {
                this.deviceTypes = deviceTypes;
                this.deviceModels = deviceModels;
                this.dropDownDeviceModels = [];
                this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null);
                this.dropDownDeviceSuppliers = [];
            });
    }

    loadRequestDevices() {
        this.loading = true;
		this.requestsService.getRequestDevices(this.requestId).subscribe(resp => {
            this.mainRequestDevices = resp.filter(x => x.manuallyAdded == false);
            this.additionalRequestDevices = resp.filter(x => x.manuallyAdded == true);
            this.loading = false;
        });
    }

    getNameById(items: KeyValueObject[] = [], id: number): string {
        return items.some(x => x.id == id)
            ? items.find(x => x.id == id).name
            : '';
    }

    createNewMainRequestDevice(): NewRequestDevice {
        let requestDevice = new NewRequestDevice;
        requestDevice.requestId = this.requestId;
        requestDevice.manuallyAdded = false;
        requestDevice.reserveResultId = <number>ReserveResult.notBooked;
        return requestDevice;
    }

    addNewMainRequestDevice() {
        this.mainRequestDevicesGrid.addRow(this.createNewMainRequestDevice());
    }

    saveMainRequestDeviceHandler({ dataItem }: any) {
        this.loading = true;
        if (dataItem.requestDeviceId == null || dataItem.requestDeviceId == 0) {
            this.requestDevicesService.create(dataItem).subscribe(resp => {
                this.loadRequestDevices();
            });
        }
        else {
            this.requestDevicesService.update(dataItem, null).subscribe(resp => {
                this.loadRequestDevices();
            });
        }
    }

    removeMainRequestDeviceHandler({ dataItem }: any) {
        this.loading = true;
        this.requestDevicesService.remove(dataItem.requestDeviceId, null, null, false).subscribe(() => {
            this.loadRequestDevices();
        });
    }

    public deviceTypeChange(deviceTypeId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
            requestDevice.deviceSupplierId = null;
        }
    }

    public deviceSupplierChange(supplierId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
        }
    }

    public handleDeviceSuppliersDropdownOpen(deviceTypeId: number) {
        this.dropDownDeviceSuppliers = this.deviceModels != null && deviceTypeId
            ? this.deviceSuppliers.filter(x => x.typeId == deviceTypeId)
            : [];
    }

    public handleDeviceModelsDropdownOpen(deviceSupplierId: number) {
		this.dropDownDeviceModels = this.deviceModels != null && deviceSupplierId
            ? this.deviceModels.filter(x => x.parentId === deviceSupplierId)
            : [];
	}

    requestDeviceNotReserved(dataItem: NewRequestDevice): boolean {
        return dataItem == null || dataItem.deviceStatusId != DeviceStatus.reserved;
    }

    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}
}