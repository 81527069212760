import { Observable, of } from "rxjs";
import { BaseFlowComponent } from "../../base-flow-component/base-flow-component";
import { InstallFlowSummarySummaryData } from "./install-flow-summary-data";
import { Component, Input } from "@angular/core";
import { ActivitiesService } from "../../../../../services/activities.service";
import { NotificationService } from "../../../../../../core/services/notification.service";
import { DevicesService } from "../../../../../services/devices.service";
import { tap } from "rxjs/operators";
import { InstallationComplete } from "../../../../../models/device/installation-complete.model";
import { NotificationType } from "../../../../../../core/services/notification-type";

@Component({
    selector: 'install-flow-summary',
    templateUrl: './install-flow-summary.component.html',
    styleUrls: ['./install-flow-summary.component.scss',
        '../../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class InstallFlowSummaryComponent extends BaseFlowComponent<InstallFlowSummarySummaryData> {

    constructor(
        public activitiesService: ActivitiesService,
        public notificationService: NotificationService,
        public devicesService: DevicesService,
    ) {
        super();
    }
    validationError = false;

    initData() {
    }

    saveChanges(): Observable<any> {
        return of({});
    }

    validateDevices(){
        this.dataSaving = true;

		this.devicesService.reservedEqualUsed(this.data.requestId, this.data.devicesToInstall)
        .pipe(
			tap(() => this.dataSaving = false)
        )
        .subscribe((resp) => {
			if (!resp.data) {
				this.validationError = true;
			}
			else
				this.finishSuccesfully();
		});
    }

    goBack(){
        this.validationError = false;
    }

    finishSuccesfully() {
        this.dataSaving = true;
        let model = new InstallationComplete();
        model.requestId = this.data.requestId;
        model.activityId = this.data.activityId;
        model.devices = this.data.devicesToInstall;
        model.comment = this.data.comment;
        model.finishDateFact = this.data.completedDateTime;
        model.activityResultId = this.data.activityResultId;
        model.activityResultId = this.data.activityResultId;
        model.activityWorkflowTransitionId = this.data.activityWorkflowTransitionId;

		this.devicesService.installationComplete(model)
        .pipe(
            tap(() => this.dataSaving = false))
        .subscribe((resp) => {
			if (resp.isSuccessful) {
				this.goToNext();
			} else {
                this.notificationService.error({
					title: 'Ошибка при успешном завершении активности',
					message: resp.errorDescription,
					notificationType: NotificationType.Toast
				});
            }
		});
    }
}