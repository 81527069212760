import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CustomerObjectsService } from "../../../customer-objects.service";
import { GridComponent } from "@progress/kendo-angular-grid";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { CustomerObjectDeviceDto } from "../../../../../shared/models/customerObject/customer-object-device";

@Component({
	selector: 'customer-object-page-devices',
	templateUrl: './customer-object-page-devices.component.html',
	styleUrls: ['./customer-object-page-devices.component.scss',
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class CustomerObjectPageDevicesComponent implements OnInit {

    @ViewChild("requestDevicesGrid") requestDevicesGrid: GridComponent;

	@Input() customerObjectId: number;
    @Input() disabled: boolean = false;

    @Output() saveRequestDevices: EventEmitter<CustomerObjectDeviceDto[]> = new EventEmitter();

	dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	loading: boolean = false;

    customerObjectDevices: CustomerObjectDeviceDto[] = [];

	constructor (
        private customerObjectsService: CustomerObjectsService
	) {}

	ngOnInit(): void {
		this.loading = true;
        this.customerObjectsService.getCustomerObjectDevices(this.customerObjectId).subscribe(resp => {
            this.customerObjectDevices = resp;
            this.loading = false;
        });
	}

    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}
}
