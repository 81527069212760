export class NewRequestService {
    requestId: number;
    requestServiceId: number;
    serviceId: number;
    serviceName: string;
    serviceCategoryId: number;
    serviceCategoryName: string;
    serviceTypeId: number;
    serviceTypeName: string;
    serviceModeName: string;
    isDone: boolean;
    isDefault: boolean;
}