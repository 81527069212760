import { Component, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { CustomerObjectsService } from '../customer-objects.service';
import { CustomerObject } from '../../../shared/models/customerObject/CustomerObject';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { LookupService } from '../../../shared/services/lookup.service';
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';
import { IdleService } from '../../../shared/services/idle.service';
import { GridSettings } from '../../../shared/models/grid/grid-settings';
import { ColumnVisibilityChangeEvent } from '@progress/kendo-angular-grid';
import { ColumnSettings } from '../../../shared/models/grid/column-settings';

@Component({
	selector: 'categories-customer-objects-list',
	templateUrl: './list.page.html',
	styleUrls: [
		'./list.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	],
	providers: [IdleService]
})
export class CustomerObjectsListPage extends ListPageBase<CustomerObject> implements AfterViewInit {

	public gridSettingsFilter: GridSettings = {
		state: {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: { logic: 'and', filters: [] },
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'name',
				title: 'Имя',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'address',
				title: 'Адрес',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'contragentName',
				title: 'Владелец',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'contragentPhone',
				title: 'Телефон владельца',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'contragentINN',
				title: 'ИНН владельца',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'createdDate',
				title: 'Дата создания',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 400,
				hidden: false
			},
		]
	}

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: { logic: 'and', filters: [] },
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'name',
				title: 'Имя',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'address',
				title: 'Адрес',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'contragentName',
				title: 'Владелец',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'contragentPhone',
				title: 'Телефон владельца',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'contragentINN',
				title: 'ИНН владельца',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'createdDate',
				title: 'Дата создания',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 400,
				hidden: false
			},
		]
	}

	constructor(
		appService: AppService,
		router: Router,
		private lookupService: LookupService,
		protected dataService: CustomerObjectsService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Объекты обслуживания';
	}

	onListPageInit(): void { 
	}

	ngAfterViewInit(): void { }

	processListItems(items: CustomerObject[]): void { }

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/categories/customer-object', dataItem.customerObjectId]);
	}
}
