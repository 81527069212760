import { BaseObject } from '../../core/BaseObject';
import { NewRequestService } from './new-request-service';
import { NewRequestDevice } from './new-request-device';
import { UploadFile } from '../../attachment/upload-file';
import { CustomerContact } from '../customer-contact';

export class NewRequest extends BaseObject {

    externalId: string;
    projectId: number;

    requestTypeId: number;
    requestTypeName: string;
    requestCategoryId: number;

    initTypeId: number;

    performerUserGroupId: number;

    statusId: number;
    
    agreementNumber: string;
    agreementId: number;
    priorityId: number;

    contactName: string;
    contactPhone: string;
    contactEmail: string;

    customerContragentId: number;

    merchantId: number;
    customerObjectId: number;
    serviceCenterId: number;
    warehouseId: number;
    authorContragentId: number;

    senderWarehouseId: number;
	recipientWarehouseId: number;

    selectedTidId: number;

    requestServices: NewRequestService[] = [];
    requestDevices: NewRequestDevice[] = [];
    requestAttachments: UploadFile[] = [];
    requestContacts: CustomerContact[] = [];

    tidServiceCategoryId: number;
    tidServiceCategoryName: string;

    description: string;
}