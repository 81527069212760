import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { RequestsService } from "../../../services/requests.service";
import { NewRequestService } from "../../../models/request/new-request/new-request-service";
import { GridComponent } from "@progress/kendo-angular-grid";
import { Observable } from 'rxjs/Observable';
import { RequestServicesService } from "../../../services/request-services.service";
import { CategoryServicesService } from "../../../services/category-services.service";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { NewRequestDevice } from "../../../models/request/new-request/new-request-device";
import { RequestDevicesService } from "../../../services/request-devices.service";
import { forkJoin } from "rxjs";
import { ReserveResult } from "../../../enums/reserve-result.enum";
import { UploadAttachmentsModel, UploadFile } from "../../../models/attachment";
import { AttachmentsService } from "../../../services/attachments.service";

@Component({
	selector: 'finish-activity-with-devices-services',
	templateUrl: './finish-activity-with-devices-services.html',
	styleUrls: ['./finish-activity-with-devices-services.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class FinishActivityWithDevicesServices implements OnInit {
    
    @Input() requestId: number;
    @Input() activityId: number;

    @Output() onContinueEvent: EventEmitter<any> = new EventEmitter();
    @Output() onCancelEvent: EventEmitter<any> = new EventEmitter();

    attachments: UploadFile[] = [];
    requestServices: NewRequestService[] = [];
    requestDevices: NewRequestDevice[] = [];

    filesUploaded: boolean = false;
    dataSaving: boolean = false;
    loadingServices: boolean = false;
    loadingDevices: boolean = false;

    dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    constructor(
        private requestsService: RequestsService,
        private requestServicesService: RequestServicesService,
        private requestDevicesService: RequestDevicesService,
        private categoryServicesService: CategoryServicesService,
        private attachmentsService: AttachmentsService
    ) { }

    ngOnInit(): void {
        this.loadRequestServices();
        this.loadRequestDevices();
    }

    loadRequestServices() {
        this.loadingServices = true;
        this.requestsService.getRequestServices(this.requestId).subscribe(resp => {
            this.requestServices = resp;
            this.loadingServices = false;
        });
    }

    loadRequestDevices() {
        this.loadingDevices = true;
        this.requestsService.getRequestDevices(this.requestId).subscribe(resp => {
            this.requestDevices = resp;
            this.requestDevices.forEach(x => {
                x.toDelivery = x.reserveResultId == ReserveResult.installedOnTsp;
            })
            this.loadingDevices = false;
        });
    }

    saveChanges() {
        this.dataSaving = true;
        this.filesUploaded = false;

        const uploadModel = new UploadAttachmentsModel({ 
            requestId: this.requestId,
            activityId: this.activityId,
            attachments: this.attachments 
        });

		this.attachmentsService.upload(uploadModel).subscribe(() => {
            if (!this.filesUploaded) {
                this.filesUploaded = true;
                this.onContinueEvent.emit();
                this.dataSaving = false;
            }
        });
    }

    saveRequestDeviceHandler({ dataItem }: any) {
        this.dataSaving = true;
        console.log(dataItem);
        let requestDevice = <NewRequestDevice>dataItem;
        requestDevice.reserveResultId = requestDevice.toDelivery 
            ? <number>ReserveResult.installedOnTsp
            : <number>ReserveResult.pendingIssueToEngineer;

        this.requestDevicesService.update(requestDevice, null).subscribe(() => {
            this.dataSaving = false;
            this.loadRequestDevices();
        });
    }

    saveRequestServiceHandler({ dataItem }: any) {
        this.dataSaving = true;
        this.requestServicesService.update(dataItem, null).subscribe(() => {
            this.dataSaving = false;
            this.loadRequestServices();
        })
    }

    turnRequestDevice(event: any, dataItem: NewRequestDevice) {
        dataItem.toDelivery = event.target.checked;
        this.saveRequestDeviceHandler({dataItem});
    }

    turnRequestService(event: any, dataItem: NewRequestService) {
        dataItem.isDone = event.target.checked;
        this.saveRequestServiceHandler({dataItem});
    }

    onAttachmentsAdded(attachments: UploadFile[]) {
		this.attachments = attachments;
	}

    cancel() {
        this.onCancelEvent.emit();
    }

    getNameById(items: KeyValueObject[] = [], id: number): string {
        return items.some(x => x.id == id)
            ? items.find(x => x.id == id).name
            : '';
    }

    createNewRequestDevice(): NewRequestDevice {
        let device = new NewRequestDevice();
        device.manuallyAdded = true;
        device.requestId = this.requestId;
        return device;
    }

    createRequestService(): NewRequestService {
        var requestService = new NewRequestService();
        requestService.requestId = this.requestId;
        requestService.isDefault = false;
        return requestService;    
    }

    requestDeviceToDeliveryChange(event: any, dataItem: NewRequestDevice) {
        dataItem.toDelivery = event.target.checked;
        this.saveRequestDeviceHandler(dataItem);
    }

    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

}