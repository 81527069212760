import { Observable, Subscription, fromEvent, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { For1CService } from "../../../../services/for-1c.service";
import { GetLastNomenclatureBySeriesQuery } from "../../../../models/for-1c/get-last-nomenclature-by-series/get-last-nomenclature-by-series-1c-query";
import { Guid } from "guid-typescript";
import { Component } from "@angular/core";
import { NotificationService } from "../../../../../core/services/notification.service";
import { NotificationType } from "../../../../../core/services/notification-type";
import { NomenclatureService } from "../../../../services/nomenclature.service";
import { KeyValueObject } from "../../../../models/core/KeyValueObject";
import { FormGroup } from "@angular/forms";
import { DismantledSeriesSerialPartNumbersData } from "./dismantled-series-serial-part-numbers-data";

@Component({
    selector: 'dismantled-series-serial-part-numbers',
    templateUrl: './dismantled-series-serial-part-numbers.component.html',
    styleUrls: ['./dismantled-series-serial-part-numbers.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class DismantledSeriesSerialPartNumbersComponent 
    extends BaseFlowComponent<DismantledSeriesSerialPartNumbersData> 
{
	public form: FormGroup;

    oldSerialNumber: string = null;
    serialNumberValid: boolean = true;
    partNumbers: KeyValueObject[] = [];
    nomenclatureByPartNumber: string = null;

    private partnumberSubscription: Subscription = null;
    private delayPartNumbersSearch: NodeJS.Timer = null;

    constructor(
        private for1CService: For1CService,
        private nomenclatureService: NomenclatureService,
        private notificationService: NotificationService) {
        super();
    }

    initData(): void {
        this.oldSerialNumber = this.data.serialNumber;
    }

    saveChanges(): Observable<any> {
        return of({});
    }

    validateInputs() {
        if (!this.data.partNumber || this.serialNumberWasChanged) {
            this.validateSerialNumber();
        }
        else if (this.data.partNumber) {
            if (this.partNumbers.filter(x => x.name == this.data.partNumber).length > 0) {
                let selectedNomenclature = this.partNumbers.find(x => x.name == this.data.partNumber); 
                if (selectedNomenclature) {
                    this.data.nomenclature = selectedNomenclature.groupName;
                }
            }
            this.goToNext();
        }
    }

    get serialNumberWasChanged(): boolean {
        return this.oldSerialNumber != this.data.serialNumber;
    }

    validateSerialNumber() {
        this.oldSerialNumber = this.data.serialNumber;
        let query: GetLastNomenclatureBySeriesQuery = {
            httpQueryId: Guid.create().toString(),
            serialNumbers: [this.data.serialNumber]
        };
        
        this.for1CService.getLastNomenclatureBySeries(query).subscribe((response) => {
            if (response.data && response.data.serialNumbers && 
                response.data.serialNumbers.length > 0 &&
                response.data.serialNumbers[0].nomenclature) {

                this.data.nomenclature = response.data.serialNumbers[0].nomenclature;
                this.goToNext();
            }
            else {
                this.serialNumberValid = false;
            }
        });
    }

    filterPartNumbers(value: string) {	
		if (!value || value.length == 0) {
			return;
		}
		if (this.delayPartNumbersSearch) {
			clearTimeout(this.delayPartNumbersSearch);
		}

		this.delayPartNumbersSearch = setTimeout(() => {
			if (this.partnumberSubscription) {
				this.partnumberSubscription.unsubscribe();
			}

			this.partnumberSubscription = this.nomenclatureService.getPartnumberByName(value)
            .subscribe((response) => {
                let partNumbers = [] as KeyValueObject[];               
                if (response && response.length > 0) {    
                    response.forEach(nomenclatureData => {
                        if (nomenclatureData && nomenclatureData.partnumbers) {
                            nomenclatureData.partnumbers.forEach(partNumber => {
                                let listItem = new KeyValueObject(partNumber.articleId, partNumber.articleName)
                                listItem.groupName = nomenclatureData.nomenclatureName;
                                partNumbers.push(listItem);
                            });
                        }
                    });
                }
                this.partNumbers = partNumbers;
            });
		}, 2000);
	}
}