import { Attachment } from '../attachment/attachment';
import { ActivityHistory } from './activity-history';
import { ActivityOperation } from './activity-operation';
import { Request } from '../request/request';

export class Activity {
    activityId: number;
    activityNumber: string;

    createdDate: Date;
    createdDateMoscow: Date;
    createdDateLocal: Date;

    requestId: number;
    performerUserId: number;
    performerUserName: string;
    changePerformerUserId: number;
    changePerformerUserName: string;

    performerPhoneNumber: string;
    performerExtPhoneNumber: string;

    performerUserIntegraCode: string

    planStartDate: Date;
    planStartDateMoscow: Date;
    planStartDateLocal: Date;

    utcTimezoneShiftString: string;

    dueDate: Date;
    dueDateMoscow: Date;
    dueDateLocal: Date;

    finishDateLocal: Date;

    statusId: number;
    statusName: string;
    attachments: Attachment[] = [];
    history: ActivityHistory[] = [];
    operations: ActivityOperation[] = [];
    activityReason: string;

		activityTypeId: number;

    skipExceptions: number[] = [];

    workFlowStepTypeId?: number;
    activityResultId?: number;

    isEditAllowed: boolean = true;

    finishActivityComment: string;

    constructor(obj?: Partial<Activity>) {
        Object.assign(this, obj);
    }
}
