import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation, NgZone, Output, EventEmitter } from "@angular/core";
import { RequestBwt } from "../../../models/request/request-bwt";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { LookupService } from "../../../services/lookup.service";
import { ActivityStatusEnum, RequestInitTypeEnum, RequestStatus, RequestType, RoleEnum } from "../../../enums";
import { KendoDropdownListComponent } from "../../workflow-transitions/kendo-dropdown-list/kendo-dropdown-list.component";
import { Activity } from "../../../models/activity/activity";
import { RequestsService } from "../../../services/requests.service";
import { AppService } from "../../../../app.service";
import { groupBy, GroupResult, orderBy } from "@progress/kendo-data-query";
import { IntlService } from "@progress/kendo-angular-intl";
import { ActivityType } from "../../../enums/activity-type.enum";
import { toDataSourceRequestString, DataSourceRequestState } from '@progress/kendo-data-query';
import { take } from "rxjs/operators";
import { GridComponent, SelectableSettings } from "@progress/kendo-angular-grid";
import { ServiceCentersService } from "../../../services/service-centers.service";
import { ActivitiesService } from "../../../services/activities.service";
import { EngineerLookupModel } from "../../../models/engineers/engineer-lookup-model";
import { ServiceCenterEngineersData } from "../../../models/engineers/service-center-engineers-data";
import { ServiceCenterEngineers } from "../../../models/engineers/service-center-engineers";
import { SecurityService } from "../../../../core/services/security.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { NotificationType } from "../../../../core/services/notification-type";
import { ExceptionCode } from "../../../enums/exception-code.enum";
import { SetCommentModel } from "../../workflow-transitions/set-comment/set-comment.model";
import { DialogService } from "@progress/kendo-angular-dialog";
import { UploadFile } from "../../../models/attachment";
import { CommentKindEnum } from "../../../enums/comment-kind.enum";
import { SetComment } from "../../workflow-transitions/set-comment/set-comment";
import { RequestGroupDto } from "../../../models/request/request-group-dto";
import { MultipleOperationErrorResultComponent } from "../../multiple-operations/multiple-operation-error-result/multiple-operation-error-result.component";
import { MultipleError } from "../../../models/request/multiple-error";
import { ActivityWorkflowTransition } from "../../../models/activity-workflow/activity-workflow-transition";
import { UsersService } from "../../../../admin/users/users.service";
import { UserGroupsService } from "../../../services/user-groups.service";

@Component({
	selector: 'request-page-activities',
	templateUrl: './request-page-activities.component.html',
	styleUrls: ['./request-page-activities.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class RequestPageActivitiesComponent implements OnInit {
    
    @Input() request: RequestBwt;

    activities: Activity[] = [];

	private requestStatuses: KeyValueObject[] = [];
    loadingActivities: boolean;
	loadingRequest: boolean;

	isEditing: boolean = false;
    loadingEngineers: boolean = false;
    engineersGrouped: any[] = [];
    engineers: EngineerLookupModel[] = [];
    emptyEngineersMessage: string = "Не найдено инженеров";

    selectedActivities: number[] = [];
	selectedActivity: Activity = null;
    public activitySelectableSettings: SelectableSettings = { checkboxOnly: false, mode: "single" };
    activityTypes: KeyValueObject[] = [];
    activityTypesForCreatingActivity: KeyValueObject[] = [];
    nodes: any[] = [];
	showActivitiesGrid: boolean = true;
	showActivityDetails: boolean = true;
    
	@Output() refreshRequestEvent: EventEmitter<RequestBwt> = new EventEmitter<RequestBwt>();

    @ViewChild('activitiesGrid') public activitiesGrid: GridComponent;
    
    get isOnlyStorekeeper() {
		return this.securityService.isOnlyStorekeeper();
	}

    public get isMkInitType(): boolean {
		return this.request.initTypeId === <number>RequestInitTypeEnum.blockChain;
	}

    get groupTreeLogic(): boolean {
		return this.request.isInGroupExt || this.request.isGroup;
	}
    
	get selectedActivityId(): number {
		if (this.selectedActivities.length === 0) {
			return null;
		}

		return this.selectedActivities[0];
	}

	constructor(
		private lookupService: LookupService,
		private elem: ElementRef,
        protected requestsService: RequestsService,
        protected appService: AppService,
        private intl: IntlService,
        private ngZone: NgZone,
        private serviceCentersService: ServiceCentersService,
        private activitiesService: ActivitiesService,
        private securityService: SecurityService,
        protected notificationService: NotificationService,
        protected dialogService: DialogService,
		private userGroupsService: UserGroupsService
	) { }

	ngOnInit(): void {
		this.lookupService.getData("request-statuses", null).subscribe((resp) => {
			this.requestStatuses = resp;
		});

        this.fillActivityTypes([
			{ id: <number>ActivityType.inspection, name: "Обследование", groupName: null, integraCode: null, disabled: false },
			{ id: <number>ActivityType.techSupport, name: "Монтаж", groupName: null, integraCode: null, disabled: false },
			{ id: <number>ActivityType.warehouse, name: "Склад", groupName: null, integraCode: null, disabled: false },
			{ id: <number>ActivityType.engineering, name: "Проектирование", groupName: null, integraCode: null, disabled: false },
			{ id: <number>ActivityType.laboratory, name: "Лабораторная", groupName: null, integraCode: null, disabled: false }
		]);

        this.loadActivities(this.request.requestId);

        //setTimeout(() => this.fitActivitiesColumns(), 1000);
	}

	editRequest() {
		this.isEditing = !this.isEditing;
	}

	getRequestStatusName(statusId: number): string {
		return this.requestStatuses.some(x => x.id == statusId)
			? this.requestStatuses.find(x => x.id == statusId).name
			: '';
	}

	getRequestStatusDropdownListItemColor(requestStatusId: number): string {
		switch (requestStatusId) {
			case RequestStatus.Registered:
				return "#54dc36";
			case RequestStatus.InProgress:
				return "#dbee22";
			case RequestStatus.Cancelled:
				return "#eb5252";
			case RequestStatus.Stopped:
				return "#b8b8b8";
			default:
				return "#d5d5d5";
		}
	}

	loadRequest(requestId: number) {
		this.loadingRequest = true;
		this.requestsService.getBwtById(requestId).subscribe((resp) => {
			this.request = resp.entity;
			this.refreshRequestEvent.emit(resp.entity);
			this.loadingRequest = false;
		});
	}

    loadActivities(requestId: number, activityId?: number) {

		this.loadingActivities = true;

		this.requestsService.getActivities(requestId).subscribe(res => {
			res.forEach(a => {
				a.createdDate = this.appService.parseDateTime(a.createdDate);
				a.planStartDate = this.appService.parseDateTime(a.planStartDate);
				a.dueDate = this.appService.parseDateTime(a.dueDate);
			});

			this.activities = orderBy(res, [{ field: "createdDate", dir: "desc" }]);

			// if (this.isMobileDevice) {
			// 	this.activities = this.activities.filter(a => a.activityTypeId === <number>ActivityType.engineer);
			// }

			if (activityId != null) {
				this.showActivityById(activityId);
			} else if (this.activities.length > 0) {
				this.showActivityById(this.activities[0].activityId);
			}

			// this.fitActivitiesColumns();

			// if (this.activities && this.activities.length > 0) {
			// 	let lastActivity = this.activities[0];
			// 	switch (lastActivity.statusId) {
			// 		case <number>ActivityStatusEnum.activityCreated:
			// 		case <number>ActivityStatusEnum.visitConfirmed:
			// 		case <number>ActivityStatusEnum.contactPersonNotRespond:
			// 		case <number>ActivityStatusEnum.tspRefused:
			// 		case <number>ActivityStatusEnum.tspNotReady:
			// 		case <number>ActivityStatusEnum.visitWarehouse:
			// 		case <number>ActivityStatusEnum.equipmentIsReadyForDelivery:
			// 		case <number>ActivityStatusEnum.equipmentIssuedFromWarehouse:
			// 		case <number>ActivityStatusEnum.awaitingDeliveryOfEquipment:
			// 			this.canChangeEngineerByActivity = true;
			// 			break;

			// 		default:
			// 			this.canChangeEngineerByActivity = false;
			// 			break;
			// 	}
			// }

			this.loadingActivities = false;
		});
	}

    // showActivityById(activityId: number) {

	// 	if (this.isMobileDevice) {
	// 		this.showActivitiesGrid = false;
	// 		this.showActivityDetails = true;
	// 	}

	// 	this.selectedActivities = [];
	// 	let activity = this.activities.find(x => x.activityId == activityId);
	// 	if (!this.statusIsCanceled(activity)){
	// 		this.selectedActivities.push(activityId);
	// 	}
	// 	this.onSelectedActivityChange();
	// }

	public onSelectedActivityChange() {

		if (!this.selectedActivityId)
			return;

		this.activitiesService.getById(this.selectedActivityId).subscribe(x => {
			this.selectedActivity = x.entity;

			// if (this.activityComments)
			// 	this.activityComments.loadComments(0, this.getSelectedActivityPerformerUserId());

			// this.fitActivitiesColumns();

			// if (this.activeIdString == "activities-tab")
			// 	this.loadFines()
		});
	}

    parseMultiTimezoneDate(dateTime: any) {

		if (dateTime == null) {
			return '';
		}

		var parsedDate = new Date();

		if (dateTime instanceof Date) {
			parsedDate = dateTime;
		} else if (typeof dateTime === 'string') {
			if (dateTime.startsWith("1753")) {
				return '';
			}

			parsedDate = this.intl.parseDate(dateTime);
		}

		return `${this.intl.formatDate(parsedDate, 'dd.MM.yyyy HH:mm')} ${this.request.utcTimezoneShiftString}`;
	}

    getActivityTypeNameById(activityTypeId: number): string {
		const activityType = this.activityTypes.filter(x => x.id === activityTypeId);
		return activityType.length === 1 ? activityType[0].name : '';
	}

    fillActivityTypes(activityTypes: KeyValueObject[]) {
		this.activityTypes = activityTypes;
		this.activityTypesForCreatingActivity = this.activityTypes;
	}

    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

    private fitActivitiesColumns(): void {
		this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {

			if (this.activitiesGrid) {
				this.activitiesGrid.autoFitColumns();
			}

		});
	}

    addNewActivity() {
		this.activitiesGrid.addRow(this.createNewActivity());
	}

    createNewActivity(): Activity {
        let now = new Date();
        let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        let local = new Date(utc.getTime() + (this.request.utcTimezoneShift * 60 * 60 * 1000));

        let activityTypeId = <number>ActivityType.engineer;

        let activity = { 
            requestId: this.request.requestId,
            createdDate: local,
            planStartDate: local,
            activityTypeId: activityTypeId,
        } as Activity;

        return activity;
    }

    loadEngeneersByServiceCenter(serviceCenterId: number, plannedStartDate: Date, mustBeStorekeeper: boolean = false, afterLoadAction: Function = null) {

		if (this.request.requestTypeId !== <number>RequestType.warehouseRefill
			&& this.request.requestTypeId !== <number>RequestType.warehouseTransfer
			&& this.request.requestTypeId !== <number>RequestType.foreighWarehouseTransfer
			&& this.request.requestTypeId !== <number>RequestType.internalWarehouseTransfer
			&& this.request.requestTypeId !== <number>RequestType.warehousePosting
			&& !serviceCenterId) {
			this.emptyEngineersMessage = 'Не указан сервисный центр';
			return;
		}

		// this.serviceCentersService
		// 	.getEngineers(serviceCenterId, this.entityViewModel.entity.requestId)
		// 	.subscribe(data => {
		// 		this.engeneers = data;
		// 	});

		if (this.request.initTypeId === <number>RequestInitTypeEnum.blockChain &&
			this.request.requestTypeId !== <number>RequestType.warehouseRefill &&
			this.request.requestTypeId !== <number>RequestType.issueFromWarehouse &&
			this.request.requestTypeId !== <number>RequestType.warehouseTransfer &&
			this.request.requestTypeId !== <number>RequestType.foreighWarehouseTransfer &&
			this.request.requestTypeId !== <number>RequestType.internalWarehouseTransfer &&
			this.request.requestTypeId !== <number>RequestType.warehousePosting &&
			this.request.requestTypeId !== <number>RequestType.receiveToWarehouse &&
			this.request.requestTypeId !== <number>RequestType.issueAndReceiveToWarehouse &&
			this.request.lastActivityId === null &&
			(this.request.commonData === null ||
				!!!this.request.commonData.isUnknownDistanceRequest) &&
			this.request.partnerStatusAlias !== 'takenToWorkSP') {
			this.emptyEngineersMessage = `В заявке указан статус в системе партнера отличный от 'takenToWorkSP'`;
			return;
		}

		if (this.request.initTypeId === <number>RequestInitTypeEnum.blockChain &&
			this.request.requestTypeId !== <number>RequestType.warehouseRefill &&
			this.request.requestTypeId !== <number>RequestType.issueFromWarehouse &&
			this.request.requestTypeId !== <number>RequestType.warehouseTransfer &&
			this.request.requestTypeId !== <number>RequestType.foreighWarehouseTransfer &&
			this.request.requestTypeId !== <number>RequestType.internalWarehouseTransfer &&
			this.request.requestTypeId !== <number>RequestType.warehousePosting &&
			this.request.requestTypeId !== <number>RequestType.receiveToWarehouse &&
			this.request.requestTypeId !== <number>RequestType.issueAndReceiveToWarehouse &&
			this.request.lastActivityId === null &&
			this.request.commonData !== null &&
			!!this.request.commonData.isUnknownDistanceRequest &&
			this.request.partnerStatusAlias !== 'stopedSLA') {
			this.emptyEngineersMessage = `В заявке указан статус в системе партнера отличный от 'stopedSLA'`;
			return;
		}

		let engineersMultipleSDateModel = new ServiceCenterEngineersData(this.request.requestId, serviceCenterId, plannedStartDate, mustBeStorekeeper);

		let engineersMultipleScModel = new ServiceCenterEngineers([engineersMultipleSDateModel]);

		this.loadingEngineers = true;
		this.serviceCentersService.engineersMultipleSc(engineersMultipleScModel).subscribe(x => {
			if (x.isSuccessful) {
				this.engineers = x.data;
				this.engineersGrouped = orderBy(<GroupResult[]>groupBy(this.engineers, [{ field: "region" }]), [{ field: "value", dir: "asc" }]);
			}

			if (afterLoadAction != null)
				afterLoadAction();

			this.loadingEngineers = false;
		});
	}

    public refreshEngineers(activity: Activity): void {
		activity.performerUserId = null;
		this.loadingEngineers = true;

		this.userGroupsService.getUsersByActivityTypeId(activity.activityTypeId).subscribe(resp => {
			this.engineers = resp;

			this.engineersGrouped = orderBy(this.engineers, [{ field: "value", dir: "asc" }]);
			this.loadingEngineers = false;
		});

		return;
	}

    activityPerformerUserDisabled(activity: Activity): boolean {
		return !!activity
			&& !!activity.performerUserId
			&& this.engineers.map(m => m.engineerUserId).includes(activity.performerUserId)
			&& this.isOnlyStorekeeper;
	}

    performerChangeUser(dataItem: Activity) {
		if (dataItem.statusId === 13 || (dataItem.statusId == 24 && this.request.customerContragentId == 1055))
			return dataItem.changePerformerUserId;
		else {

			return dataItem.performerUserId;
		}
	}

    changePerformerUser(dataItem: Activity, value) {
		if (dataItem.statusId === 13 || (dataItem.statusId == 24 && this.request.customerContragentId == 1055))
			dataItem.changePerformerUserId = value;
		else
			dataItem.performerUserId = value;
	}

    public activitySaveHandler(eventArgs: any) {
		const isNew = !!eventArgs.dataItem.activityId;

		if (isNew) {
			this.activitiesService.getById(eventArgs.dataItem.activityId).subscribe(async activityOld => {
				let engineerIdNew = eventArgs.dataItem.performerUserId;
				let engineerIdOld = activityOld.entity.performerUserId;

				if ((activityOld.entity.statusId === 13 || (activityOld.entity.statusId === 24 && this.request.customerContragentId == 1055)) && activityOld.entity.changePerformerUserId !== eventArgs.dataItem.changePerformerUserId) {
					const newUser = this.engineers.filter(p => p.engineerUserId === eventArgs.dataItem.changePerformerUserId)[0];
					this.notificationService.confirmation({
						title: this.request.requestId.toString(),
						message: `Вы пытаетесь сменить инженера после того, как оборудование уже было выдано. Активность перейдет в состояние ожидания действий по приему оборудования со с
						тороны нового инженера ${newUser.name}`,
						type: 'question',
						confirmButtonText: 'Ок',
						cancelButtonText: 'Отменить',
						showCloseButton: true
					}, () => {
						this.updateActivity(eventArgs.dataItem, engineerIdNew, engineerIdOld);
					}, () => { });
				} else {
					this.updateActivity(eventArgs.dataItem, engineerIdNew, engineerIdOld);
				}

			});

		} else {
			this.AddNewActivity(<Activity>eventArgs.dataItem);
			eventArgs.dataItem = null;
		}
	}

    private updateActivity(dataItem: any, engineerIdNew: number, engineerIdOld: number): void {
		let commentModel: SetCommentModel = null;

		if (engineerIdOld !== engineerIdNew && !this.request.isInGroupExt) {
			const dialogRef = this.dialogService.open({
				content: SetComment,
				width: this.appService.isMobileDevice ? '90%' : '500px',
				title: "Укажите причину смены инженера"
			});

			const customerObjectForm = <SetComment>dialogRef.content.instance;

			if (customerObjectForm.setCommentModel == null) {
				customerObjectForm.setCommentModel = new SetCommentModel('');
			}

			customerObjectForm.setCommentModel.commentKind = CommentKindEnum.internal;

			customerObjectForm.onContinueEvent.subscribe((setCommentModel: SetCommentModel) => {
				commentModel = setCommentModel;

				this.updateActivityCore(dataItem, () => {

					let uploadAttachments: UploadFile[] = [];
					this.requestsService.addComment(
						{
							text: `Причина смены инженера: ${commentModel.commentText}`,
							requestId: this.request.requestId,
							activityId: dataItem.activityId,
							uploadAttachments: uploadAttachments,
							notifyCustomer: false,
							notifyEngineer: false,
							engineerNotifyWays: [],
							commentKind: CommentKindEnum.system
						}).subscribe(x => { });

					this.requestsService.addComment(
						{
							text: `Причина смены инженера по активности [${dataItem.activityId}]: ${commentModel.commentText}`,
							requestId: this.request.requestId,
							activityId: null,
							uploadAttachments: uploadAttachments,
							notifyCustomer: false,
							notifyEngineer: false,
							engineerNotifyWays: [],
							commentKind: CommentKindEnum.system
						}).subscribe(x => { });

					this.loadActivities(this.request.requestId);

				});

			});
		} else {

			this.updateActivityCore(dataItem, () => {
				this.loadActivities(this.request.requestId);
			});

		}
	}

    private AddNewActivity(activity: Activity): void {

		this.loadingActivities = true;

		this.activitiesService.create(activity, NotificationType.SweetAlert, true).subscribe(x => {

			var needReloadActivities = true;
			let needReloadRequest = this.isMkInitType
				&& this.request.requestTypeId === <number>RequestType.remoteInstall;

			if (!x.isSuccessful) {

				if (x.code === 948) {
					this.showGroupError(x.errorDescription);

				} else if (x.code === <number>ExceptionCode.engineerHasDifferentThanRequestServiceCenterException) {

					this.notificationService
						.confirmation({
							message: x.errorDescription,
							type: 'question',
							confirmButtonText: 'Изменить Исполнителя (СЦ)',
							cancelButtonText: 'Отмена',
							showCloseButton: false
						},
							() => {
								activity.skipExceptions.push(<number>ExceptionCode.engineerHasDifferentThanRequestServiceCenterException);
								this.AddNewActivity(activity);
								return;
							},
							() => {
								needReloadActivities = false;
								this.loadingActivities = false;
								return;
							}
						);


				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: x.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

				this.loadingActivities = false;

			} else {
				if (needReloadActivities)
					this.loadActivities(this.request.requestId);

				// if (needReloadRequest)
				// 	this.loadRequest(this.request.requestId);
			}
		});

	}

    updateActivityCore(activity: Activity, onSuccess: Function) {

		this.activitiesService.update(activity, 'Наряд обновлен', true).subscribe(x => {
			if (x.isSuccessful) {

				if (!!onSuccess)
					onSuccess();

			} else {
				if (x.code === 948) {
					this.showGroupError(x.errorDescription);
				} else if (x.code === <number>ExceptionCode.engineerHasDifferentThanRequestServiceCenterException) {

					this.notificationService
						.confirmation({
							message: x.errorDescription,
							type: 'question',
							confirmButtonText: 'Изменить Исполнителя (СЦ)',
							cancelButtonText: 'Отмена',
							showCloseButton: false
						},
							() => {

								if (activity.skipExceptions == null)
									activity.skipExceptions = [];

								activity.skipExceptions.push(<number>ExceptionCode.engineerHasDifferentThanRequestServiceCenterException);
								this.updateActivityCore(activity, onSuccess);
								return;
							},
							() => {
								this.loadingActivities = false;
								return;
							}
						);


				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: x.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
			}
		});

	}

    private showGroupError(msg: string): void {
		let errors = (<RequestGroupDto[]>this.nodes).map(m => new MultipleError(m.requestId, ""));

		const dialogRef = this.dialogService.open({ content: MultipleOperationErrorResultComponent, width: '50%', height: '50%' });

		const multipleOperationErrorResultComponent = <MultipleOperationErrorResultComponent>dialogRef.content.instance;
		multipleOperationErrorResultComponent.errors = errors;
		multipleOperationErrorResultComponent.heightPercent = 53;
		multipleOperationErrorResultComponent.headerText = msg;
	}

    public activityRemoveHandler({ dataItem }: any) {
		this.activitiesService.remove(`${(<Activity>dataItem).activityId}`, 'Наряд удален', '').subscribe(x => {

			if (x.isSuccessful) {
				this.loadActivities(this.request.requestId);
				this.selectedActivities = [];
				this.selectedActivity = null;
			}
		});
	}

	onActivityChangeStatus(transition: ActivityWorkflowTransition) {
		this.loadActivities(this.request.requestId);
		this.loadRequest(this.request.requestId);
		this.onSelectedActivityChange();
		//this.loadRequest(this.request.requestId);
		// if (this.activityDevices) {
		// 	this.activityDevices.refreshDevicesList();
		// }
	}

	showActivityById(activityId: number) {

		if (this.appService.isMobileDevice) {
			this.showActivitiesGrid = false;
			this.showActivityDetails = true;
		}

		this.selectedActivities = [];
		let activity = this.activities.find(x => x.activityId == activityId);
		if (!this.statusIsCanceled(activity)){
			this.selectedActivities.push(activityId);
		}
		this.onSelectedActivityChange();
	}

	public statusIsCanceled(item: any): boolean {
		return item && item.statusId == <number>ActivityStatusEnum.cancelled;
	}
}