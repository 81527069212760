import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommentsComponent } from '../comments.component';
import { SelectedCommentsFilter } from '../../../models/request/comments/selected-comment-filter';
import { RequestsService } from '../../../services/requests.service';
import { NotificationType } from '../../../../core/services/notification-type';
import { NotificationService } from '../../../../core/services/notification.service';
import { saveAs } from '@progress/kendo-file-saver';
import { CommentKindEnum } from '../../../enums/comment-kind.enum';
import { SecurityService } from '../../../../core/services/security.service';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { ColorSchemeSettings } from '../../../models/color-scheme-settings/color-scheme-settings';
import { ColorSchemeSettingsService } from '../../../services/color-scheme-settings.service';
import { CommentKind } from '../../../models/request/comments/comment-kind';

@Component({
	selector: 'comments-block',
	templateUrl: './comments-block.component.html',
	styleUrls: [
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./comments-block.component.scss'
	]
})
export class CommentsBlockComponent implements OnInit {

	@ViewChild('comments') public comments: CommentsComponent;

	@Input()
	blockHeight: number = 650;

	@Input()
	requestId: number;

	@Input()
	activityId?: number;

	@Input()
	sendButtonDisabled: boolean;

	@Input()
	stoppingReasons: KeyValueObject[] = [];

	@Input()
	notifyEngineerEmailSwitchDisabled: boolean = false;

	@Input()
	activityPerformerId: number;

	@Input()
	isMobile = false;

	@Input()
	includeAllRequestComments = false;

	@Output()
	onCommentAdded: EventEmitter<any> = new EventEmitter();

	selectedCommentsFilter: SelectedCommentsFilter = { selectedTab: "all", showSystemComments: true };

	excelLoading: boolean;
	hover: boolean;

	commentKinds: CommentKind[] = [];

	constructor(private requestsService: RequestsService,
				private securityService: SecurityService,
				private notificationService: NotificationService) {
		
	}

	ngOnInit(): void {

	}

	loadComments(scrollToBottomSpeed: number, activityPerformerId: number) {
		this.comments.loadComments(scrollToBottomSpeed, activityPerformerId);
	}

	toggleShowSystemCommentsClick(){
		this.selectedCommentsFilter.showSystemComments = !this.selectedCommentsFilter.showSystemComments;
	}

	isTabSelected(tabName: string): boolean {
		return this.selectedCommentsFilter && this.selectedCommentsFilter.selectedTab == tabName;
	}

	exportToExcel(){

		this.excelLoading = true;

        this.requestsService.getCommentsExcel(this.requestId, this.activityId, this.isMobile).subscribe(x => {

            if (x.isSuccessful) {
                const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${x.data.fileData}`;
                saveAs(dataURI, x.data.fileName);
            } else {
                this.notificationService.error({
                    title: 'Ошибка при выгрузке комментариев',
                    message: x.errorDescription,
                    notificationType: NotificationType.Toast
                });
            }

            this.excelLoading = false;
        });
		
		
	}

	commentsFilterChanged(tabName: string) {
		this.selectedCommentsFilter.selectedTab = tabName;
		setTimeout(() => {
			this.comments.scrollToBottom(300);
		}, 0);
	}

	get canViewGeneralCommentkind(): boolean {
		return this.securityService.hasClaim(`comment-kind-${<number>CommentKindEnum.general}-view`);
	}

	get canViewInternalCommentkind(): boolean {
		return this.securityService.hasClaim(`comment-kind-${<number>CommentKindEnum.internal}-view`);
	}

	get canViewSystemCommentkind(): boolean {
		return this.securityService.hasClaim(`comment-kind-${<number>CommentKindEnum.system}-view`);
	}

	onCommendAddedEvent(event: any){
		this.onCommentAdded.emit();
	}

	get elementHeight(): number {
		let height = this.blockHeight ? this.blockHeight - 100 : 550;
		return height;
	}
}