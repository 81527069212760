import { Component, Input, OnInit } from "@angular/core";
import { RequestsService } from "../../../services/requests.service";
import { RequestChangeLog } from "../../../models/request/request-change-log";
import { State } from "@progress/kendo-data-query";

@Component({
	selector: 'request-page-change-log',
	templateUrl: './request-page-change-log.component.html',
	styleUrls: ['./request-page-change-log.component.scss']
})
export class RequestPageChangeLogComponent implements OnInit {
    
    @Input() requestId: number;

	public gridState: State = { sort: [{ field: 'changeDate', dir: 'desc' }] };
    public gridHeight: number = window.innerHeight - 260;

    changeLog: RequestChangeLog[] = [];

    loading: boolean = false;

    constructor (
        private requetsService: RequestsService
    ) { }
    
    ngOnInit(): void {
        this.loading = true;
        this.requetsService.getChangeLog(this.requestId).subscribe(resp => {
            this.changeLog = resp;
            this.loading = false;
        });
    }
}