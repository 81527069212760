import { Component, Input, OnInit } from "@angular/core";
import { CustomerObjectsService } from "../../../../../../categories/customer-objects/customer-objects.service";
import { CustomerObjectRelatedRequestDto } from "../../../../../models/customerObject/customer-object-related-request-dto";
import { PerfectScrollbarComponent } from "ngx-perfect-scrollbar";
import { Router } from "@angular/router";

@Component({
	selector: 'request-page-customer-object-requests',
	templateUrl: './request-page-customer-object-requests.component.html',
	styleUrls: ['./request-page-customer-object-requests.component.scss',
		'../../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class RequestPageCustomerObjectRequestsComponent implements OnInit {
	
	@Input() requestId: number;

	relatedRequests: CustomerObjectRelatedRequestDto[] = [];

	loading: boolean = false;

	constructor (
		private customerObjectsService: CustomerObjectsService
	) {}

	ngOnInit(): void {
		this.initData();
	}

	initData() {
		this.loading = true;
		this.customerObjectsService.getCustomerObjectRelatedRequests(null, this.requestId).subscribe(resp => {
			this.relatedRequests = resp.filter(x => x.requestId != this.requestId);
			this.loading = false;
		});
	}
	
    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

	cellClick({ dataItem }: any) {
		window.open(`/requests/${dataItem.requestId}`, '_blank');
	}
}
