import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { LookupService } from "../../../../shared/services/lookup.service";
import { forkJoin } from "rxjs";
import { KeyValueObject } from "../../../../shared/models/core/KeyValueObject";
import { NewRequestService } from "../../../../shared/models/request/new-request/new-request-service";
import { CategoryServicesService } from "../../../../shared/services/category-services.service";
import { ServiceItem } from "../../../../shared/models/services/service-item";
import { GridComponent } from "@progress/kendo-angular-grid";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { NewRequestDevice } from "../../../../shared/models/request/new-request/new-request-device";
import { HierarchyLookupModel } from "../../../../shared/models/core/HierarchyLookupModel";
import { DeviceModelsService } from "../../../../shared/services/device-models.service";
import { DeviceModelDto } from "../../../../shared/models/device/device-model-dto";

@Component({
	selector: 'new-request-services-and-devices',
	templateUrl: './new-request-services-and-devices.component.html',
	styleUrls: ['./new-request-services-and-devices.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class NewRequestServicesAndDevicesComponent implements OnInit {

    @ViewChild("servicesGrid") servicesGrid: GridComponent;
    @ViewChild("requestDevicesGrid") requestDevicesGrid: GridComponent;

    @Input() disabled: boolean;
    
    @Output() saveRequestServices: EventEmitter<NewRequestService[]> = new EventEmitter();
    @Output() saveRequestDevices: EventEmitter<NewRequestDevice[]> = new EventEmitter();

    private defaultServiceTypeId = 2;
    private defailtServiceCategoryId = 4;
	dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    fullServices: ServiceItem[] = [];
    services: KeyValueObject[] = [];
    serviceTypes: KeyValueObject[] = [];
    serviceCategories: KeyValueObject[] = [];

    deviceTypes: KeyValueObject[] = [];
    deviceModels: DeviceModelDto[] = [];
    dropDownDeviceModels: DeviceModelDto[] = [];
    deviceSuppliers: DeviceModelDto[] = [];
    dropDownDeviceSuppliers: DeviceModelDto[] = [];

	requestServices: NewRequestService[] = [];
    requestDevices: NewRequestDevice[] = [];

    constructor(
        public categoryServicesService: CategoryServicesService,
        public deviceModelsService: DeviceModelsService,
        public lookupService: LookupService
    ) { }

    ngOnInit(): void {
        let fullServices$ = this.categoryServicesService.list();
        let serviceTypes$ = this.lookupService.getData('service-types', null);
        let serviceCategories$ = this.lookupService.getData('service-categories', null);
        let deviceTypes$ = this.lookupService.getData('device-types', null);
		let deviceModels$ = this.deviceModelsService.getAll();

        forkJoin(fullServices$, serviceTypes$, serviceCategories$, deviceTypes$,  deviceModels$)
            .subscribe(([fullServices, serviceTypes, serviceCategories, deviceTypes, deviceModels]) => {
                this.services = fullServices.data.map(x => new KeyValueObject(x.id, x.name));
                this.fullServices = fullServices.data;
                this.serviceTypes = serviceTypes;
                this.serviceCategories = serviceCategories;

                this.deviceTypes = deviceTypes;
                this.deviceModels = deviceModels;
                this.dropDownDeviceModels = [];
                this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null);
                this.dropDownDeviceSuppliers = [];
            });
    }

    changeRequestTypeAndCategory(requestTypeId: number, requestCategoryId: number) {
        if (requestTypeId == null || requestTypeId == 0 || 
            requestCategoryId == null || requestCategoryId == 0) {
            this.requestServices = [];
            return;
        }

        this.services = this.fullServices
            .filter(x => x.serviceRequestTypes.some(srt => 
                srt.requestTypeId == requestTypeId && 
                srt.requestCategoryId == requestCategoryId))
            .map(srt => new KeyValueObject(srt.id, srt.name));

        this.requestServices = this.fullServices
            .filter(x => x.serviceRequestTypes.some(srt => 
                srt.requestTypeId == requestTypeId && 
                srt.requestCategoryId == requestCategoryId && 
                srt.isDefault == true))
            .map(x => {
                return {
                    serviceId: x.id,
                    serviceName: x.name,
                    serviceTypeId: this.defaultServiceTypeId,
                    serviceCategoryId: this.defailtServiceCategoryId,
                    isDefault: true
                } as NewRequestService;
            });

        this.saveRequestServices.emit(this.requestServices);
    }

    getNameById(items: KeyValueObject[] = [], id: number): string {
        return items.some(x => x.id == id)
            ? items.find(x => x.id == id).name
            : '';
    }

    refreshRequestServices() {
        this.saveRequestServices.emit(this.requestServices);
    }

    createNewService(): NewRequestService {
        let service = { 
            serviceTypeId: this.defaultServiceTypeId,
            serviceCategoryId: this.defailtServiceCategoryId
        } as NewRequestService;

        return service;
    }

    addNewService() {
		this.servicesGrid.addRow(this.createNewService());
	}

    refreshRequestDevices() {
        this.saveRequestDevices.emit(this.requestDevices);
    }

    createNewRequestDevice(): NewRequestDevice {
        let device = new NewRequestDevice();
        return device;
    }

    addNewRequestDevice() {
        this.requestDevicesGrid.addRow(this.createNewRequestDevice())
    }

    public deviceTypeChange(deviceTypeId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
            requestDevice.deviceSupplierId = null;
        }
    }

    public deviceSupplierChange(supplierId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
        }
    }

    public handleDeviceModelsDropdownOpen(deviceSupplierId: number) {
		this.dropDownDeviceModels = this.deviceModels != null && deviceSupplierId
            ? this.deviceModels.filter(x => x.parentId === deviceSupplierId)
            : [];
	}

    public handleDeviceSuppliersDropdownOpen(deviceTypeId: number) {
        this.dropDownDeviceSuppliers = this.deviceModels != null && deviceTypeId
            ? this.deviceSuppliers.filter(x => x.typeId == deviceTypeId)
            : [];
    }

    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

    get gridHeight(): number {
        return this.requestServices.length * 50 + 150;
    }
}
