import { Component, OnInit, ViewChild } from '@angular/core';
import { NewRequest } from '../../shared/models/request/new-request/new-request';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../core/services/notification.service';
import { AppService } from '../../app.service';
import { ComponentPageBase } from '../../shared/components/component-page-base/component-page-base';
import { NewRequestsService } from '../../shared/services/new-requests.service';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { EntityViewModel } from '../../shared/models/core/EntityViewModel';
import { CustomerObjectsService } from '../../categories/customer-objects/customer-objects.service';
import { DialogService } from '@progress/kendo-angular-dialog';import { ContragentWithContactDto } from '../../shared/models/contragent/contragent-with-contact-dto';
import { CustomerObjectWithContactsDto } from '../../shared/models/customerObject/customer-object-with-contacts.dto';
import { NewRequestCustomerObjectInfoComponent } from './sub-components/customer-object-info/new-request-customer-object-info.component';
import { NewRequestServicesAndDevicesComponent } from './sub-components/services-and-devices/new-request-services-and-devices.component';
import { NewRequestService } from '../../shared/models/request/new-request/new-request-service';
import { NewRequestDevice } from '../../shared/models/request/new-request/new-request-device';

@Component({
	selector: 'new-request-page',
	templateUrl: './new-request.page.html',
	styleUrls: [
		'./new-request.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class NewRequestPage extends ComponentPageBase<NewRequest> implements OnInit {
	
	@ViewChild("customerObjectInfo") customerObjectInfoBlock: NewRequestCustomerObjectInfoComponent;
	@ViewChild("serviceAndDevices") servicesAndDevicesBlock: NewRequestServicesAndDevicesComponent;

	customerObjects: KeyValueObject[] = [];

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: NewRequestsService,
		private customerObjectsService: CustomerObjectsService,
		protected dialogService: DialogService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'requests';
		this.appService.pageTitle = 'Новая заявка';
	}

	ngOnInit() {
		this.canEditForm = true;
		this.isNew = true;
		this.entityViewModel = new EntityViewModel<NewRequest>();
		this.entityViewModel.entity = new NewRequest();
		this.entityViewModel.entity.warehouseId = 0;
	}

	afterSaveChanges() {
		if (!this.entityId)
			return;

		this.router.navigate(['/requests/', this.entityId]);
	}

	saveContragent(contragent: ContragentWithContactDto) {
		if (contragent == null) {
			this.customerObjectInfoBlock.customerObjectChange(null);
			this.entityViewModel.entity.customerContragentId = null;
		}
		else {
			if (this.entityViewModel.entity.customerContragentId != contragent.id) {
				this.customerObjectInfoBlock.customerObjectChange(null);
			}
			this.entityViewModel.entity.customerContragentId = contragent.id;
			this.loadCustomerObjects();
		}
	}

	saveCustomerObject(customerObject: CustomerObjectWithContactsDto) {
		this.customerObjectsService.getCustomerObjectsByContragent(this.entityViewModel.entity.customerContragentId)
			.subscribe((resp) => {
				this.customerObjects = resp;
				this.entityViewModel.entity.customerObjectId = customerObject.customerObjectId;
			});		
	}

	loadCustomerObjects() {
		this.customerObjectsService.getCustomerObjectsByContragent(this.entityViewModel.entity.customerContragentId)
			.subscribe((resp) => {
				this.customerObjects = resp;
			});
	}

	changeRequestTypeId(requestTypeId: number) {
		this.entityViewModel.entity.requestTypeId = requestTypeId;
		this.notifyChangeRequestGeneralData();
	}

	changeRequestCategoryId(requestCategoryId: number) {
		this.entityViewModel.entity.requestCategoryId = requestCategoryId;
		this.notifyChangeRequestGeneralData();
	}

	notifyChangeRequestGeneralData() {
		this.servicesAndDevicesBlock.changeRequestTypeAndCategory(
			this.entityViewModel.entity.requestTypeId, this.entityViewModel.entity.requestCategoryId);
	}

	changeRequestDescription(requestDescription: string) {
		this.entityViewModel.entity.description = requestDescription;
	}

	saveRequestServices(requestServices: NewRequestService[] = []) {
		this.entityViewModel.entity.requestServices = requestServices;
	}

	saveRequestDevices(requestDevices: NewRequestDevice[] = []) {
		this.entityViewModel.entity.requestDevices = requestDevices;
	}

	get editRequestServicesDisabled(): boolean {
		return this.entityViewModel.entity.requestTypeId == null 
			|| this.entityViewModel.entity.requestTypeId == 0 
			|| this.entityViewModel.entity.requestCategoryId == null
			|| this.entityViewModel.entity.requestCategoryId == 0;
	}

	get createRequestAvailable(): boolean {
		return this.entityViewModel.entity.customerContragentId != null &&
			this.descriptionIsValid;
	}

	get descriptionIsValid(): boolean {
		return this.entityViewModel.entity.description != null &&
			this.entityViewModel.entity.description.trim() != '';
	}
}
