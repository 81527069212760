import { Component, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { ContragentsService } from '../contragents.service';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { GridSettings } from '../../../shared/models/grid/grid-settings';
import {ContragentListItem} from "../../../shared/models/contragent/contragent-list-item";
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';

@Component({
	selector: 'categories-contragents-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ContragentsListPage extends ListPageBase<ContragentListItem> {

	public gridSettingsFilter: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'name', dir: 'asc' }],
			filter: { logic: 'and', filters: [] },
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'name',
				title: 'Наименование',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'contragentTypeName',
				title: 'Тип',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'integraCode',
				title: 'Интегра код',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'legalName',
				title: 'Юр. наименование',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'inn',
				title: 'ИНН',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'legalAddress',
				title: 'Юр. Адрес',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'contactFullName',
				title: 'ФИО контакта',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'contactPhoneNumber',
				title: 'Телефон контакта',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'contactEmail',
				title: 'Email контакта',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			}
		]
	};

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'name', dir: 'asc' }],
			filter: { logic: 'and', filters: [] },
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'name',
				title: 'Наименование',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'contragentTypeName',
				title: 'Тип',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'integraCode',
				title: 'Интегра код',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'legalName',
				title: 'Юр. наименование',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'inn',
				title: 'ИНН',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'legalAddress',
				title: 'Юр. Адрес',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'contactFullName',
				title: 'ФИО контакта',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'contactPhoneNumber',
				title: 'Телефон контакта',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'contactEmail',
				title: 'Email контакта',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			}
		]
	};

	contragentTypes: KeyValueObject[] = [];

	constructor(
		appService: AppService,
		router: Router,
		protected dataService: ContragentsService,
		protected persistingService: StatePersistingService,
		private contragentService: ContragentsService,
		private lookupService: LookupService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Контрагенты';
	}

	onListPageInit(): void {
		this.lookupService.getData("contragentTypes", null).subscribe(data => this.contragentTypes = data);
	}

	processListItems(items: ContragentListItem[]): void { }

	public cellClick({ dataItem }: any): void {
		window.open(`/categories/contragent/${dataItem.contragentId}`, '_blank');
	}
}
