import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { ServiceItem } from "../../../shared/models/services/service-item";
import { CategoryServicesService } from "../../../shared/services/category-services.service";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";
import { StatePersistingService } from "../../../shared/services/state-persisting.service";
import { GridSettings } from "../../../shared/models/grid/grid-settings";

@Component({
	selector: 'services-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class ServicesListPage extends ListPageBase<ServiceItem> {

	constructor(
		protected appService: AppService,
		protected router: Router,
		protected dataService: CategoryServicesService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
	    appService.pageTitle = 'Услуги';
  	}

	public gridSettingsFilter: GridSettings = {
		state: {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: [{ field: 'name', dir: 'desc' }],
			filter: { logic: 'and', filters: [] },
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'name',
				title: 'Наименование',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'number',
				title: 'Код услуги',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'rate',
				title: 'Тариф',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'serviceCode',
				title: 'Интегра код',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'requestTypeNames',
				title: 'Тип заявок',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'description',
				title: 'Описание',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},			
			{
				orderIndex: 6,
				field: 'cost',
				title: 'Стоимость',
				filter: 'string',
				filterable: true,
				width: 150,
				hidden: false
			},
		]
	}

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: [{ field: 'name', dir: 'desc' }],
			filter: { logic: 'and', filters: [] },
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'name',
				title: 'Наименование',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'number',
				title: 'Код услуги',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'rate',
				title: 'Тариф',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'serviceCode',
				title: 'Интегра код',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'requestTypeNames',
				title: 'Тип заявок',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'description',
				title: 'Описание',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},			
			{
				orderIndex: 6,
				field: 'cost',
				title: 'Стоимость',
				filter: 'string',
				filterable: true,
				width: 150,
				hidden: false
			},
		]
	}

	onListPageInit(): void {
	}

	processListItems(items: ServiceItem[]): void {

	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/categories/service', dataItem.id]);
	}

}
