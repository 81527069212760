import { Injectable } from "@angular/core";
import { DataService } from "../../core/services/data.service";
import { KeyValueObject } from "../models/core/KeyValueObject";
import { NotificationService } from "../../core/services/notification.service";
import { HttpClient } from "@angular/common/http";
import { RequestCategotyWithTypesModel } from "../models/request/request-categoty-with-types-model";
import { Observable } from "rxjs";

@Injectable()
export class RequestCategoryService extends DataService<KeyValueObject, KeyValueObject> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'request-categories';
	}

	public getRequestCategoriesWithTypes(): Observable<RequestCategotyWithTypesModel[]> {
		const url = `${this.baseUrl()}/with-types`;
		return this.getQuery<RequestCategotyWithTypesModel[]>(url);
	}
}