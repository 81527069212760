import { RequestCommonData } from "./request-common-data";
import { WorkflowTransition } from "./workflow-transition";

export class RequestBwt {
    requestId: number;
    internalNumber: string;
    requestTypeId: number;
    requestTypeName: string;
    requestStatusId: number;
    requestStatusName: string;
    requestCategoryId: number;
    requestCategoryName: string;
    performerUserId: number;
    performerUserName: string;
    performerUserGroupId: number;
    performerUserGroupName: string;
    createdDate: Date;
    finishDate?: Date | null;
    customerContragentId: number;
    customerContragentName: string;
    customerObjectAddress: string;
    customerObjectName: string
    customerObjectId: number;
    description: string;
    workflowTransitions: WorkflowTransition[] = [];
    utcTimezoneShiftString: string;
    utcTimezoneShift: number;
    initTypeId: number;
    lastActivityId: number;
    commonData: RequestCommonData;
    partnerStatusAlias: string;
    serviceCenterId: number;
    isInGroupExt: boolean;
    isGroup: boolean;
    integraCode: string;
    defaultScWarehouseName: string;
    defaultScWarehouseId: number;
    isOneRoleSC: boolean;
    paymentStatusId: number;
    paymentStatusName: string;
    paymentStatusColor: string;
}