import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { RequestBwt } from "../../../models/request/request-bwt";
import { CustomerObjectWithContactsDto } from "../../../models/customerObject/customer-object-with-contacts.dto";
import { CustomerObjectsService } from "../../../../categories/customer-objects/customer-objects.service";
import { RequestPageCustomerObjectRequestsComponent } from "./sub-components/request-page-customer-object-requests/request-page-customer-object-requests.component";
import { RequestPageCustomerObjectContactsComponent } from "./sub-components/request-page-customer-object-contacts/request-page-customer-object-contacts.component";
import { RequestPageCustomerObjectDevicesComponent } from "./sub-components/request-page-customer-object-devices/request-page-customer-object-devices.component";

@Component({
	selector: 'request-page-customer-object',
	templateUrl: './request-page-customer-object.component.html',
	styleUrls: ['./request-page-customer-object.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class RequestPageCustomerObjectComponent {

    @ViewChild("customerObjectRequests") customerObjectRequestsComponent: RequestPageCustomerObjectRequestsComponent;
    @ViewChild("customerObjectContacts") customerObjectContactsComponent: RequestPageCustomerObjectContactsComponent;
    @ViewChild("customerObjectDevices") customerObjectDevicesComponent: RequestPageCustomerObjectDevicesComponent;
    
    @Input() request: RequestBwt;

    @Output() refreshRequestEvent: EventEmitter<RequestBwt> = new EventEmitter<RequestBwt>();

    refreshRequest(request: RequestBwt) {
        this.request = request;
        this.refreshAllData();
        this.refreshRequestEvent.emit(request);
    }

    refreshAllData() {
        this.customerObjectContactsComponent.customerObjectId = this.request.customerObjectId;
        this.customerObjectDevicesComponent.customerObjectId = this.request.customerObjectId;
        this.customerObjectRequestsComponent.initData();
        this.customerObjectContactsComponent.initData();
        this.customerObjectDevicesComponent.initData();
    }
}