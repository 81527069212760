import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KeyValueObject } from '../models/core/KeyValueObject';
import { UserGroup } from '../models/user-group/user-group';
import { UserGroupPerformer } from '../models/user-group/user-group-performer';
import { EngineerLookupModel } from '../models/engineers/engineer-lookup-model';

@Injectable()
export class UserGroupsService extends DataService<UserGroup, UserGroup> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'user-groups';
	}

	public getUsersByGroup(userGroupId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${userGroupId}/users`);
	}

	public getPerformerUserByGroup(userGroupId: number): Observable<UserGroupPerformer> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${userGroupId}/performer-user`);
	}

	public getAll(requestId: number = null): Observable<UserGroup[]> {
		let requestIdStr = '';
		if (!!requestId) {
			requestIdStr = `?requestId=${requestId.toString()}`;
		}
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-all${requestIdStr}`);
	}

	public getContragentIdByUserGroup(userGroupId: number): Observable<number> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${userGroupId}/contragent-id`);
	}

	public getUsersByActivityTypeId(activityTypeId: number): Observable<EngineerLookupModel[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${activityTypeId}/activity-type-users`)
	}
}
