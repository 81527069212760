import { SecurityService } from './../../core/services/security.service';
import { Component, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { ListPageBase } from '../../shared/components/list-page-base/list-page-base';
import { LookupService } from '../../shared/services/lookup.service';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { CompositeFilterDescriptor, DataSourceRequestState } from '@progress/kendo-data-query';
import { from, fromEvent, interval, Subject } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, tap, filter, takeUntil } from 'rxjs/operators';
import { StatePersistingService } from '../../shared/services/state-persisting.service';
import { GridSettings } from '../../shared/models/grid/grid-settings';
import { RequestStatus, RequestType } from '../../shared/enums';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { MultipleOperationsComponent } from '../../shared/components/multiple-operations/multiple-operations.component';
import { NotificationService } from '../../core/services/notification.service';
import { NotificationType } from '../../core/services/notification-type';
import { CheckboxColumnComponent, ColumnBase, ColumnComponent, ColumnVisibilityChangeEvent, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { UserFiltersService } from '../../shared/services/user-filters.service';
import { UserFilter } from '../../shared/models/user-filter/user-filter';
import { NgbModal, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { RequestNotificationTypeService } from '../../shared/services/request-notification-type.service';
import { RequestNotificationType } from '../../shared/models/request/request-notification-type';
import { MapYaDataService } from '../../shared/services/map-ya-data.service';

import { environment } from '../../../environments/environment';
import { ColumnSettings } from '../../shared/models/grid/column-settings';
import { MultipleOperationErrorResultComponent } from '../../shared/components/multiple-operations/multiple-operation-error-result/multiple-operation-error-result.component';
import { UserGroupsService } from '../../shared/services/user-groups.service';
import { MultipleError } from '../../shared/models/request/multiple-error';
import { SignalRNotificationsService } from '../../shared/services/signalr-notifications.service';
import { LoadFinancialReconciliationWarningComponent } from '../../shared/components/financial-reconciliation-warning/financial-reconciliation-warning';
import { UserFilterType } from '../../shared/enums/user-filter-type.enum';
import { ContragentsService } from '../../categories/contragents/contragents.service';
import {UsersService} from "../../admin/users/users.service";
import {InitTypesService} from "../../shared/services/init-types.service";
import {RequestStatusesService} from "../../shared/services/request-statuses.service";
import {RequestStatusMspAliasGroupsService} from "../../shared/services/request-status-msp-alias-groups.service";
import {ActivityStatusesService} from "../../shared/services/activity-statuses.service";
import {RequestAttributesService} from "../../shared/services/request-attributes.service";
import {RequestPrioritiesService} from "../../shared/services/request-priorities.service";
import {AttachmentTypesService} from "../../shared/services/attachment-types.service";
import {RegionsService} from "../../shared/services/regions.service";
import { RequestTypeService } from '../../shared/services/request-type.service';
import {MerchantsService} from "../../categories/merchants/merchants.service";
import {AgreementsService} from "../../shared/services/agreements.service";
import {CategoryServicesService} from "../../shared/services/category-services.service";
import {ServiceCategoriesService} from "../../shared/services/service-categories.service";
import {ServiceTypesService} from "../../shared/services/service-types.service";
import {ServiceModesService} from "../../shared/services/service-modes.service";
import {ServiceCentersService} from "../../shared/services/service-centers.service";
import { RolesService } from '../../admin/roles';
import { ExportExcelFromGridService } from '../../shared/services/export-excel-from-grid.service';
import { MapYaComponent } from '../../requests/map-ya/map-ya.component';
import { VeerouteCalendarComponent } from '../../requests/veeroute/veeroute-calendar.component';
import { WarehouseWorkplaceRequestsService } from '../../shared/services/warehouse-workplace-requests.service';
import { WarehouseWorkplaceRequestListItem } from '../../shared/models/warehouse-workplace-request/warehouse-workplace-request-list-item';
import { EquipmentMoveMode } from "../equipment-move-mode/equipment-move-mode";
import { EquipmentMoveModel } from '../equipment-move-mode/equipment-move.model';
import { Guid } from 'guid-typescript';
import { GetCellBySnFrom1CQuery } from '../../shared/models/for-1c/get-warehouse-and-celll-by-sn/get-cell-by-sn-from-1c-query';
import { For1CService } from '../../shared/services/for-1c.service';
import { DeviceFrom1C } from '../../shared/models/for-1c/responses/device-from-1c';
import { SetServiceCellBySnFrom1CQuery } from '../../shared/models/for-1c/set-service-warehouse-and-celll-by-sn/set-service-cell-by-sn-from-1c-query';
import { FinancialReconciliationReportResult } from '../../shared/models/request/financial-reconciliation-report-result';

@Component({
	selector: 'requests',
	templateUrl: './list.page.html',
	styleUrls: [
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class WarehouseWorkplaceRequestsListPage extends ListPageBase<WarehouseWorkplaceRequestListItem> implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('filterAllInput') filterAllInput: ElementRef;
	@ViewChild('userFiltersModal') userFiltersModal: any;
	@ViewChild('yaMap') yaMap: MapYaComponent;
	@ViewChild('fileInput') fileInput: any;
	@ViewChild("requestsGrid") requestsGrid: GridComponent;

	requestTypes: KeyValueObject[] = [];
	gridSelectedRequestIds: number[] = [];
	userFilters: UserFilter[] = [];
	notificationTypes: RequestNotificationType[] = [];

	reconciliations: KeyValueObject[] = new Array(new KeyValueObject(1, "Да"), new KeyValueObject(0, "Нет"));

	isMap: boolean = false;
	isMapSwitcherOn: boolean = false;
	isGroupClick: boolean = false;

	activeIdString: String = "searchTab";

	lastFilterText = '';
	public dataGenerating = false;
	public loadingFinancialReconciliation: boolean = false;
	public setDefaultFiltersIsInProgress: number[] = [];
	public removeFiltersIsInProgress: number[] = [];

	private _lastRefreshTime?: Date = null;

	private _destroy$ = new Subject<boolean>();

	searchItems: WarehouseWorkplaceRequestListItem[] = [];

	throttle = 300;
	scrollDistance = 1;
	selectedTab = 1;
	selectedRequestStatus = 12;
	selectedDate = 'today';
	selectedOrder = null;
	showTomorrow = false;
	showDays = [];
	public openedSearchModal = false;
	searchRequests: WarehouseWorkplaceRequestListItem[] = [];
	searchRequestsSize: number = 0;
	delaySearch = null;

	toggledAccordions: Map<number, boolean> = new Map();
	alwaysToggled = false;

	mobileDeviceVisibleColumnNames: string[] = [
		"requestIdStr",
		"createdDateLocal",
		"requestTypeName",
		"statusName",
		"performerUserGroupName",
		"customerContragentName",
		"tspLegalEntityName",
		"tidNumbers",
		"tspAddress",
		"slaDueDateLocal",
		"serviceCenterEngineerName",
		"serviceCenterName",
		"stoppedUntilLocal",
		"hoursToDeadline",
		"customerObjectLatitude",
		"customerObjectLongitude",
		"description",
		"tspAddress"
	];

	reconciliationDialog: DialogRef;

	constructor(
		private sanitizer: DomSanitizer,
		protected appService: AppService,
		router: Router,
		private modalService: NgbModal,
		protected dataService: WarehouseWorkplaceRequestsService,
		protected persistingService: StatePersistingService,
		private lookupService: LookupService,
		protected dialogService: DialogService,
		protected notificationService: NotificationService,
		protected exportExcelFromGridService: ExportExcelFromGridService,
		private userFiltersService: UserFiltersService,
		private securityService: SecurityService,
		public requestNotificationTypeService: RequestNotificationTypeService,
		private mapYaDataService: MapYaDataService,
		public userGroupsService: UserGroupsService,
		private signalRNotificationsService: SignalRNotificationsService,
		public usersDataService: UsersService,
		public initTypesService: InitTypesService,
		public requestStatusesService: RequestStatusesService,
		public requestStatusMspAliasGroupsService: RequestStatusMspAliasGroupsService,
		public activityStatusesService: ActivityStatusesService,
		public requestAttributesService: RequestAttributesService,
		public requestPrioritiesService: RequestPrioritiesService,
		public attachmentTypesService: AttachmentTypesService,
		public regionsService: RegionsService,
		public requestTypeDataService: RequestTypeService,
		public merchantsService: MerchantsService,
		public agreementsService: AgreementsService,
		public categoryServicesService: CategoryServicesService,
		public serviceCategoriesService: ServiceCategoriesService,
		public serviceTypesService: ServiceTypesService,
		public serviceModesService: ServiceModesService,
		public serviceCentersService: ServiceCentersService,
		protected contragentsDataService: ContragentsService,
		protected rolesDataService: RolesService,
		public warehouseWorkplaceRequestsService: WarehouseWorkplaceRequestsService,
		public for1CService: For1CService
	) {
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		this.groupedKey = 'createdDateGroupValue';
		appService.pageTitle = 'Рабочее место склада';
		this.alwaysToggled = localStorage.getItem('alwaysToggled') === 'true';
	}

	public loadData(state: DataSourceRequestState = this.gridSettings.state): void {
		this.loading = true;

		var visibleColumnNames = [];

		if (this.isMobileDevice) {
			visibleColumnNames = this.mobileDeviceVisibleColumnNames;
		}
		else {
			visibleColumnNames = this.gridSettings.columnsConfig.filter(x => !x.hidden).map(x => x.field);
		}

		this.dataService.requestList(state, visibleColumnNames).subscribe(lvm => {
			this.processListItems(lvm.data);
			this.listViewModel = lvm;
			this.groupedData = this.groupedByKey;
			this.loading = false;
		});
	}

	public getWarehouseAndCells(): void {

		if (this.gridSelectedRequestIds != null && this.gridSelectedRequestIds.length > 0) {
			this.loading = true;

			const selectedSerialNumbers = this.listViewModel.data
				.filter(x => this.gridSelectedRequestIds.includes(x.requestId)).map(x => x.serialNumber);

			var query = new GetCellBySnFrom1CQuery;
			query.httpQueryId = Guid.create().toString();
			query.serialNumbers = selectedSerialNumbers;

			this.for1CService.getWarehouseAndCellBySN(query).subscribe((result) => {
				if (result.data != null && result.data.devices != null && result.data.devices.length > 0) {
					this.listViewModel.data.filter(x => this.gridSelectedRequestIds.includes(x.requestId))
					.forEach((item) => {
						var device = result.data.devices.find(x => x.sn == item.serialNumber);
						if (device != null) {
							item.cell = device.cell;
							if (device.warehouse != null) item.warehouse = device.warehouse.name;
							if (device.warehouseArea != null) item.warehouseArea = device.warehouseArea.name;
						}
					});
				}
				else {
					this.notificationService.info({ 
						title: "",
						message: "ТО для выбранных заявок не может быть найдено в 1C",
						notificationType: NotificationType.Toast
					});
				}

				this.loading = false;
			});
		}
		else {
			this.notificationService.info({ 
				title: "",
				message: "Выберите хотя бы одну заявку",
				notificationType: NotificationType.Toast
			});
		}
	}

	public moveEquipment(): void {
		const dialog = this.dialogService.open({
			title: 'Режим перемещения оборудования',
			content: EquipmentMoveMode,
			width: 400
		});

		const equipmentMoveModeDialog = <EquipmentMoveMode>dialog.content.instance;
		equipmentMoveModeDialog.onContinueEvent.subscribe((moveModel: EquipmentMoveModel) => {
			this.loading = true;
			
			var query = new SetServiceCellBySnFrom1CQuery;
			query.httpQueryId = Guid.create().toString();
			query.devices = moveModel.serialNumbers.map(sn => {
				var device = new DeviceFrom1C;
				device.sn = sn;
				device.cell = moveModel.cellNumber;
				return device;
			});

			this.for1CService.setServiceWarehouseAndCellBySN(query).subscribe((resp) => {
				this.loading = false;
                if (resp.isSuccessful) {
                    this.notificationService.success({ 
                        title: "",
                        message: "Оборудование успешно перемещено",
                        notificationType: NotificationType.Toast
                    });
                }
			});
		});
	}

	tabChanged(e: NgbTabChangeEvent) {
		this.activeIdString = e.nextId;
	}

	public columnColor(statusColorCode: string, slaColorCode: string, isInRms: boolean): SafeStyle {
		if (slaColorCode) {
			return this.sanitizer.bypassSecurityTrustStyle(slaColorCode);
		}

		return this.sanitizer.bypassSecurityTrustStyle(statusColorCode);
	}

	public rowClass(context: RowClassArgs) {
		if (context.dataItem.hoursToDeadline) {

			let splitHours: number = 0;
			let hours: number = 0;
			let minutes: number = 0;
			let splitDays: number = 0;
			let days: number = 0;

			if (context.dataItem.hoursToDeadline.includes(".")) {
				splitDays = context.dataItem.hoursToDeadline.split(".");
				days = parseInt(splitDays[0]);
			} else {
				splitHours = context.dataItem.hoursToDeadline.split(":");
				hours = parseInt(splitHours[0]);
				minutes = parseInt(splitHours[1]);
			}

			if ((days < 0) || (hours < -3 || (hours === -3 && minutes > 0))) {
				return {
					'cursor-pointer': true,
					'red-text': true
				};
			} else {
				return {
					'cursor-pointer': true
				};
			}

		} else {
			return {
				'cursor-pointer': true
			};
		}
	}

	filterChange(): void {
		this.gridSelectedRequestIds = [];
		this.mapYaDataService.changeFilters(this.gridSettings.state);
	}

	public enableSaveGridSettings: boolean = true;

	gridSettingsStorageKey: string = 'warehouse-workplace-requests-grid-settings';

	public gridColumnOffset: number = 1;

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 25,
			sort: [{ field: 'requestIdStr', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'requestIdStr',
				title: '# заявки',
				filter: 'string',
				filterable: false,
				width: 150,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'warehouse',
				title: 'Склад',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'warehouseArea',
				title: 'Помещение',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'cell',
				title: 'Ячейка',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'createdDateLocal',
				title: 'Дата создания',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: false,
				width: 280,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'externalId',
				title: '# заявки заказчика',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false,
			},
			{
				orderIndex: 6,
				field: 'initTypeName',
				title: 'Источник',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'initTypeId'
			},
			{
				orderIndex: 7,
				field: 'requestTypeName',
				title: 'Тип заявки',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: false,
				filterField: 'requestTypeId'
			},
			{
				orderIndex: 8,
				field: 'statusName',
				title: 'Статус заявки',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: false,
				filterField: 'statusId'
			},
			{
				orderIndex: 9,
				field: 'partnerStatusAliasName',
				title: 'Состояние в ИС партнера',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'partnerStatusAliasGroupId'
			},
			{
				orderIndex: 10,
				field: 'performerUserGroupName',
				title: 'Группа ответственного',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: false,
				filterField: 'performerUserGroupId'
			},
			{
				orderIndex: 11,
				field: 'performerUserName',
				title: 'Ответственный',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'performerUserId'
			},
			{
				orderIndex: 12,
				field: 'authorUserGroupName',
				title: 'Группа автора',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'authorUserGroupId'
			},
			{
				orderIndex: 13,
				field: 'authorUserName',
				title: 'Автор',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'authorUserId'
			},
			{
				orderIndex: 14,
				field: 'customerContragentName',
				title: 'Клиент',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: false,
				filterField: 'customerContragentId'
			},
			{
				orderIndex: 15,
				field: 'tspName',
				title: 'Название ТСП',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'tspId'
			},
			{
				orderIndex: 16,
				field: 'requestCustomerObjectLegalName',
				title: 'Ю/Л в запросе',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 17,
				field: 'tspLegalEntityName',
				title: 'Ю/л ТСП',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: false,
				filterField: 'tspLegalEntityId'
			},
			{
				orderIndex: 18,
				field: 'tidNumbers',
				title: 'Tid',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 19,
				field: 'tspAddress',
				title: 'Адрес ТСП',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 20,
				field: 'description',
				title: 'Описание',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 21,
				field: 'sla',
				title: 'Количество часов SLA',
				filter: 'numeric',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 22,
				field: 'slaStartDateLocal',
				title: 'Дата начала SLA',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 23,
				field: 'slaDueDateLocal',
				title: 'Срок SLA',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 24,
				field: 'lastActivityStatusName',
				title: 'Статус последней активности',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'lastActivityStatusId'
			},
			{
				orderIndex: 25,
				field: 'serviceCenterEngineerName',
				title: 'Инженер (СЦ)',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: false,
				filterField: 'serviceCenterEngineerId'
			},
			{
				orderIndex: 26,
				field: 'finishDateLocal',
				title: 'Дата завершения',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 27,
				field: 'serviceCenterName',
				title: 'Исполнитель (СЦ)',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: false,
				filterField: 'serviceCenterId'
			},
			{
				orderIndex: 28,
				field: 'stoppedUntilLocal',
				title: 'Остановлено до',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 29,
				field: 'reasonForStopping',
				title: 'Причина остановки',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 30,
				field: 'priorityName',
				title: 'Приоритет',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'priorityId'
			},
			{
				orderIndex: 31,
				field: 'hoursToDeadline',
				title: 'Осталось часов',
				filter: 'date',
				filterable: false,
				width: 180,
				hidden: false
			},
			{
				orderIndex: 32,
				field: 'stateUpdateDateLocal',
				title: 'Дата изменения состояния',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 33,
				field: 'customerObjectDistrictName',
				title: 'Район ТСП',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 34,
				field: 'attributeString',
				title: 'Тип в БЧ',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'requestAttributeId'
			},
			{
				orderIndex: 35,
				field: 'spentHours',
				title: 'Потрачено времени',
				filter: 'date',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 36,
				field: 'lastCriticalNotificationPriorityId',
				title: 'Последнее критичное уведомление',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'lastCriticalNotificationTypeId'
			},
			{
				orderIndex: 37,
				field: 'unreadNotificationMaxPriorityId',
				title: 'Уведомления',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'requestNotificationsCustomFilter'
			},
			{
				orderIndex: 38,
				field: 'endOfSlaDateLocal',
				title: 'Дата окончания работ',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 39,
				field: 'acquirerName',
				title: 'Банк-эквайер',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'acquirerId'
			},
			{
				orderIndex: 40,
				field: 'externalProjectName',
				title: 'Проект',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 41,
				field: 'reconciliationCompletedString',
				title: 'Фин. сверка',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'reconciliationCompleted'
			},
			{
				orderIndex: 42,
				field: 'customerCreatedDate',
				title: 'Дата регистрации у заказчика',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 43,
				field: 'childCount',
				title: 'Группа из',
				filter: 'numeric',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 44,
				field: 'parentExternalId',
				title: '№ групповой заявки',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 45,
				field: 'finishDateFactLocal',
				title: 'Дата фактического завершения работ (по талону)',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 46,
				field: 'attachmentTypesString',
				title: 'Вложения',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'attachmentTypesCustomFilter'
			},
			{
				orderIndex: 47,
				field: 'merchantRegionName',
				title: 'Регион ТСП',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'merchantRegionId'
			},
			{
				orderIndex: 48,
				field: 'lastRequestComment',
				title: 'Текст последнего комментария в заявке',
				filter: 'string',
				filterable: false,
				width: 400,
				hidden: true
			},
			{
				orderIndex: 49,
				field: 'lastActivityComment',
				title: 'Текст последнего комментария в активности',
				filter: 'string',
				filterable: false,
				width: 400,
				hidden: true
			},
			{
				orderIndex: 50,
				field: 'lastCustomerComment',
				title: 'Текст последнего комментария от клиента',
				filter: 'string',
				filterable: false,
				width: 400,
				hidden: true
			},
			{
				orderIndex: 51,
				field: 'agreementNumber',
				title: 'Договор',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'agreementId'
			},
			{
				orderIndex: 52,
				field: 'distanceFromCityKm',
				title: 'Удаленность по договору, км',
				filter: 'numeric',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'distanceFromCityKm'
			},
			{
				orderIndex: 53,
				field: 'customerObjectDistanceFromCityKm',
				title: 'Фактическая удаленность, км',
				filter: 'numeric',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'customerObjectDistanceFromCityKm'
			},
			{
				orderIndex: 54,
				field: 'serviceNames',
				title: 'Услуги',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'serviceId'
			},
			{
				orderIndex: 55,
				field: 'serviceCategoryNames',
				title: 'Категории услуг',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'serviceCategoryId'
			},
			{
				orderIndex: 56,
				field: 'serviceTypeNames',
				title: 'Типы сервисных услуг',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'serviceTypeId'
			},
			{
				orderIndex: 57,
				field: 'serviceModeNames',
				title: 'Режимы сервисных услуг',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'serviceModeId'
			},
			{
				orderIndex: 58,
				field: 'serviceIsDoneNames',
				title: 'Сервисные услуги выполнены',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: true,
				filterField: 'serviceIsDone'
			},
			{
				orderIndex: 59,
				field: 'deliveryStatus',
				title: 'Статус доставки',
				filter: 'lookup',
				filterable: false,
				width: 300,
				hidden: false,
				filterField: 'deliveryStatusId'
			},
			{
				orderIndex: 60,
				field: 'engineerRolesNames',
				title: 'Роль инженера',
				filter: 'lookup',
				filterable: false,
				width: 300,
				filterField: 'engineerRolesCustomFilter',
				hidden: true,
			},
			{
				orderIndex: 61,
				field: 'deviceTypeName',
				title: 'Тип устройства',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 62,
				field: 'deviceConnectionTypeName',
				title: 'Тип соединения устройства',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 63,
				field: 'deviceSupplierName',
				title: 'Производитель устройства',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 64,
				field: 'deviceModelName',
				title: 'Модель устройства',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 65,
				field: 'isPinPadRequired',
				title: 'Требуется Pinpad',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 66,
				field: 'serialNumber',
				title: 'С/Н в запросе',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 67,
				field: 'deviceServiceTypeName',
				title: 'Сегмент',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 68,
				field: 'nomenclature',
				title: 'Номенклатура для резерва',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 69,
				field: 'requestedModel',
				title: 'Наименование в запросе',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 70,
				field: 'reserveResult',
				title: 'Статус резерва',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 71,
				field: 'warehouseAreaName',
				title: 'Склад, ячейка',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 72,
				field: 'contactNamesTsp',
				title: 'Имя контакта ТСП',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 73,
				field: 'contactPhonesTsp',
				title: 'Телефон контакта ТСП',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 74,
				field: 'addressTsp',
				title: 'Адрес ТСП',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			}
		]
	};

	protected gridColumnRightProcess(settings: GridSettings) {
		if (settings != undefined) {
			if (this.isCostVisible) {
				if (!settings.columnsConfig.some(el => el.filterField === 'startPrice')) {
					var columnsConfig: ColumnSettings = {
						orderIndex: 56,
						field: 'startPrice',
						title: 'Стоимость',
						filter: 'numeric',
						filterable: false,
						width: 300,
						hidden: false,
						filterField: 'startPrice'
					};
					settings.columnsConfig.push(columnsConfig);
				}
			}
			else {
				settings.columnsConfig = settings.columnsConfig.filter(item => item.filterField !== "startPrice");
			}
		}
	}
	onListPageInit(): void {

		if (this.isMobileDevice)
			return;
		this.lookupService.getData("request-types", null).subscribe(data => this.requestTypes = data);
		this.gridColumnRightProcess(this.gridSettings);

		this.requestNotificationTypeService.getTypes().subscribe(data => this.notificationTypes = data);

		this.loadUserFilters();
	}

	loadUserFilters() {

		this.loading = true;

		this.setLocalFilter();
	}

	clearFilters(): void {
		this.requestsGrid.filterable = true;

		this.gridSettings.state = {
			skip: 0,
			take: 25,
			sort: [{ field: 'requestIdStr', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		};

		if (this.enableSaveGridSettings) {
			this.saveGridSettings();
		}

		this.appService.clearAllGridFilters.next();

		this.onGridStateChange();

		this.loadData();
	}

	public ngOnInit(): void {

		this.gridColumnRightProcess(this.gridSettings);
		const gridSettings: GridSettings = this.persistingService.get(this.gridSettingsStorageKey);
		this.gridColumnRightProcess(gridSettings);
		this.setGridSettings(gridSettings);

		if (this.isMobileDevice) {
			this.gridSettings.state.filter.filters = [this.getStatusKindFilter(this.selectedDate)];
			this.gridSettings.state.sort = [{ field: 'requestIdStr', dir: 'desc' }];
		}

		this.onListPageInit();

		this.signalRNotificationsService.context = this;
		this.signalRNotificationsService.onFinancialReconciliationCompleted = this.onLoadFinancialReconciliationCompleted;
		this.signalRNotificationsService.onSignalRConnected = this.onSignalRConnected;
	}

	ngAfterViewInit(): void {

		if (!this.filterAllInput)
			return;

		fromEvent(this.filterAllInput.nativeElement, 'keyup')
			.pipe(
				map((evt: any) => evt.target.value),
				debounceTime(500),
				distinctUntilChanged(),
			).subscribe((text: string) => this.onFilter(text));
	}

	protected onGridStateChange(): void {
		this.selectedUserFilter = null;
	}

	public ngOnDestroy() {
		this.signalRNotificationsService.onFinancialReconciliationCompleted = null;
		this.signalRNotificationsService.context = null;
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	processListItems(items: WarehouseWorkplaceRequestListItem[]): void {
		items.forEach(x => {
			x.createdDate = this.appService.parseDate(x.createdDate);
			x.slaStartDate = this.appService.parseDateTime(x.slaStartDate);
			x.slaDueDate = this.appService.parseDateTime(x.slaDueDate);
			if (x.finishDate !== null) {
				x.finishDate = this.appService.parseDateTime(x.finishDate);
			}
			if (x.stoppedUntil !== null) {
				x.stoppedUntil = this.appService.parseDateTime(x.stoppedUntil);
			}
		});
		this._updateRefreshTime();
	}

	public cellMobileClick(dataItem) {
		var request = (<WarehouseWorkplaceRequestListItem>dataItem);
		var requestId = request.requestId;
		var win = window.open(`/requests/${requestId}`, '_blank');
	}

	public cellClick({ type, dataItem, column }: any): void {

		var request = (<WarehouseWorkplaceRequestListItem>dataItem);

		if (column instanceof CheckboxColumnComponent) {

			if (this.gridSelectedRequestIds.some(x => x === request.requestId)) {
				this.gridSelectedRequestIds = this.gridSelectedRequestIds.filter(x => x !== request.requestId);
			} else {
				this.gridSelectedRequestIds.push(dataItem.requestId);
			}

		} else if (type === "click") {
			let col = <ColumnComponent>column;
			if (col.field !== 'requestIdStr') {
				let requestId = request.requestId;
				let win = window.open(`/requests/${requestId}`, '_blank');
			}
		} else {
			this.isGroupClick = request.requestTypeId === <number>RequestType.groupRequest
				|| request.isInGroup;
		}
	}

	public onContextMenuClick({ item, dataItem }: any): void {
		if (item === "Удалить выбранные") {
			this.warehouseWorkplaceRequestsService.removeWarehouseWorkplaceRequests(this.gridSelectedRequestIds).subscribe(res => {
				if(res.isSuccessful) {
					this.loadGridData();
					this.notificationService.success({
						title: '',
						message: 'Заявки удалены из РМС',
						notificationType: NotificationType.Toast
					});
				}
			});
		} else if (item === "Удалить все") {

			this.warehouseWorkplaceRequestsService.removeAllWarehouseWorkplaceRequests().subscribe(res => {
				if(res.isSuccessful) {
					this.loadGridData();
					this.notificationService.success({
						title: '',
						message: 'Заявки удалены из РМС',
						notificationType: NotificationType.Toast
					});
				}
			});
		}
		else if (item === "Удалить текущую") {
			this.warehouseWorkplaceRequestsService.removeWarehouseWorkplaceRequests([dataItem.requestId]).subscribe(res => {
				if(res.isSuccessful) {
					this.loadGridData();
					this.notificationService.success({
						title: '',
						message: 'Заявка удалена из РМС',
						notificationType: NotificationType.Toast
					});
				}
			});
		}
		else {
			const dialogRef = this.dialogService.open({ content: MultipleOperationsComponent, width: '70%', height: '70%' });

			const multipleOperationsComponent = <MultipleOperationsComponent>dialogRef.content.instance;
			multipleOperationsComponent.requestIds = this.listViewModel.data.filter(val => this.gridSelectedRequestIds.includes(val.requestId)).map(m => m.requestId);
			multipleOperationsComponent.tabName = item;
			multipleOperationsComponent.heightPercent = 48;

			multipleOperationsComponent.applyEventOnResetCheckboxes.subscribe(() => {
				this.gridSelectedRequestIds = [];
			});
		}
	}

	public onRequestsReport(): void {
		this.dataGenerating = true;

		this.getXlsx('warehouse-workplace-requests', 'Рабочее место склада.xlsx',
			(c) => {
				c.forEach(f => {
					if (f.field === "sla") {
						f.field = "slaAsTimeSpanString";
						f.filter = "string";
					}
				});
			},
			() => {
				this.dataGenerating = false;
			});
	}

	public onLoadFinancialReconciliation(file: File): void {
		this.loadingFinancialReconciliation = true;
		this.signalRNotificationsService.startConnection().subscribe(() => {
			this.signalRNotificationsService.initReconciliation(file);
		});
	}

	public onSignalRConnected(context: WarehouseWorkplaceRequestsListPage, file: File): void {
		context.dataService.loadFinancialReconciliation(file[0]).subscribe(x => {
			if (x.isSuccessful) {
				context.reconciliationDialog = context.dialogService.open({ content: LoadFinancialReconciliationWarningComponent });
			}

			context.fileInput.nativeElement.value = '';
			context.loadingFinancialReconciliation = false;
		}, () => {
			context.fileInput.nativeElement.value = '';
			context.loadingFinancialReconciliation = false;
		});
	}

	public onLoadFinancialReconciliationCompleted(context: WarehouseWorkplaceRequestsListPage, response: FinancialReconciliationReportResult): void {
		if (context.reconciliationDialog) {
			context.reconciliationDialog.close();
		}
		if (response != null && response.errorItems && response.errorItems.length > 0) {
			const dialogRef = context.dialogService.open({ content: MultipleOperationErrorResultComponent, width: '50%', height: '50%' });

			const multipleOperationErrorResultComponent = <MultipleOperationErrorResultComponent>dialogRef.content.instance;
			multipleOperationErrorResultComponent.errors = response.errorItems;
			multipleOperationErrorResultComponent.heightPercent = 53;
			multipleOperationErrorResultComponent.headerText = "Все заявки обработаны корректно, кроме:";
		} else {
			context.notificationService.success({
				title: 'Файл обработан успешно',
				message: 'Сверка проведена успешно!',
				notificationType: NotificationType.SweetAlert
			});
		}
	}

	public onFilter(inputValue: string): void {
		this.lastFilterText = inputValue;

		this.loadData(this.getCustomState(inputValue));
	}

	private getCustomState(inputValue: string): DataSourceRequestState {
		return {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: "or",
				filters: [
					{
						field: 'requestIdStr',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'initTypeName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'requestTypeName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'statusName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'partnerStatusAliasName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'performerUserGroupName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'performerUserName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'authorUserGroupName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'authorUserName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'customerContragentName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'tspName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'tspLegalEntityName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'tspAddress',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'lastActivityStatusName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'serviceCenterEngineerName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'serviceCenterName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'attributeString',
						operator: 'contains',
						value: inputValue
					}
				],
			}
		};

	}

	private _updateRefreshTime(): void {
		this._lastRefreshTime = new Date();
	}

	private _getMsTime(seconds: number): number {
		return seconds * 1000;
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	public get showMobileView(): boolean {
		return this.isMobileDevice || this.securityService.isOnlyEngineer() || this.securityService.isOnlySCPartnerEngineer() || this.securityService.isSelfEmployedEngineer();
	}

	public initialized: boolean = false;

	setUserFilter(userFilter: UserFilter) {
		this.selectedUserFilter = userFilter;

		var localFilter = localStorage.getItem(this.gridSettingsStorageKey);

		if (!this.isMobileDevice && (localFilter == null || localFilter != userFilter.filterExpression)) {
			this.setGridSettings(JSON.parse(userFilter.filterExpression));
			this.saveGridSettings();
		}

		this.loadData();

		this.mapYaDataService.changeFilters(this.gridSettings.state);

		this.initialized = true;
	}

	setLocalFilter() {
		var localFilter = this.persistingService.get<GridSettings>(this.gridSettingsStorageKey);

		if (localFilter != null && !this.isMobileDevice) {
			this.setGridSettings(localFilter);
			this.saveGridSettings();
		}

		this.loadData();
		this.checkTomorrowRequests();

		this.mapYaDataService.changeFilters(this.gridSettings.state);

		this.initialized = true;
	}

	userFilterSettings() {
		this.modalService.open(this.userFiltersModal, { windowClass: 'modal-top modal-sm' }).result.then((result) => {

		}, (reason) => {

		});
	}

	setUserFilterAsDefault(userFilter: UserFilter) {

		this.switchOnInProgressFlag(this.setDefaultFiltersIsInProgress, userFilter.userFilterId);

		this.userFiltersService.setDefaultUserFilter(userFilter.userFilterId).subscribe(x => {
			this.switchOffInProgressFlag(this.setDefaultFiltersIsInProgress, userFilter.userFilterId);
			this.loadUserFilters();
		});
	}

	removeUserFilter(userFilter: UserFilter) {

		this.switchOnInProgressFlag(this.removeFiltersIsInProgress, userFilter.userFilterId);

		this.userFiltersService.remove(`${userFilter.userFilterId}`, "Пользовательские фильтры", "Фильтр успешно удален").subscribe(x => {
			this.loadUserFilters();

			this.switchOffInProgressFlag(this.removeFiltersIsInProgress, userFilter.userFilterId);
		});
	}

	switchOnInProgressFlag(items: number[], id: number) {
		items.push(id);
	}

	switchMap(): void {
		if (!this.isMap && this.listViewModel.total > 1000) {
			this.notificationService.confirmation({
				title: 'Возможное снижение производительности',
				message: 'Выбрано большое 1000 заявок. Желаете продолжить?',
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: true
			},
				() => {
					this.isMap = !this.isMap;
				},
				() => {
					this.isMapSwitcherOn = false;
				});
		} else {
			this.isMap = !this.isMap;
		}
	}

	switchOffInProgressFlag(items: number[], id: number) {
		const index = items.indexOf(id, 0);

		if (index > -1) {
			items.splice(index, 1);
		}
	}

	onScrollDown() {
		this.loading = true;
		this.gridSettings.state.skip += this.gridSettings.state.take;
		this.dataService.list(this.gridSettings.state).subscribe(lvm => {
			this.listViewModel.data = [...this.listViewModel.data, ...lvm.data];
			this.groupedData = this.groupedByKey;
			this.loading = false;
		});
	}

	public get isEngineer() {
		return this.securityService.isEngineer();
	}

	public get isCostVisible() {
		return this.securityService.isCostVisible();
	}

	public get isMobileEngneer() {
		return this.isMobileDevice && this.isEngineer;
	}

	public changeSelectionTab(tab, requestStatus) {
		this.selectedTab = tab;
		this.selectedRequestStatus = requestStatus;
		this.selectedDate = 'today';
		this.filterLoad();
	}

	public isSelectedTab(tab: number) {
		return this.selectedTab === tab;
	}

	public selectDateFilter(type) {
		if (this.selectedDate === type) {
			this.selectedDate = 'all';
		} else {
			this.selectedDate = type;
		}
		this.filterLoad();
	}

	public selectDateOrder(type) {
		if (this.selectedOrder === type) {
			this.selectedOrder = null;
		} else {
			this.selectedOrder = type;
		}
		this.filterLoad();
	}

	private filterLoad() {
		this.gridSettings.state.skip = 0;
		this.gridSettings.state.filter.filters = [this.getStatusKindFilter(this.selectedDate)];
		this.gridSettings.state.sort = this.getSort()
		this.loadData();
		this.checkTomorrowRequests();
	}

	private checkTomorrowRequests() {

		if (this.selectedTab === 1 && this.isMobileDevice) {
			this.dataService.slaDates().subscribe(lvm => {
				// for test
				// lvm = ["05.06", "06.06", "07.06", "08.06", "09.06", "10.06", "11.06", "12.06", "13.06"]
				var tommorow = new Date(new Date().setHours(24));
				var day = tommorow.getDate();
				var d = day >= 10 ? day.toString() : "0" + day.toString();
				var month = tommorow.getMonth() + 1;
				var m = month >= 10 ? month.toString() : "0" + month.toString();
				this.showTomorrow = lvm.includes(`${d}.${m}-${tommorow.getFullYear()}`);
				this.showDays = lvm.filter(p => p !== `${d}.${m}-${tommorow.getFullYear()}`).map(p => p.split('-')[0]);
			});
		}
	}

	private getSort() {
		const result = [];
		if (this.selectedOrder === 'client') {
			result.push({ field: 'customerContragentName', dir: 'asc' })
		} else if (this.selectedOrder === 'type') {
			result.push({ field: 'requestTypeName', dir: 'asc' })
		} else if (this.selectedOrder === 'address') {
			result.push({ field: 'tspAddress', dir: 'asc' })
		} else if (this.selectedOrder === 'sla') {
			result.push({ field: 'slaDueDateLocal', dir: 'asc' })
		} else {
			result.push({ field: 'requestIdStr', dir: 'desc' });
		}
		return result;
	}

	private getStatusKindFilter(selectedDate: string): CompositeFilterDescriptor {
		const filter: CompositeFilterDescriptor = this.selectedRequestStatus === <number>RequestStatus.AssignedToEngineer
			? { logic: 'and', filters: [{ logic: 'or', filters: [
				{ field: 'statusId', operator: 'eq', value: <number>RequestStatus.AssignedToEngineer },
				{ field: 'statusId', operator: 'eq', value: <number>RequestStatus.InWork },
				{ field: 'statusId', operator: 'eq', value: <number>RequestStatus.InProgress }
			] }] }
			: { logic: 'and', filters: [{ field: 'statusId', operator: 'eq', value: this.selectedRequestStatus }] };
		if (selectedDate === 'today') {
			const today = new Date().toJSON().split('T')
			filter.filters.push({
				logic: 'and', filters: [
					{ field: 'slaDueDate', operator: 'gte~datetime', value: `${today[0]}T00-00-0` },
					{ field: 'slaDueDate', operator: 'lte~datetime', value: `${today[0]}T00-00-0` }
				]
			})
		} else if (selectedDate === 'tomorrow') {
			const today = new Date(new Date().setHours(24)).toJSON().split('T');
			filter.filters.push({
				logic: 'and', filters: [
					{ field: 'slaDueDate', operator: 'gte~datetime', value: `${today[0]}T00-00-0` },
					{ field: 'slaDueDate', operator: 'lte~datetime', value: `${today[0]}T00-00-0` }
				]
			})
		} else if (selectedDate !== 'all') {
			const today = new Date();
			const s = selectedDate.split('.');
			filter.filters.push({
				logic: 'and', filters: [
					{ field: 'slaDueDate', operator: 'gte~datetime', value: `${today.getFullYear()}-${this.getDate(s[1])}-${this.getDate(s[0])}T00-00-0` },
					{ field: 'slaDueDate', operator: 'lte~datetime', value: `${today.getFullYear()}-${this.getDate(s[1])}-${this.getDate(s[0])}T00-00-0` }
				]
			})
		}
		return filter;
	}

	private getDate(s) {
		return s.length < 2 ? "0" + s : s;
	}

	trackByFunction(index, item: ColumnSettings) {

		if (!item)
			return null;

		return item.field;

	}

	public close(status) {
		console.log(`Dialog result: ${status}`);
		this.openedSearchModal = false;
		this.searchRequests = [];
		this.searchRequestsSize = 0;
	}

	public open() {
		this.openedSearchModal = true;
	}

	public get searchRequestsSizeText() {
		return `Поиск. (${this.searchRequestsSize}) записей.`
	}

	public onsearch(event) {
		const value = event.target.value;
		if (value === undefined || value === null || value === '') {
			this.searchRequests = [];
			this.searchRequestsSize = 0;
			return;
		}

		if (this.delaySearch) {
			clearTimeout(this.delaySearch);
		}

		this.delaySearch = setTimeout(() => {
			this.loading = true;
			this.searchRequests = [];
			const searchFilters: CompositeFilterDescriptor = {
				logic: 'or', filters: [
					{ field: 'requestIdStr', operator: 'contains', value: event.target.value },
					{ field: 'customerContragentName', operator: 'contains', value: event.target.value },
					{ field: 'description', operator: 'contains', value: event.target.value },
					{ field: 'externalId', operator: 'contains', value: event.target.value },
					{ field: 'tspAddress', operator: 'contains', value: event.target.value },
					{ field: 'tidNumbers', operator: 'contains', value: event.target.value },
				]
			};

			if (this.isMobileDevice) {
				const statusFilter = this.getStatusKindFilter(this.selectedDate);
				statusFilter.filters.push(searchFilters);
			}

			const searchSetting = this.gridSettings;
			searchSetting.state.filter = searchFilters;
			searchSetting.state.skip = 0;
			searchSetting.state.take = 1000;

			this.dataService.requestList(this.gridSettings.state, this.mobileDeviceVisibleColumnNames).subscribe(lvm => {
				this.searchRequests = lvm.data;
				this.searchRequestsSize = lvm.total;
				this.loading = false;
			});
		}, 800);
	}

	public toggleAccordion(any) {
		if (this.toggledAccordions[any.index] !== undefined && this.toggledAccordions[any.index] !== null) {
			this.toggledAccordions[any.index] = any.active;
		} else {
			this.toggledAccordions.set(any.index, any.active);
		}
	}

	public isActiveAccordion(index: number) {
		return this.alwaysToggled || this.toggledAccordions[index];
	}

	public alwaysToggledChange() {
		this.alwaysToggled = !this.alwaysToggled;
		localStorage.setItem('alwaysToggled', this.alwaysToggled.toString());
	}

	get hasAddRequestClaim(): boolean {
		return this.securityService.hasClaim('cat-requests-add');
	}

	public onVisibilityChange(event: ColumnVisibilityChangeEvent): void {
		if (!this.enableSaveGridSettings) {
			return;
		}
		this.actualizeHiddenColumnsFilteringAndSorting(event.columns);
		super.onVisibilityChange(event);
		this.loadData();
	}
}
