import { BaseObject } from "../core/BaseObject";

export class ServiceItem extends BaseObject {
	id: number;
	name: string;
	number: string;
	rate: string;
	cost: number;
	serviceCode: string;
	description: string;
	requestTypeNames: string;
	requestTypeIds: number[] = [];
	requestCategoryIds: number[] = [];
	serviceRequestTypes: ServiceRequestTypeItem[] = [];
}

export class ServiceRequestTypeItem {
	requestTypeId: number;
	requestCategoryId: number;
	isDefault: boolean;
}

