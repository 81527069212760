import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ContragentCustomerObjectDto } from "../../../../../shared/models/contragent/contragent-customer-object-dto";

@Component({
	selector: 'contragent-customer-objects',
	templateUrl: './contragent-customer-objects.component.html',
	styleUrls: [
        './contragent-customer-objects.component.scss',
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	],
    encapsulation: ViewEncapsulation.None
})
export class ContragentCustomerObjectsComponent implements OnInit {

    @Input() customerObjects: ContragentCustomerObjectDto[] = [];

    selectedCustomerObject: ContragentCustomerObjectDto = null;

    ngOnInit(): void {
        if (this.customerObjects && this.customerObjects.length > 0) {
            this.selectedCustomerObject = this.customerObjects[0];
        } 
    }

	selectCustomerObject(customerObject: ContragentCustomerObjectDto) {
        this.selectedCustomerObject = customerObject;
    }

    get selectedCustomerObjectCenter() {
        if (this.selectedCustomerObject && 
            this.selectedCustomerObject.latitude && 
            this.selectedCustomerObject.longitude) 
        {
            return [this.selectedCustomerObject.latitude, this.selectedCustomerObject.longitude];
        }
        else {
            return [55.751952, 37.600739];
        }
    }

    get cardHeight(): number {
        return this.hasCustomerObjects ? 475 : 120;
    }

    get hasCustomerObjects(): boolean {
        return this.customerObjects && this.customerObjects.length > 0;
    }

}