import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ContragentWithContactDto } from "../../../models/contragent/contragent-with-contact-dto";
import { ContragentsService } from "../../../../categories/contragents/contragents.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { NotificationType } from "../../../../core/services/notification-type";
import { NgModel } from "@angular/forms";
import { AddressSuggestion } from "../../../models/dadata-address/address-suggestion";
import { DaDataAddressComponent } from "../../dadata-address/dadata-address.component";

@Component({
	selector: 'create-contragent',
	templateUrl: './create-contragent.component.html',
	styleUrls: [
		'./create-contragent.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class CreateContragentComponent implements OnInit {

	@ViewChild("contactPhone") contactPhoneModel: NgModel;
	@ViewChild("dadataAddressControl") dadataAddressControl: DaDataAddressComponent;

	loading: boolean = false;

	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

	@Input() contragentEntity: ContragentWithContactDto = null;
	@Input() isEditing: boolean = false;

	@Output() onContinueEvent: EventEmitter<ContragentWithContactDto> = new EventEmitter<ContragentWithContactDto>();
	@Output() onCancelEvent: EventEmitter<any> = new EventEmitter<any>();

	entity: ContragentWithContactDto;

	constructor(
		public contragentsService: ContragentsService,
		public notificationService: NotificationService
	) { }

	ngOnInit(): void {
		if (this.contragentEntity == null) {
			this.entity = new ContragentWithContactDto();
		}
		else {
			this.entity = { ...this.contragentEntity };
		}

		if (this.dadataAddressControl) {
			this.dadataAddressControl.initValue(this.entity.address);
		}
	}

	save() {
		this.loading = true;
		if (this.isEditing) {
			this.contragentsService.updateContragentWithContact(this.entity).subscribe((resp) => {
				this.refreshAndContinue(this.entity.id, resp.isSuccessful, resp.errorDescription);
			}, () => this.loading = false);
		}
		else {
			this.contragentsService.createContragentWithContact(this.entity).subscribe((resp) => {
				this.refreshAndContinue(resp.data, resp.isSuccessful, resp.errorDescription);
			}, () => this.loading = false);
		}
	}

	refreshAndContinue(id: number, isSuccessful: boolean, errorMessage: string = null) {
		if (isSuccessful) {
			this.contragentsService.getContragentWithContact(id).subscribe((getResp) => {
				this.entity = getResp;
				this.onContinueEvent.emit(this.entity);
			}, () => this.loading = false);
		}
		else {
			this.notificationService.error({
				title: 'Ошибка',
				message: errorMessage,
				notificationType: NotificationType.SweetAlert
			});
			this.loading = false;
		}
	}

	onAddressChange(value: AddressSuggestion) {
		this.entity.address = value.address;
	}

	cancel() {
		this.onCancelEvent.emit();
	}

	get canCreate(): boolean {
		return this.entity != null && !this.contactPhoneModel.invalid;
	}
}