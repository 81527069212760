import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { KeyValueObject } from "../../../../models/core/KeyValueObject";
import { WorkflowTransition } from "../../../../models/request/workflow-transition";
import { ProjectWorkflowTransitionType } from "../../../../models/request/workflow-transition-type";
import { ChangeStatusModel } from "../../../../models/change-status-models/change-status-model";
import { RequestBwt } from "../../../../models/request/request-bwt";
import { SetPerformerUserGroup } from "../../../workflow-transitions/set-performer-user-group/set-performer-user-group";
import { SetPerformerUserGroupModel } from "../../../workflow-transitions/set-performer-user-group/set-performer.model";
import { PerformerUserGroupModel } from "../../../../models/change-status-models/performer-user-group-model";
import { DialogService } from "@progress/kendo-angular-dialog";
import { CommentKindEnum } from "../../../../enums/comment-kind.enum";
import { SetReasonForStopping } from "../../../workflow-transitions/set-reason-for-stopping/set-reason-for-stopping";
import { RequestsService } from "../../../../services/requests.service";
import { StopRequestModel } from "../../../../models/change-status-models/stop-request-model";
import { UserGroupsService } from "../../../../services/user-groups.service";
import { UserGroup } from "../../../../models/user-group/user-group";

@Component({
	selector: 'request-status-workflow-transitions',
	templateUrl: './request-status-workflow-transitions.component.html',
	styleUrls: ['./request-status-workflow-transitions.component.scss',
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	],
	encapsulation: ViewEncapsulation.None,
})
export class RequestStatusWorkflowTransitionsComponent implements OnInit {

    @Input() dataSaving: boolean = false;
    @Input() request: RequestBwt = null;
    @Input() workflowTransitions: KeyValueObject[] = [];

    @Output() beforeChangeStatusEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterChangeStatusEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() cancelChangeStatusEvent: EventEmitter<any> = new EventEmitter<any>();

	requestStatusId: number;
	modalUserGroups: UserGroup[] = [];

    constructor (
        private dialogService: DialogService,
        private requestsService: RequestsService,
        private userGroupsService: UserGroupsService
    ) { }

    ngOnInit(): void {
        this.dataSaving = true;
		this.requestStatusId = this.request.requestStatusId;
		this.userGroupsService.getAll(this.request.requestId).subscribe((data) => { 
			this.modalUserGroups = data;
            this.dataSaving = false;
		});
    }

	changeStatus(transitionId: number) {
		let transition = this.request.workflowTransitions.find(x => x.transitionId == transitionId);

		switch (transition.projectWorkflowTransitionType) {
			case ProjectWorkflowTransitionType.SetPerformerUserAndGroupTransition:
				this.showSetPerformerUserAndGroupTransition(transition);
				break;

			case ProjectWorkflowTransitionType.SetReasonForStopping: 
				this.showSetReasonForStopping(transition);
				break;

            case ProjectWorkflowTransitionType.planned:
                this.planned(transition);
                break;
			
			default: 	
				this.changeStatusCore(transition, new ChangeStatusModel(this.request.requestId, transition.requestStatusToId));
				break;
		}
	}

	showSetPerformerUserAndGroupTransition(transition: WorkflowTransition) {
		const dialogRef1 = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: SetPerformerUserGroup,
			width: 400,
		});

		const setPerformerUserGroupForm = <SetPerformerUserGroup>dialogRef1.content.instance;
		setPerformerUserGroupForm.setPerformerUserGroupModel = new SetPerformerUserGroupModel(this.request.performerUserGroupId, this.request.performerUserId);
		setPerformerUserGroupForm.setPerformerUserGroupModel.commentKind = CommentKindEnum.internal;
		setPerformerUserGroupForm.workflowTransition = transition;
		setPerformerUserGroupForm.requestId = this.request.requestId;

		setPerformerUserGroupForm.onCancelEvent.subscribe(() => {
			this.cancelChangeStatusCore();
		});
		setPerformerUserGroupForm.onContinueEvent.subscribe((result: SetPerformerUserGroupModel) => {
			this.request.performerUserGroupId = result.userGroupId;
			this.request.performerUserId = result.userId;

			this.changeStatusCore(transition, new PerformerUserGroupModel(this.request.requestId, transition.requestStatusToId, this.request.performerUserId, this.request.performerUserGroupId, result.comment, result.commentKind));
		});
	}

	showSetReasonForStopping(transition: WorkflowTransition) {
		const dialogRef = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: SetReasonForStopping,
			width: 400
		});

		const setStopReasonAndDateForm = <SetReasonForStopping>dialogRef.content.instance;
		setStopReasonAndDateForm.setPerformerUserGroupModel = new SetPerformerUserGroupModel(this.request.performerUserGroupId, this.request.performerUserId);
		setStopReasonAndDateForm.setPerformerUserGroupModel.commentKind = CommentKindEnum.internal;
		setStopReasonAndDateForm.userGroups = this.modalUserGroups;
		setStopReasonAndDateForm.requestId = this.request.requestId;

		setStopReasonAndDateForm.workflowTransition = transition;
		setStopReasonAndDateForm.stoppingReasons = [];
		setStopReasonAndDateForm.isClientAlfaBank = false;

		setStopReasonAndDateForm.onCancelEvent.subscribe(() => {
			this.cancelChangeStatusCore();
		});
		setStopReasonAndDateForm.onContinueEvent.subscribe((result: SetPerformerUserGroupModel) => {
			this.request.performerUserGroupId = result.userGroupId;
			this.request.performerUserId = result.userId;
			if (setStopReasonAndDateForm.stoppingReason) {
				result.requestStoppingReasonId = setStopReasonAndDateForm.stoppingReasons.find((v, i, a) => v.id === setStopReasonAndDateForm.stoppingReason).id;
				result.comment = result.comment ? 'Комментарий: ' + result.comment : null;
			}

			if (!!setStopReasonAndDateForm.stoppedUntil) {
				setStopReasonAndDateForm.stoppedUntil.setHours(setStopReasonAndDateForm.stoppedUntil.getHours() - this.request.utcTimezoneShift);
			}

			this.changeStatusCore(transition, new StopRequestModel(
				this.request.requestId,
				transition.requestStatusToId,
				this.request.performerUserId,
				this.request.performerUserGroupId,
				result.comment,
				setStopReasonAndDateForm.stoppedUntil,
				result.commentKind,
				result.requestStoppingReasonId));
		});
	}

    planned(transition: WorkflowTransition) {
        const dialogRef1 = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: SetPerformerUserGroup,
			width: 400,
		});

		const setPerformerUserGroupForm = <SetPerformerUserGroup>dialogRef1.content.instance;
		setPerformerUserGroupForm.setPerformerUserGroupModel = new SetPerformerUserGroupModel(this.request.performerUserGroupId, this.request.performerUserId);
		setPerformerUserGroupForm.setPerformerUserGroupModel.commentKind = CommentKindEnum.internal;
		setPerformerUserGroupForm.workflowTransition = transition;
		setPerformerUserGroupForm.requestId = this.request.requestId;
        setPerformerUserGroupForm.showPlanStartDate = true;

		setPerformerUserGroupForm.onCancelEvent.subscribe(() => {
			this.cancelChangeStatusCore();
		});
		setPerformerUserGroupForm.onContinueEvent.subscribe((result: SetPerformerUserGroupModel) => {
			this.request.performerUserGroupId = result.userGroupId;
			this.request.performerUserId = result.userId;

			this.changeStatusCore(transition, new PerformerUserGroupModel(
                this.request.requestId, transition.requestStatusToId, this.request.performerUserId, this.request.performerUserGroupId, result.comment, result.commentKind, result.planStartDate));
		});
    }

	cancelChangeStatusCore() {
		this.requestStatusId = this.request.requestStatusId;
		this.cancelChangeStatusEvent.emit();
	}

	changeStatusCore(transition: WorkflowTransition, changeStatusModel: ChangeStatusModel) {
		this.requestStatusId = transition.transitionId;
		this.beforeChangeStatusEvent.emit();

		changeStatusModel.transitionId = transition.transitionId;
		this.requestsService.changeStatus(changeStatusModel).subscribe(() => {
            this.afterChangeStatusEvent.emit();
		});
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
	  	if (event.key === 'Escape') {
			this.cancelChangeStatusCore();
	  	}
	}
}